import Highcharts from 'highcharts';

import corporate_account_template from '../../../../templates/sellers/corporate_accounts/_corporate_account.html';
import self_financed_template from '../../../../templates/sellers/corporate_accounts/_corporate_account_self_financed.html.haml';
import aging_report_template from '../../../../templates/sellers/performance/aging_report.html';

angular.module('apruve.sellers.corporate_account').controller('SellersCorporateAccountController', [
  '$state', '$scope', '$stateParams', 'currentUserService', 'corporate_account',
  'statistics', 'merchant', 'SellersCorporateAccountStatistics', 'SellersCorporateAccount',
  'statusIconsService', 'chartColors', 'data',
  function($state, $scope, $stateParams, currentUserService, corporate_account,
    statistics, merchant, SellersCorporateAccountStatistics, SellersCorporateAccount,
    statusIconsService, chartColors, data) {
    this.statusIconsService = statusIconsService;
    this.url_params = $stateParams;
    this.url_params.page = parseInt(this.url_params.page);
    this.new_params = angular.copy(this.url_params);
    this.loan = corporate_account.credit_limit || corporate_account.financing_loan;
    this.available_terms = merchant.merchant_term ? merchant.merchant_term.payment_term_strategy_names : [];
    this.sf_decline_reasons = merchant.self_financed_decline_reasons;
    this.show_deny_reason_dropdown = false;
    this.show_needs_more_info_textarea = false;
    this.show_approve_params = true;
    $scope.submit_value = 'approve';
    this.self_financed_country_codes = merchant.self_financed_country_codes;
    this.currencies_by_country = merchant.currencies_by_country;
    this.next_state = $state.current.name;

    this.state = $state;
    if (this.state.current.name === "sellers.corporate_account") {
      this.state.go('sellers.corporate_account.tabs.details', null, { location: 'replace' });
    }

    this.corporate_account_template = corporate_account_template;
    this.self_financed_template = self_financed_template;
    this.aging_report_template = aging_report_template;
    this.reevaluate_new_credit_limit = corporate_account.requested_credit_limit_display_no_symbols;

    this.is_active_tab = name => {
      return this.state.current.name === name
    };

    $scope.data = data;

    this.toggle = function (value) {
      $scope.submit_value = value;
      if (value === 'approve') {
        this.show_approve_params = true;
        this.show_deny_reason_dropdown = false;
        this.show_needs_more_info_textarea = false;
      }
      else if (value === 'deny') {
        if (this.sf_decline_reasons.length > 0 && this.show_deny_reason_dropdown === false) {
          this.show_deny_reason_dropdown = true;
          this.corporate_account.denial_reason = '';
        }
        this.show_needs_more_info_textarea = false;
        this.show_approve_params = false;
      }
      else if (value === 'needs_more_info') {
        this.show_needs_more_info_textarea = true;
        this.show_deny_reason_dropdown = false;
        this.show_approve_params = false;
      }
    };
    this.submit_decision = function() {
      this.corporate_account.decision = $scope.submit_value;
      if (this.corporate_account.decision === 'approve') {
        this.corporate_account.credit_limit = this.reevaluate_new_credit_limit;
        SellersCorporateAccount.submit_decision(this.corporate_account, result => {
              $state.go('.', {}, {reload: true});
            }
            , response => {
              this.corporate_account = response.data;
              console.error(response.data);
            });
      }
      else if (this.corporate_account.decision === 'deny') {
        if (this.sf_decline_reasons.length === 0  || (this.sf_decline_reasons.length > 0  && this.corporate_account.denial_reason !== '')) {
          SellersCorporateAccount.submit_decision(this.corporate_account, result => {
                $state.go('.', {}, {reload: true});
              }
              , response => {
                this.corporate_account = response.data;
                console.error(response.data);
              });
        }
      }
      else if (this.corporate_account.decision === 'needs_more_info') {
          if (this.corporate_account.needs_more_info !== '') {
              SellersCorporateAccount.submit_decision(this.corporate_account, result => {
                      $state.go('.', {}, {reload: true});
                  }
                  , response => {
                      this.corporate_account = response.data;
                      console.error(response.data);
                  });
          }
      }
    };


    this.load_new_page = (new_value, old_value) => {
      if ((new_value == null) || (old_value == null) || (new_value === old_value)) { return true; }
      $state.go('.', new_value, {notify: false});
      if (new_value.page !== old_value.page) { return true; }
      this.url_params = angular.copy(new_value);
    };

    this.edit_nickname = () => {
      this.nickname_read_only = false;
    };

    this.edit_merchant_account_id = () => {
      this.merchant_account_id_read_only = false;
    };

    this.display_email_notification_type = type => {
      return type.replace(/_/g, ' ');
    };

    this.display_verified_bank_account_icon = () => {
        return ((corporate_account.payment_method_category != null ? corporate_account.payment_method_category.name : undefined) === "BankAccount") && corporate_account.verified_payment_method;
      };

    this.display_unverified_bank_account_icon = () => {
        return ((corporate_account.payment_method_category != null ? corporate_account.payment_method_category.name : undefined) === "BankAccount") && !corporate_account.verified_payment_method;
      };

    this.save_nickname = () => {
      SellersCorporateAccount.update(this.corporate_account, result => {
        this.nickname_read_only = true;
      }
      , response => {
        this.corporate_account = response.data;
        console.error(response.data);
      });
    };

    this.save_merchant_account_id = () => {
      SellersCorporateAccount.update(this.corporate_account, result => {
        this.merchant_account_id_read_only = true;
      }
      , response => {
        this.corporate_account = response.data;
        console.error(response.data);
      });
    };

    $scope.$watch('corpAcctCtrl.new_params', this.load_new_page, true);

    this.nickname_read_only = true;
    this.merchant_account_id_read_only = true;

    // Chart

    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });
    this.chartControl = {};
    this.tooltip_date_format_change = function (unit) {
      return (unit === 'month') ? '%B %Y' : '%B %e, %Y';
    };
    this.chartConfig = {
      chart: {
        backgroundColor: 'transparent',
        type: 'area',
        height: 240
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      plotOptions: {
        series: {
          fillOpacity: 0.1,
          lineWidth: 3,
          marker: {
            enabled: false
          },
          dataLabels: {
            enabled: false
          },
          label: {
            enabled: false
          }
        }
      },
      menuItemStyle: {
        fontWeight: 'normal',
        background: 'none'
      },
      menuItemHoverStyle: {
        fontWeight: 'bold',
        background: '#1784B0',
        color: '#fff'
      },
      tooltip: {
        backgroundColor: '#fff',
        borderRadius: 4,
        borderColor: '#ccc',
        borderWidth: 1,
        footerFormat: '</table>',
        headerFormat: '<small>{point.key}</small><table>',
        pointFormat: '<tr><td><b>${point.y}</b></td></tr>',
        shadow: false,
        shared: true,
        useHTML: true,
        valueDecimals: 2,
        xDateFormat: '%B %e, %Y'
      },
      series: [{
        data: statistics.chart_data,
        color: chartColors.primary, // fill color
        pointStart: Date.parse(statistics.start_date),
        pointIntervalUnit: statistics.point_interval_unit,
        showInLegend: false,
        states: {
          hover: {
            enabled: false
          }
        }
      }],
      loading: false,
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%b %e',
          week: '%b %e',
          month: '%b, %Y',
          year: '%Y'
        }
      },
      yAxis: {
        opposite:  false,
        labels: {
          enabled: true
        },
        title: {
          text: 'Invoice Volume'
        }
      }
    };

    this.calculate_recent_orders_lines = () => {
      const lines = []; // One line for each table row. A line is a 2-tuple of ORDER or null, INVOICE or null
      for (let order of this.statistics.recent_orders) {
        if (order.invoices.length === 0) {
          lines.push([order, null]);
        } else {
          lines.push([order, order.invoices[0]]);
        }
        for (let invoice of order.invoices.slice(1)) {
          lines.push([null, invoice]);
        }
      }
      return this.recent_orders_lines = lines;
    };

    this.row_classes_for = poAndInvoice => {
      if (poAndInvoice[0]) { // If it has an order
        return ['data-table__row', 'has-rowspan'];
      } else {
        return ['no-border'];
      }
    };

    this.purchase_order_status_class = poAndInvoice => {
      const po = poAndInvoice[0];
      if (po !== null) {
        const cls = statusIconsService.purchase_order_class(po);
        return cls;
      } else {
        return [];
      }
    };
    this.invoice_status_class = poAndInvoice => {
      const invoice = poAndInvoice[1];
      if (invoice !== null) {
        const cls = statusIconsService.invoice_class(invoice);
        return cls;
      } else {
        return [];
      }
    };
    const { chartControl } = this;
    const _this = this;

    // This is called when the period is changed in the dropdown. We load a new statistics object here and do any manual
    // refreshing that angular wouldn't handle correctly.
    this.updatePeriod = period =>
      SellersCorporateAccountStatistics
        .get({date_range_type: period.value, merchant_id: $stateParams.merchant_id, uuid: corporate_account.uuid})
        .$promise.then(function(statistics) {
          // "this" is wrong here unless we reference a variable explicitly.
          _this.chartControl.updateTooltipDateFormat(_this.tooltip_date_format_change(statistics.point_interval_unit));

          _this.statistics = statistics;
          _this.chartControl.updateSeries(
            {
              data: statistics.chart_data,
              color: '#1784B0', // fill color
              pointStart: Date.parse(statistics.start_date),
              pointIntervalUnit: statistics.point_interval_unit,
              showInLegend: false,
              states: {
                hover: {
                  enabled: false
                }
              }
            },
            0 // The index of our chart is zero
          );
          _this.chartHasData = !statistics.chart_data.every(elem => elem === 0);
          return _this.setChartStyle();
      })
    ;

    this.setChartStyle = () => {
      // We can't use an ng-if because if we do the chart directive isn't ever called and the chartControl object
      // isn't populated.
      if (this.chartHasData) {
        this.chartStyle = {};
      } else {
        this.chartStyle = {display: 'none'};
      }
    };
    currentUserService.user.$promise.then(() => {
      this.corporate_account = corporate_account;
      this.statistics = statistics;
      this.chartHasData = !statistics.chart_data.every(elem => elem === 0);
      this.setChartStyle();
      this.calculate_recent_orders_lines();
//      @merchant_name = currentUserService.currentContext.name
      this.periods = [{name: 'Last 30 Days', value: 'last_30'},
        {name: 'Last 90 Days', value: 'last_90'},
        {name: 'Year To Date', value: 'ytd'},
        {name: 'All Time', value: 'all_time'}];
      this.periodHash = {};
      for (let period of this.periods) {
        this.periodHash[period.value] = period;
      }

      // the model needs to be {name: '', value: ''} hash, but query param can only be a string
      this.period = this.periodHash['last_30'];
    });
  }
]);