import Highcharts from 'highcharts';

angular.module('apruve.common.charts')
.directive 'hcDonut', [
  ->
    restrict: 'E',
    template: '<div></div>'
    scope:
      options: '='
      chartControl: '='
    link: (scope, element) ->
      chart = Highcharts.chart element[0], scope.options

      scope.chartControl = scope.chartControl || {}

      scope.chartControl.addSeries = (series) ->
        chart.addSeries series

      scope.chartControl.removeSeries = (seriesIndex) ->
        chart.series[seriesIndex].remove()

      scope.chartControl.updateSeries = (series, seriesIndex) ->
        chart.series[seriesIndex].update series
]