angular.module('apruve.users.quote').controller "QuoteController", [
  '$scope', '$state', 'quote_data', '$uibModal', 'CorporateAccount'
  ($scope, $state, quote_data, $uibModal, CorporateAccount) ->
    $scope.quote_status = quote_data.status
    $scope.quote_data = quote_data

    $scope.quote_data.trusted_merchant = false
    $scope.quotes_enabled = true

    $scope.finalized = ->
      quote_data.status == 'approved' or quote_data.status == 'rejected'

    $scope.prompt_trusted_merchant = ->
      $scope.trustMerchantModal = $uibModal.open({
        animation: true
        ariaLabelledBy: 'trustMerchantModal'
        templateUrl: require('../../../../templates/quotes/trust_merchant_modal.html')
        scope: $scope
        windowClass: 'modal fade v2'
      })
      $scope.trustMerchantModal.result.then null, ->
        $scope.isSubmitting = undefined # reset spinning button if modal is dismissed

    $scope.update_quotes_enabled = (quotes_enabled) ->
      CorporateAccount.update_quotes_enabled({uuid: quote_data.corporate_account_uuid, quotes_enabled: quotes_enabled})

    $scope.close_modal = ->
      $scope.trustMerchantModal.dismiss()
      $state.go('purchase_order', {}, reload: true)

    $scope.approve_quote = ->
      quote_data.$approve (quote_data) ->
        $scope.result = 'success'
        if !quote_data.trusted_merchant_set_at && quote_data.quotes_enabled
          $scope.prompt_trusted_merchant()
        else
          $state.go('purchase_order', {}, reload: true)
      , (error) ->
        $scope.result = 'error'
        console.error("Error while confirming order")

    $scope.start_approve_quote = ->
      $scope.isSubmitting = true
      $scope.approve_quote()

    $scope.reject_quote = ->
      $scope.isSubmitting = true
      quote_data.$reject (quote_data) ->
        $scope.result = 'success'
        $state.go('purchase_order', {}, reload: true)
      , (error) ->
        $scope.result = 'error'
        console.error("Error while rejecting order")

    $scope.cancel_options =
      buttonDefaultText: 'Cancel Order'
      buttonSubmittingText: 'Working...'
      buttonSuccessText: 'Canceled'
      buttonDefaultClass: 'actionBar-btn-primary'
      buttonSubmittingClass: 'actionBar-btn-primary'
      buttonSuccessClass: 'actionBar-btn-primary'
      buttonErrorClass: 'actionBar-btn-danger'
    $scope.confirm_options =
      buttonDefaultText: 'Confirm Order'
      buttonSubmittingText: 'Working...'
      buttonSuccessText: 'Confirmed'
      buttonDefaultClass: 'actionBar-btn-primary'
      buttonSubmittingClass: 'actionBar-btn-primary'
      buttonSuccessClass: 'actionBar-btn-primary'
      buttonErrorClass: 'actionBar-btn-danger'
]