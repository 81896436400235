angular.module('apruve.common.sidenav')
.directive('sidenav', [function() {
  return {
    restrict: 'E',
    templateUrl: require('../../../../templates/common/sidenav/sidenav.html'),
    replace: true,
    transclude: true
  };
}
])
.directive('sidenavItem', [function() {
  return {
    restrict: 'E',
    templateUrl: require('../../../../templates/common/sidenav/sidenav-item.html'),
    replace: true,
    transclude: true,
    scope: {
      activeState: '@',
      activeIcon: '@',
      badge: '=',
      hasNotification: '=',
      urgency: '=',
      href: '@',
      target: '@',
      icon: '@',
      titleText: '@'
    },
    controller: [
      '$scope', '$state', '$log',
      function($scope, $state, $log) {
        $scope.id = $scope.titleText.toLowerCase().replace(' ', '-') + '-nav';
        $scope.is_active = false;
        $scope.collapsed = true;
        $scope.state = $state;

        $scope.determineActive = function(toState) {
          var active_when = $scope.activeState.split(/\s*\|\|?\s*/);

          return $scope.is_active = _.some(active_when, function(i) {
            return toState.name.indexOf(i) === 0;
          });
        };

        $scope.$on('$stateChangeSuccess', function(event, toState) {
          return $scope.determineActive(toState);
        });

        $scope.init = function() {
          return $scope.determineActive($scope.state.$current);
        };

        return $scope.init();
      }
    ]
  };
}
])
.directive('sidenavSettings', [function() {
  return {
    restrict: 'E',
    templateUrl: require('../../../../templates/common/sidenav/sidenav-settings.html'),
    replace: true,
    transclude: true,
    controller: [
      '$scope', '$rootScope',
      function($scope, $rootScope) {
        $scope.collapsed = true;

        // this scope always evaluated before children, so this kinda resets it and if a child is active it will uncollapse
        return $rootScope.$on('$stateChangeStart', function() {
          return $scope.collapsed = true;
        });
      }
    ]
  };
}
])
.directive('sidenavSettingsUser', [function() {
  return {
    restrict: 'E',
    templateUrl: require('../../../../templates/common/sidenav/sidenav-settings-user.html'),
    replace: true,
    transclude: true,
    controller: [
      '$scope', '$rootScope',
      function($scope, $rootScope) {
        $scope.collapsed = true;

        // this scope always evaluated before children, so this kinda resets it and if a child is active it will uncollapse
        return $rootScope.$on('$stateChangeStart', function() {
          return $scope.collapsed = true;
        });
      }
    ]
  };
}
])
.directive('sidenavSettingsItem', [function() {
  return {
    restrict: 'E',
    templateUrl: require('../../../../templates/common/sidenav/sidenav-settings-item.html'),
    replace: true,
    transclude: true,
    scope: {
      href: '@',
      target: '@',
      titleText: '@'
    },
    controller: [
      '$scope', '$rootScope', '$location',
      function($scope, $rootScope, $location) {
        $scope.id = $scope.titleText.toLowerCase().replace(' ', '-') + '-nav';
        $scope.collapsed = true;
        $scope.is_active = function() {
          return ($scope.href === $location.path()) || ($location.path().indexOf($scope.href) === 0);
        };

        $rootScope.$on('$stateChangeStart', function() {
          if ($scope.is_active()) { return $scope.$parent.$parent.collapsed = false; }
        });

        if ($scope.is_active()) {
          // $scope.$parent.$parent experimentally points to the settings bar scope, so
          // that gets set to uncollapsed if any child scopes are active. Need this on
          // initial page load despite $stateChangeStart above.
          return $scope.$parent.$parent.collapsed = false;
        }
      }
    ]
  };
}
])
.directive('sidenavLogoutItem', [function() {
  return {
    restrict: 'E',
    templateUrl: require('../../../../templates/common/sidenav/sidenav-logout-item.html'),
    replace: true,
    transclude: true,
    scope: {
      href: '@',
      target: '@',
      titleText: '@'
    },
    controller: [
      '$scope',
      function($scope) {
        return $scope.id = $scope.titleText.toLowerCase().replace(' ', '-') + '-nav';
      }
    ]
  };
}
]);