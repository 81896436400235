import moment from 'moment';

angular.module('apruve.users.statement').controller("StatementsController", [
  '$scope', '$state', '$stateParams', 'statusIconsService',
  function($scope, $state, $stateParams, statusIconsService) {
    this.statusIconsService = statusIconsService;
    // statementsCtrl
    this.url_params = $stateParams;
    this.url_params.page = parseInt(this.url_params.page);
    this.new_params = angular.copy(this.url_params);
    this.search_field = this.new_params.search;
    this.url = "/statements.json";

    this.navigate_to_statement = statement => $state.go('statement', {statement_id: statement.uuid});

    this.search = function() {
      this.new_params.page = 0;
      return this.new_params.search = this.search_field;
    };

    this.resort = function(clicked_on_col) {
      if (this.new_params.sort_column !== clicked_on_col) {
        this.new_params.sort_column = clicked_on_col;
        return this.new_params.sort_order = 'desc';
      } else {
        if (this.new_params.sort_order === 'desc') {
          return this.new_params.sort_order = 'asc';
        } else {
          return this.new_params.sort_order = 'desc';
        }
      }
    };

    this.sort_classes_for = function(column) {
      if (column === this.new_params.sort_column) {
        return ['caret', this.new_params.sort_order];
      } else {
        return [];
      }
    };

    this.display_status = function(statement) {
      if (this.is_overdue(statement) && statement.type === 'Statement') {
        return 'overdue';
      } else if (this.is_waiting(statement)) {
        return 'waiting';
      } else if (this.is_due(statement) && statement.type === 'Statement') {
        return 'due soon';
      } else if (statement.status === 'issued') {
        return 'open';
      } else {
        return statement.status;
      }
    };

    this.is_overdue = function(statement) {
      if ((statement.status === 'issued') && (moment() > moment(statement.due_at).add(2, 'days'))) {
        return true;
      }
      return false;
    };

    this.is_due = function(statement) {
      if ((statement.status === 'issued') && (moment(statement.due_at) < (moment().add(5, 'days')))) {
        return true;
      }
      return false;
    };

    this.is_waiting = statement => (statement.needs_payment === false) && (statement.status === 'issued');

    this.load_new_page = (new_value, old_value) => {
      if ((new_value == null) || (old_value == null)) { return true; }
      if (new_value === old_value) { return true; }
      $state.go('.', new_value, {notify: false});
      if (new_value.page !== old_value.page) { return true; }
      return this.url_params = angular.copy(new_value);
    };

    $scope.$watch("statementsCtrl.new_params", this.load_new_page, true);
      
  }
]);

