require ('angular');
require ('angular-resource');
require('javascripts/angular/common/bank_account_format/bank_account_format.service.js');
require('javascripts/angular/common/bank_account_format/_bank_account_format.module.js');

angular.module('apruve.common.bank_account_format').factory('BankAccountFormatService',['$resource', function($resource){
      var service = {};
      var BankAccountFormater = $resource('/payment_methods/get_bank_account_format.json', {country_code: '@country_code'});
      var format_hash_for_country;

      service.get_format_information_for_country = function (corporate_account_id, invoice_uuid){
        return BankAccountFormater.get({corporate_account: corporate_account_id, invoice_uuid: invoice_uuid}).$promise.then(function (format) {
          format_hash_for_country = format;
        });
      };

      service.get_required_fields = function () {
        return format_hash_for_country.required_fields;
      };

      service.get_currency = function () {
        return format_hash_for_country.currency;
      };

      service.get_account_number_regex = function () {
        return format_hash_for_country.account_number;
      };

      service.get_branch_number_regex = function () {
        return format_hash_for_country.branch_number;
      };

      service.get_routing_number_regex = function () {
        return format_hash_for_country.routing_number;
      };

      service.get_iban_regex = function () {
        return format_hash_for_country.iban;
      };

      service.get_bank_number_regex = function () {
        return format_hash_for_country.bank_number;
      };

      service.country_has_valid_format = function () {
        return format_hash_for_country != undefined;
      };

      return service;
    }]);
