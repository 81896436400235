import billing_tier_smb from '../../../../images/billing-tiers__smb.png'
import billing_tier_custom from '../../../../images/billing-tiers__custom.png'
import billing_tier_enterprise from '../../../../images/billing-tiers__enterprise.png'

angular.module('apruve.sellers.billing_invoices').controller "BillingInvoicesController", [
  '$scope', '$state', '$stateParams', 'fee_tier', 'saas_fee_tier', 'debit_account', 'DebitBankAccount',
  ($scope, $state, $stateParams, fee_tier, saas_fee_tier, debit_account, DebitBankAccount) ->
    @new_params = angular.copy($stateParams)
    @new_params.page = parseInt @new_params.page
    @url_params = angular.copy(@new_params)
    @url = "/sellers/#{$stateParams.merchant_id}/billing_invoices.json"

    @fee_tier = fee_tier
    @saas_fee_tier = saas_fee_tier

    @debit_account = debit_account
    @debit_account_resource = new DebitBankAccount(merchant_id: $stateParams.merchant_id)
    # Know that $stateParams.merchant_id is good here

    @debitCardTemplate = () ->
      require('../../../../templates/sellers/billing_invoices/debit_bank_account_card.html')

    @debitModalTemplate = () ->
      require('../../../../templates/common/bank_accounts/bank_account_modal.html')

    @navigate_to_billing_invoice = (billing_invoice) ->
      $state.go('sellers.billing_invoice', billing_invoice_uuid: billing_invoice.uuid, merchant_id: $stateParams.merchant_id)

    @resort = (clicked_on_col) ->
      if @new_params.sort_column != clicked_on_col
        @new_params.sort_column = clicked_on_col
        @new_params.sort_order = 'asc'
      else
        if @new_params.sort_order == 'desc'
          @new_params.sort_order = 'asc'
        else
          @new_params.sort_order = 'desc'

    @sort_classes_for = (column) ->
      if column == @new_params.sort_column
        ['apruve-icon-angle', @new_params.sort_order]
      else
        []

    @row_classes_for = (billing_invoice) ->
      classes = []
      if billing_invoice.status_display in ['closed', 'canceled']
        classes.push 'list-item-done'
      classes

    @billing_tier_icon_url = ->
      switch @saas_fee_tier.tier_level
        when 'base_level' then billing_tier_smb
        when 'mid_level' then billing_tier_enterprise
        when 'top_level' then billing_tier_custom
        else billing_tier_smb

    @status_classes_for = (billing_invoice) ->
      classes = []
      if billing_invoice.status_display in ['closed', 'canceled']
        classes.push 'list-item-status-done'
      else if billing_invoice.status_display == 'overdue'
        classes.push 'list-item-status-urgent'
      else
        classes.push 'list-item-status-open'
      classes

    @load_new_page = (new_value, old_value) =>
      return true if !new_value? or !old_value?
      return true if new_value == old_value
      $state.go '.', new_value, notify: false
      return true if new_value.page != old_value.page
      @url_params = angular.copy(new_value)

    @show_billing_tiers = ->
      @fee_tier != null && @saas_fee_tier != null

    $scope.$watch "ctrl.new_params", @load_new_page, true

    return
]