angular.module('apruve.sellers.team_admin').controller 'SellersTeamAdminController', [
  '$state', '$scope', '$stateParams', 'currentUserService', 'SellersTeamAdmin', 'roles'
  ($state, $scope, $stateParams, currentUserService, SellersTeamAdmin, roles) ->
    @url_params = $stateParams
    @url_params.page = parseInt @url_params.page
    @new_params = angular.copy(@url_params)
    @account_administrators_url = "/sellers/#{@url_params.merchant_id}/administrators.json"
    @owner_email = currentUserService.currentContext.owner_email

    @team_admin = new SellersTeamAdmin(merchant_id: $stateParams.merchant_id)
    @url = "/sellers/#{$stateParams.merchant_id}/administrators.json"

    @roles = roles
    @load_new_page = (new_value, old_value) =>
      return true if !new_value? or !old_value?
      return true if new_value == old_value
      $state.go '.', new_value, notify: false
      return true if new_value.page != old_value.page
      @url_params = angular.copy(new_value)

    $scope.$watch ->
      @new_params
    , ->
      @load_new_page
    , true

    @show_admin = (account_admin) ->
      $state.go('sellers.account_administrator', {merchant_id:$stateParams.merchant_id, account_admin_id: account_admin.uuid}, {reload: true})

    @can_edit_admin = (account_admin) ->
      @is_current_user_owner() || !@is_owner(account_admin) || @is_current_user_super_admin()

    @is_owner = (account_admin) ->
      account_admin.email == @owner_email

    @is_current_user_super_admin = ->
      _(currentUserService.user.merchant_roles).find((role) => role.id == 'merchant_super_admin') != undefined

    @get_role_name = (account_admin) ->
      if account_admin.role
        return account_admin.role.display_name
      else
        return 'Admin'

    @is_current_user_owner = ->
      currentUserService.currentContext.owner_email == currentUserService.user.email

    @row_classes_for = (account_admin) ->
      if @can_edit_admin(account_admin)
        ['data-table__row--clickable']
      else
        ['list-item-done', 'data-table__row--unclickable']

    @add_admin = () =>
      if @team_admin_form.$invalid
        @display_errors = true
        return false
      @display_errors = false
      @team_admin.$create({merchant_id: $stateParams.merchant_id}, (result) =>
        result.selected = true
        for am in @account_administrators
          delete am.selected
        @account_administrators.push(result)
        @team_admin = new SellersTeamAdmin(merchant_id: $stateParams.merchant_id)
      , (response) ->
        console.error(response.data)
    )

    return
]