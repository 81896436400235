angular.module('apruve.common.corporate_account')
.factory 'loanDonut', ->
  this.chartOptions = (colors, name, data) ->
    chart:
      type: 'pie'
      height: '100%'
    colors: colors
    title:
      text: ''
    yAxis:
      title:
        text: ''
    plotOptions:
      pie:
        shadow: false
    tooltip:
      enabled: false
    exporting:
      enabled: false
    credits:
      enabled: false
    series: [
      {
        name: name
        data: data
        size: '65px'
        innerSize: '70%'
        showInLegend: false
        dataLabels:
          enabled: false
        states:
          hover:
            enabled: false
      }
    ]

  this.loanOptions = ->
    restrict: 'E'
    templateUrl: require('../../../../templates/common/corporate_accounts/credit_limit_donut.html')
  this