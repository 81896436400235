# Handles any HTTP errors from AJAX requests.
#
# If necessary, there are two ways you can prevent/disable this from your local code:
#
# - if you're using JQuery, set global: false, per http://api.jquery.com/Ajax_Events/
#
# - if you're using Rails UJS, set this attribute in your markup:
#     :'data-non-global' => true
#
# - if you're using a 3rd party library that is triggering this code, find a solution, and fix this code. :-)
#     (maybe check the source here, and ignore?)
#
$(document).bind "ajaxError", (event, data, status, xhr) ->
  if $(this).data('non-global')
    return true
  if data.status == 401
    alert("Sorry, your session has expired. Please log in to resume.")
    location.reload()
  else if data.status == 0
    console.error("xhr request was canceled.")
    console.error(data)
  else
    console.error(data)
    console.error(status)
    message = "Sorry, we appear to be experiencing technical problems. If this persists, please shoot us an email at support@apruve.com to let us know."
    if process.env.NODE_ENV == 'test'
      message += JSON.stringify(status);
      message += JSON.stringify(data)
    alert(message)