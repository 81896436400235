require 'external/s3_direct_upload'

class @ImageUploader
  @error_display_selector

  constructor: (upload_form, upload_button, error_display_selector) ->
    @initAvatarUploader(upload_form, upload_button)
    @error_display_selector = error_display_selector

  initAvatarUploader: (upload_form, upload_button) ->
    $(upload_form).S3Uploader(
      remove_completed_progress_bar: false,
      remove_failed_progress_bar: true,
      allow_multiple_files: false,
      progress_bar_target: $('#uploads_container'))
    $(upload_form).bind('s3_upload_failed', (e, content) ->
      error = 'An unknown error has occurred.  Please try again or contact support.'
      switch content.error_thrown
        when 'Bad Request' then error = 'must be less than 2MB.'
        when 'Forbidden' then error = 'Apruve is experiencing difficulties, please contact support.'
      imageUploader.handleS3Errors(error))

    $('input[id=avatar_uploader]').change ->
      $('#photoCover').val(if (this.files && this.files.length) then this.files[0].name else this.value.replace(/^C:\\fakepath\\/i, ''))

    $(upload_button).on 'click', ->
      $('input[id=avatar_uploader]').click()

    if( navigator.appName.indexOf('Internet Explorer')!= -1) and (navigator.appVersion.indexOf('MSIE 9')!=-1 and navigator.userAgent.indexOf('Trident/5')!=-1 )
      $('input[id=avatar_uploader]').addClass('ie9_uploader_hotfix')


  handleS3Errors: (msg, selector=@error_display_selector) ->
    $('#' + selector + '_error').show()
    $('#' + selector + '_error').text(msg)
    $('.' + selector + '_field').addClass('error')
    if selector == 'profile_image'
      profileFormAnimation.stop()



  clearS3Errors: (selector=@error_display_selector) ->
    $("#" + selector + "_error").hide()
    $("." + selector + "_field").removeClass("error")

window.ImageUploader = @ImageUploader