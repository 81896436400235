angular.module('apruve.users.payment').controller 'CancelPaymentController', [
  '$scope', '$state', '$stateParams', 'Payment',
  ($scope, $state, $stateParams, Payment) ->

    $scope.cancel_payment = ->
      $scope.submitting = true
      payment = new Payment {uuid: $stateParams.payment_uuid}
      payment.$cancel (p) -> (
        $scope.result = 'success'
        $state.go('payment', {}, {reload: true})
      ),
      (e) -> (
        $scope.result = 'error'
        console.error('Error while canceling payment: ', angular.toJson(e.data, true))
        $scope.errors = e.data.errors
        return
      )

    $scope.button_options =
      buttonDefaultText: 'Cancel Payment'
      buttonSubmittingText: 'Canceling...'
      buttonSuccessText: 'Canceled'
      buttonErrorText: 'Error'
      buttonDefaultClass: 'actionBar-btn-primary'
      buttonSubmittingClass: 'actionBar-btn-primary'
      buttonSuccessClass: 'actionBar-btn-primary'
      buttonErrorClass: 'actionBar-btn-danger'
]