// Images
import logo_white from '../../../../images/logo-white.png'
import routing_number from '../../../../images/routing_number.png'
import account_number from '../../../../images/account_number.png'
import webhook_notification_example from '../../../../images/notification_email_examples/webhook_notification_example.png'
import order_notification_example from '../../../../images/notification_email_examples/order_notification_example.png'
import invoice_update_notification_example from '../../../../images/notification_email_examples/invoice_update_notification_example.png'
import funding_report_notification_example from '../../../../images/notification_email_examples/funding_report_notification_example.png'
import credit_application_notification_example from '../../../../images/notification_email_examples/credit_application_notification_example.png'
import billing_event_notification_example from '../../../../images/notification_email_examples/billing_event_notification_example.png'

// SVGs
import paper_check from '../../../../images/svg/paper-check.svg';
import eft_transfer from '../../../../images/svg/eft-transfer.svg';
import exclamation_triangle from '../../../../images/svg/exclamation-triangle.svg';
import reevaluate from '../../../../images/svg/re-evaluate.svg';
import merchant_created_doc from '../../../../images/svg/merchant-created-doc.svg';
import funding_activity from '../../../../images/svg/funding-activity.svg';
import circle_plus from '../../../../images/svg/circle-plus.svg';

angular.module('apruve.common.assets').factory('images', () => ({
  paper_check,
  eft_transfer,
  exclamation_triangle,
  reevaluate,
  merchant_created_doc,
  funding_activity,
  circle_plus,
  logo_white,
  routing_number,
  account_number,
  webhook_notification_example,
  order_notification_example,
  invoice_update_notification_example,
  funding_report_notification_example,
  credit_application_notification_example,
  billing_event_notification_example
}));