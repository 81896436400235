import moment from 'moment'
angular.module('apruve.sellers.invoice.new_payment').controller 'NewPaymentController', [
  'invoice_data', 'payment_data', 'corporate_account', '$scope', '$state', '$stateParams'
  (invoice_data, payment_data, corporate_account, $scope, $state, $stateParams) ->
    $scope.payment = payment_data
    $scope.today = moment()
    $scope.min_payment_date = moment($scope.payment.opened_at_orig)
    $scope.currency_symbol = corporate_account.currency_symbol
    $scope.currency_data = invoice_data.currency_data;

    $scope.record_payment = () ->
      $scope.submitting = true
      if $scope.paymentForm.$invalid
        $scope.display_errors = true
        $scope.result = 'error'
        return false

      $scope.display_errors = false
      $scope.payment.$save (p) ->
        if p.meta.errors == null
          $scope.result = 'success'
          $state.go('sellers.invoice.payments', {}, {reload: true})
        else
          $scope.display_errors = true
          $scope.result = 'error'
          $scope.payment.invoice_uuids = [$stateParams.invoice_uuid]
      , (response) ->
        $scope.payment.meta = response.data.meta
        $scope.result = 'error'

    $scope.display_errors = false
    $scope.create_button_options =
      buttonDefaultText: 'Save Payment'
      buttonSubmittingText: 'Submitting...'
      buttonSuccessText: 'Payment Saved'
      buttonDefaultClass: 'actionBar-btn-primary'
      buttonSubmittingClass: 'actionBar-btn-primary'
      buttonSuccessClass: 'actionBar-btn-primary'
      buttonErrorClass: 'actionBar-btn-danger'
]