angular.module('apruve.users.team_member').controller "EditTeamMemberController", [
  '$state', '$scope', '$stateParams', '$window', 'team_member_data', 'currentUserService',
  ($state,   $scope,   $stateParams,   $window,   team_member_data, currentUserService) ->
    team_member_data.buyer = true
    $scope.team_member = team_member_data

    $scope.can_delete_team_member = false # Default to false while we wait for the promise

    currentUserService.user.$promise.then (user) =>
      $scope.can_delete_team_member = team_member_data.user.uuid != user.uuid # Can't delete yourself

    @submit_options =
      buttonDefaultText: 'Save team member'
      buttonSubmittingText: 'Working...'
      buttonSuccessText: 'Success!'

    @readonly = !!team_member_data.user?.email?

    $scope.$watch 'team_member.admin', (newValue, oldValue) =>
      if !oldValue and !!newValue
        $scope.team_member.payer = true

    $scope.$watch 'team_member.payer', (newValue, oldValue) =>
      if !oldValue and !!newValue
        $scope.team_member.buyer = true
      else if !!oldValue and !newValue
        $scope.team_member.admin = false

    $scope.$watch 'team_member.buyer', (newValue, oldValue) =>
      if !!oldValue and !newValue
        $scope.team_member.payer = false

    @delete = () =>
      name = $scope.team_member.user.name || $scope.team_member.user.email || 'this user'
      if $window.confirm("Remove " + name + " from this account’s team?")
        console.log $scope.team_member.$destroy ->
          $state.go('account.new_team_member', {corporate_account_id: $scope.team_member.customer_account.uuid})
        , (error) ->
          @result = 'Error removing team member'
          @display_errors = true
          console.error error

    @submit = () =>
      @submitting = true
      if @team_member_form.$invalid
        @result = 'error'
        @display_errors = true
        return false

      @display_errors = false

      success = (result) =>
        @result = 'success'
        $state.go('account.new_team_member', { corporate_account_id: $scope.team_member.customer_account.uuid })
      error = (response) =>
        @result = 'error'
        @display_errors = true
        console.error(response.data)

      $scope.team_member.$update(success, error)

    return
]
