class @TabNav
  constructor: ->
    hash = document.location.hash
    prefix = "#tab_"
    if (hash)
      prefix = '#' unless hash.includes prefix # Works for #tabname and #tab_tabname
      $('.profile__tabsection a[href$='+hash.replace(prefix,"")+']').tab('show')

    $('.profile__tabsection a').on 'shown', (e) ->
      window.location.hash = e.target.hash.replace("#", + prefix)
      $('.alertContainer').remove()
