import moment from 'moment'
angular.module('apruve.users.statement').controller 'StatementController', [
  '$scope', '$stateParams', '$state', '$uibModal', 'statusIconsService', 'statement_data',
  ($scope, $stateParams, $state, $uibModal, statusIconsService, statement_data) ->
    $scope.url_params = $stateParams
    $scope.url_params.page = parseInt $scope.url_params.page
    $scope.new_params = angular.copy($scope.url_params)
    $scope.url = '/invoices.json'

    $scope.statusIconsService = statusIconsService
    $scope.statement = statement_data

    $scope.navigate_to_payment = (payment) ->
      $state.go('payment', payment_uuid: payment.uuid)

    $scope.navigate_to_invoice = (invoice) ->
      $state.go('invoice', invoice_uuid: invoice.uuid)

    $scope.is_overdue = (statement) ->
      if ((statement.status == 'issued') and (moment() > moment(statement.due_at))) and (statement.amount_due != "$0.00")
        return true
      false

    $scope.is_due = (statement) ->
      if ((statement.status == 'issued') and (moment(statement.due_at) < (moment().add(5, 'days')))) and (statement.amount_due != "$0.00")
        return true
      false

    $scope.is_invoice_overdue = (invoice) ->
      if (invoice.status_display == 'overdue')
        return true
      false

    $scope.is_invoice_due = (invoice) ->
      if (invoice.status_display == 'due soon')
        return true
      false

    $scope.status_explanation_for = (statement) ->
      switch statement.status
        when 'open' then "This billing period is currently open and collecting statements."
        when 'issued'     then "This statement will collect no more invoices, and is ready for payment."
        when 'closed'  then "This statement has been settled."
        else ''
]
