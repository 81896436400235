angular.module('apruve.sellers.services')
.factory 'statusIconsService', [
  ->
    new class StatusIconsService
      invoice_class: (invoice) =>
        status_class = switch invoice.status
          when 'closed', 'canceled', 'funded' then 'list-item-status--closed'
          when 'open'
            switch invoice.status_display
              when 'overdue' then 'list-item-status--urgent'
              when 'due soon' then 'list-item-status--due'
              when 'pending' then 'list-item-status--pending'
              else 'list-item-status--open'
          when 'failed' then 'list-item-status--failed'
          when 'processing' then 'list-item-status--processing'
          when 'captured' then 'list-item-status--done' # Captured is done here
          else 'list-item-status--unknown'
        return [status_class]
      purchase_order_class: (purchase_order) =>
        status_class = switch purchase_order.status
          when 'new', 'pending' then 'list-item-status--pending' # Lump new in with pending because new shouldn't ever be customer-visible
          when 'accepted', 'approved' then 'list-item-status--approved' # english_status = accepted, status = approved
          when 'canceled', 'rejected' then 'list-item-status--rejected' # english_status = canceled, status = rejected
          else 'list-item-status--unknown' # Shouldn't ever happen unless we add a new status
        [status_class]
      corporate_account_class: (corporate_account) =>
        status_class = switch corporate_account.status
          when 'approved' then 'list-item-status--approved'
          when 'in_progress' then 'list-item-status--in_progress'
          when 'pending' then 'list-item-status--pending_approval'
          when 'unconfirmed' then 'list-item-status--pending_approval'
          when 'denied' then 'list-item-status--denied'
          when 'suspended' then 'list-item-status--suspended'
          when 'disabled' then 'list-item-status--disabled'
        [status_class]
      statement_class: (statement) ->
        status_class = switch statement.status_display
          when 'closed' then 'list-item-status--closed'
          when 'overdue' then 'list-item-status--urgent'
          when 'due' then 'list-item-status--due'
          when 'issued' then 'list-item-status--issued'
          when 'open' then 'list-item-status--open'
          else 'list-item-status--unknown'
        [status_class]
      payment_class: (payment) =>
        status_class = switch payment.status
          when 'pending' then 'list-item-status--pending'
          when 'processing' then 'list-item-status--processing'
          when 'captured' then 'list-item-status--captured'
          when 'failed' then 'list-item-status--failed'
          when 'canceled' then 'list-item-status--canceled'
          else 'list-item-status--unknown'
        [status_class]
]