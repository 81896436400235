angular.module('apruve.sellers.app', [
  'ui.router'
  'ngAnimate'
  'ngSanitize'
  'ngResource'
  'apruve.common'
  'apruve.sellers.app.routes'
  'apruve.sellers.services'
  'apruve.sellers.corporate_account'
  'apruve.sellers.corporate_account.new_account'
  'apruve.sellers.credit_applications'
  'apruve.sellers.financing_applications'
  'apruve.sellers.shipment'
  'apruve.sellers.funding_details'
  'apruve.sellers.invoice'
  'apruve.sellers.funding_events'
  'apruve.sellers.invoice.new_payment'
  'apruve.sellers.payment'
  'apruve.sellers.credit_memo'
  'apruve.sellers.bank_account'
  'apruve.sellers.payout'
  'apruve.sellers.role'
  'apruve.sellers.purchase_order'
  'apruve.sellers.subscription'
  'apruve.sellers.message_log'
  'apruve.sellers.performance'
  'apruve.sellers.checklist'
  'apruve.sellers.statement'
  'apruve.sellers.team_admin'
  'apruve.sellers.billing_invoices'
  'apruve.ng-bs-animated-button'
  'ui.bootstrap'
  'bgf.paginateAnything'
  'angular-aba-routing-validation'
  'apruve.common.angular_iban_validation'
  'apruve.common.bank_account_format'
  'apruve.common.moment'
])