import { TrackJS } from 'trackjs'

angular.module('apruve.users.statement').controller 'StatementPaymentsController', [
  '$scope', '$state', '$stateParams', 'statement_data',
  ($scope, $state, $stateParams, statement_data) ->
    $scope.url_params = $stateParams
    $scope.url_params.page = parseInt $scope.url_params.page
    $scope.new_params = angular.copy($scope.url_params)
    $scope.url = '/payments.json'

    $scope.statement = statement_data

    $scope.navigate_to_invoice = (invoice) ->
      $state.go('invoice', invoice_uuid: invoice.uuid)

    $scope.$on '$stateChangeSuccess', (event, toState) ->
      TrackJS.console.log(toState.name)
]