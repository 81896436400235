angular.module('apruve.common.payment_modal')
  .controller('PaymentModalNewBankAccountController', ['$scope', 'payable_model', '$state', 'BankAccount', 'images', PaymentModalNewBankAccountController]);

function PaymentModalNewBankAccountController($scope, payable_model, $state, BankAccount, images) {
  $scope.bankAccountResource = new BankAccount({account_uuid: payable_model.customer_account_uuid, strategy: payable_model.strategy});
  $scope.bankAccount = new BankAccount({account_uuid: payable_model.customer_account_uuid, strategy: payable_model.strategy});
  $scope.images = images;

  $scope.$on('bank_account.done', function() {
    $state.go('^.^.payment_methods', {}, {reload: true});
  });
}
