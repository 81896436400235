angular.module('apruve.common.invoice_import')
    .directive('invoiceImport', ['$uibModal', 'environment', invoiceImport]);

function invoiceImport($uibModal, environment) {
  return {
    restrict: 'A',
    scope: {
      integration: '=',
      importFunction: '=importFunction',
      successCallback: '=successCallback'
    },
    link: function(scope, element, attr) {

      scope.import_button_options = {
        buttonDefaultText: 'Import',
        buttonSubmittingText: 'Importing...',
        buttonSuccessText: 'Invoice Imported',
        buttonDefaultClass: 'actionBar-btn-primary',
        buttonSubmittingClass: 'actionBar-btn-primary',
        buttonSuccessClass: 'actionBar-btn-primary',
        buttonErrorClass: 'actionBar-btn-danger'
      };

      scope.button_state = {
        result: null,
        submitting: null
      };

      scope.close_modal = function() {
        scope.modal.dismiss();
      };

      scope.import_invoice = function(invoice_number) {
        scope.button_state.submitting = true;
        var callback = scope.importFunction(invoice_number, scope.integration.integration_name);
        callback.then(function(data) {
          scope.button_state.result = 'success';
          scope.successCallback(data);
        }, function(error) {
          scope.button_state.result = 'error';
          console.error(error);
        });
      };

      element.on('click', function(event) {
        scope.modal = $uibModal.open({
          animation: true,
          ariaLabelledBy: 'deleteBankAccountModal',
          templateUrl:  require('../../../../templates/common/invoice_import/modal.html'),
          scope: scope,
          windowClass: environment.is_test() ? 'modal v2' : 'modal v2 fade'
        })
      });
    }
  };
}