class @CharacterCounter
  constructor: (textArea, counter, characterLimit) ->
    $(textArea).on 'keyup', ()->
      if $(this).val().length > characterLimit
        $(counter).parent().parent('.control-group').addClass('lengthError')
      else
        $(counter).parent().parent('.control-group').removeClass('lengthError')
      $(counter).text(
        (characterLimit - $(this).val().length)
      )

