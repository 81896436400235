angular.module('apruve.users.purchase_order').controller "PurchaseOrderUpdateController", [
  '$scope', '$state', 'PurchaseOrder'
  ($scope, $state, PurchaseOrder) ->
    $scope.attached_po_orig = $scope.purchase_order.payment_term?.document
    $scope.po_number_orig = $scope.purchase_order.po_number

    $scope.update_purchase_order = ->
      $scope.animated_button.submitting = true
      $scope.display_errors = false
      if $scope.purchase_order_form.$invalid
        $scope.display_errors = true
        $scope.animated_button.result = 'error'
        return false

      PurchaseOrder.update purchase_order_id: $scope.purchase_order.token, $scope.purchase_order, (purchase_order) ->
        $scope.purchase_order = purchase_order
        $scope.$parent.purchase_order = purchase_order
        if purchase_order.meta.errors?
          $scope.display_errors = true
          $scope.animated_button.result = 'error'
        else
          $scope.animated_button.result = 'success'
          $state.go('^')

    $scope.cancel_update = ->
      if $scope.purchase_order.payment_term?
        $scope.purchase_order.payment_term.document = $scope.attached_po_orig
      $scope.purchase_order.po_number = $scope.po_number_orig
      $state.go('^')

    $scope.display_errors = false
    $scope.animated_button =
      buttonDefaultText: 'Save'
      buttonSubmittingText: 'Working...'
      buttonSuccessText: 'Saved'
      buttonDefaultClass: 'actionBar-btn-primary'
      buttonSubmittingClass: 'actionBar-btn-primary'
      buttonSuccessClass: 'actionBar-btn-primary'
      buttonErrorClass: 'actionBar-btn-danger'
]