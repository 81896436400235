angular.module('apruve.users.payment').controller('PaymentInvoicesController', ['payment_data', '$scope', '$state', '$stateParams', PaymentInvoicesController]);
function PaymentInvoicesController(payment_data, $scope, $state, $stateParams) {
  this.url_params = $stateParams;
  this.url_params.page = parseInt(this.url_params.page);
  this.new_params = angular.copy(this.url_params);
  this.payment = payment_data;

  this.url = function () {
    return '/payments/' + this.payment.uuid + '/invoices.json';
  };

  this.navigate_to_invoice = function(invoice_payment) {
    $state.go('invoice', {invoice_uuid: invoice_payment.invoice_uuid});
  };

  this.load_new_page = function(new_value, old_value) {
    $state.go('.', new_value, { notify: false });
  };

  $scope.$watch('ctrl.new_params', this.load_new_page, true);
}
