angular.module('apruve.common.payment_modal').factory('creditMemoWorkflowFactory', [creditMemoWorkflowFactory]);

function creditMemoWorkflowFactory() {
  return {
    resolve_next_state: function(payment_action, state) {
      state.go('^.credit_memo.review', {
        payment_action: payment_action,
      })
    }
  };
}
