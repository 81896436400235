angular.module('apruve.users.getting_started').controller('AutoPayTosFormController', [
  '$scope', '$uibModalInstance', '$window', '$http',
  function($scope, $uibModalInstance, $window, $http) {
    if (!$scope.adTosForm) { $scope.adTosForm = {}; }
    $scope.show_error_class = function(elem, adTosForm) {
      var submitted = adTosForm ? adTosForm.$submitted : false;
      return !elem.$valid && (elem.$touched || elem.$dirty || submitted);
    };

    $scope.log_out_url = '/users/sign_out';
    $scope.confirmation_page = false;

    $scope.accept_tos = function(adTosForm) {
      if (adTosForm.$invalid) { return; }
      return $scope.corporate_account.$accept_auto_pay_tos(function(corp_acct) {
        return $uibModalInstance.dismiss();
      }
      , function(corp_acct) {
        return $scope.corporate_account.meta = corp_acct.data.meta;
      });
    };

    $scope.decline_tos = function() {
      return $scope.corporate_account.$decline_auto_pay_tos(function(corp_acct) {
        return setTimeout(function() {
          return $scope.logout_user();
        }
        , 1500);
      }
      , function(corp_acct) {
        return $scope.corporate_account.meta = corp_acct.data.meta;
      });
    };

    $scope.logout_user = function() {
      return $http["delete"]($scope.log_out_url).then(function() {
        return window.location.reload();
      });
    };

    $scope.go_to_confirmation = function() {
      return $scope.confirmation_page = true;
    };

    $scope.go_to_tos_form = function() {
      return $scope.confirmation_page = false;
    };

  }
]);