angular.module('apruve.common.profile_band')
.directive 'userProfileBand', ['currentUserService', '$stateParams', (currentUserService, $stateParams) ->
  templateUrl: require('../../../../templates/common/profile_band/user.html')
  restrict: 'E'
  link: (scope) ->
    scope.currentUser = currentUserService.user
    scope.isMerchantContext = $stateParams.merchant_id? ? true : false
  scope:
    user: '='
    label: '@'
]