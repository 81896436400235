angular.module('apruve.sellers.performance').controller 'PerformanceController', [
  '$state', '$scope', 'currentUserService', '$stateParams'
  ($state, $scope, currentUserService, $stateParams) ->
    @state = $state

    @updatePeriod = (period) ->
      $state.go('.', {date_range: period.value})

    currentUserService.user.$promise.then =>
      @merchant_name = currentUserService.currentContext.name
      @periods = [{name: 'Last 30 Days', value: 'last_30'},
        {name: 'Last 90 Days', value: 'last_90'},
        {name: 'Year To Date', value: 'ytd'},
        {name: 'All Time', value: 'all_time'}]
      @periodHash = {}
      for period in @periods
        @periodHash[period.value] = period

      # the model needs to be {name: '', value: ''} hash, but query param can only be a string
      @period = @periodHash[$stateParams.date_range]

    $scope.$watch =>
      @period
    , (period) =>
      if period?
        $scope.$emit 'performance.period.update', period

    return
]