import _ from 'lodash';

export function wrap(response) {
  if(_.isUndefined(response)) {
    return {
      config: {},
      headers: function() {
        return {};
      }
    }
  }

  let copied_response = Object.assign({}, response);

  if(_.isUndefined(copied_response.config)) {
    copied_response.config = {};
  }

  if(_.isUndefined(copied_response.headers) || !_.isFunction(copied_response.headers)) {
    copied_response.headers = function() {
      return {};
    };
  }
  return copied_response;
}
