class @Ga_env
    constructor: ->
        ga_env = process.env.GA_TRACKING_ID
        _gaq = window._gaq ?= []

        unless ga_env == ''
            ga = document.createElement 'script'
            ga.type = 'text/javascript'
            ga.async = true

            ga.src = 'https://www.google-analytics.com/ga.js'

            doc_scripts = document.getElementsByTagName 'script'
            doc_scripts[0].parentNode.insertBefore ga, doc_scripts

            _gaq.push ['_setAccount', ga_env]
            _gaq.push ['_trackPageview']