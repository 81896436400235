angular.module('apruve.common.message_log').controller 'MessageLogController', [
  '$scope', '$timeout', 'MessageLog', 'message_log_type', 
  ($scope, $timeout, MessageLog, message_log_type) ->
    $scope.message_log = new MessageLog(
      purchase_order_uuid: $scope.purchaseOrderUuid
      merchant_id: $scope.merchantId
      sender: $scope.sender
      message_type: message_log_type)

    $scope.display_errors = false

    $scope.save_message_log = ->
      $scope.isSubmitting = true
      if $scope.message_log_form.$invalid
        $scope.display_errors = true
        $scope.result = 'error'
        return false

      $scope.display_errors = false
      $scope.message_log.$save (message_log) ->
        if message_log.meta.errors == null
          $scope.result = 'success'
          $timeout $scope.onSuccess, 1000
        else
          $scope.display_errors = true
          $scope.result = 'error'
          console.error("Error while sending message_log: #{angular.toJson(message_log.meta, true)}")

    $scope.isSubmitting = null
    $scope.result = null
    $scope.message_options =
      buttonDefaultText: 'Send'
      buttonSubmittingText: 'Sending...'
      buttonSuccessText: 'Sent'
]