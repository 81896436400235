angular.module('apruve.common.charts')
  .factory('chartColors', () ->

    hexToRGBA = (hex, opacity) ->
      hex = hex.replace('#', '')
      hexAsInt = parseInt(hex, 16)
      lowBitsMask = 255
      r = (hexAsInt >> 16) & lowBitsMask
      g = (hexAsInt >> 8) & lowBitsMask
      b = hexAsInt & lowBitsMask
      opacity = opacity.toFixed(1)
      "rgba(#{r}, #{g}, #{b}, #{opacity})"
    return {
      primary: '#00a3a0'
      secondary: '#a0c51e'
      primaryLight: '#81d5d3'
      secondaryLight: '#a0c51e'
      applyTransparency: (hex, opacity) => hexToRGBA(hex, opacity)
    })