angular.module('apruve.common.payment_modal').provider('paymentModalState', paymentModalState);

function paymentModalState() {
  var parent_state = null;

  this.createNestedState = function (stateProvider, parentState) {
    parent_state = parentState;
    var modal_state = parent_state + '.new';
    // Payment Method Select
    stateProvider
      .state(modal_state, {
        templateUrl: require('../../../../templates/common/payment_modal/modal.html'),
        title: 'New Payment',
        abstract: true,
        url: '/new',
        bindToController: true,
        resolve: {
          payable_model: ['payableModelService', function(payableModelService) {
            return payableModelService.get_payable_model();
          }],
          corporate_account_data: ['payable_model', 'CorporateAccount', function(payable_model, CorporateAccount) {
            return CorporateAccount.get({uuid: payable_model.customer_account_uuid}).$promise;
          }]
        },
        onEnter: ['payableModelService', 'payable_model', function(payableModelService, payable_model) {
          payableModelService.decorate(payable_model); // payable_model is resolved here, so now we can extend it
          $(function() {
            if($('#payment-modal')) {
              $('#payment-modal').modal({backdrop: 'static', keyboard: false});
              $('#payment-modal').modal('show');
            }
          })
        }],
        onExit: function() {
          $('#payment-modal').modal('hide');
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
        }
      })
      .state(modal_state + '.payment_methods', {
        templateUrl: require('../../../../templates/common/payment_modal/payment_methods.html'),
        title: 'New Payment',
        url: '',
        controller: 'PaymentMethodModalController',
        controllerAs: 'ctrl',
        bindToController: true
      })
      .state(modal_state + '.error', {
        templateUrl: require('../../../../templates/common/payment_modal/error.html'),
        title: 'Error Completing Payment',
        controller: 'PaymentModalErrorController',
        controllerAs: 'ctrl'
      });

     // Bank Account (ECheckAccount)
    stateProvider
      .state(modal_state + '.bank_account', {
        template: '<ui-view />',
        abstract: true
      })
      .state(modal_state + '.bank_account.new', {
        templateUrl: require('../../../../templates/common/payment_modal/bank_account/new.html'),
        title: 'Add Bank Account',
        controller: 'PaymentModalNewBankAccountController',
        controllerAs: 'ctrl'
      })
      .state(modal_state + '.bank_account.confirmation', {
        templateUrl: require('../../../../templates/common/payment_modal/bank_account/confirmation.html'),
        title: 'Electronic Payment Confirmation',
        controller: 'PaymentModalBankAccountController',
        controllerAs: 'ctrl',
        params: {
          payment_action: null
        }
      });
    // Credit Memo
    stateProvider
        .state(modal_state + '.credit_memo', {
          title: 'Credit Memo Payment',
          template: '<ui-view />',
          controller: 'PaymentModalCreditMemoController',
          controllerAs: 'ctrl',
          abstract: true,
          params: {
            payment_action: null
          }
        })
        .state(modal_state + '.credit_memo.review', {
          templateUrl: require('../../../../templates/common/payment_modal/credit_memo/review.html'),
          title: 'Credit Memo Payment'
        })
        .state(modal_state + '.credit_memo.confirmation', {
          templateUrl: require('../../../../templates/common/payment_modal/credit_memo/confirmation.html'),
          title: 'Credit Memo Payment Confirmation'
        });

    return stateProvider;
  };

  this.$get = function() {
    return {
    };
  };
  return this;
}


