angular.module('apruve.common.to_precision')
.directive('toPrecision', function(){
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$formatters.unshift(function(value) {
        return parseFloat(value).toFixed(attrs.toPrecision);
      });
    }
  }
});