/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('apruve.sellers.purchase_order').controller("PurchaseOrderController", [
  '$scope', '$state', 'purchase_order_data', 'PurchaseOrder',
  function($scope, $state, purchase_order_data, PurchaseOrder) {
    $scope.purchase_order = purchase_order_data;
    $scope.shopper = $scope.purchase_order.shopper;

    $scope.colspan = Object.keys($scope.purchase_order.item_optional_fields).filter(field => $scope.purchase_order.item_optional_fields[field]).length + 5;

    $scope.editing_po_number = false;

    $scope.purchase_order.reasons = purchase_order_data.cancellation_reasons ? purchase_order_data.cancellation_reasons : [];

    $scope.start_editing_po_number = () => $scope.editing_po_number = true;

    $scope.save_new_po_number = function() {
      const update_params = {
        token: $scope.purchase_order.token,
        po_number: $scope.purchase_order.po_number,
        merchant: { id: $scope.purchase_order.merchant.id }
      };
      return PurchaseOrder.update(update_params).$promise
        .then(function(po) {
          $scope.editing_po_number = false;
          return $scope.purchase_order = po;}).catch(err => console.error(err));
    };

    $scope.cancelOrder = function() {
      if ($scope.purchase_order.reasons.length > 0 && $scope.purchase_order.reason == undefined) {
        $scope.display_errors = true;
        return false;
      }
      $scope.display_errors = false;
      $scope.isSubmitting = true;
      $scope.purchase_order.status = 'rejected';
      return $scope.purchase_order.$update(function(purchase_order) {
        if (purchase_order.rejected) {
          $scope.result = 'success';
          return $state.go('sellers.purchase_order');
        } else {
          $scope.result = 'error';
          return console.error(`Error while canceling an order: ${angular.toJson(purchase_order.meta, true)}`);
        }
      });
    };

    $scope.cancel_subscriptions = function() {
      $scope.isSubmitting = true;
      console.log("Canceling all subscriptions");
      return $scope.purchase_order.$cancel_subscriptions(function(purchase_order) {
        $scope.result = 'success';
        return $state.go('sellers.purchase_order.subscriptions');
      });
    };

    $scope.acceptPendingPO = function() {
      $scope.purchase_order.status = 'approved';
      return $scope.purchase_order.$update(function(purchase_order) {
        $state.go('sellers.purchase_order');
        if (!purchase_order.approved) {
          return console.error(`Error while approving a purchase order: ${angular.toJson(purchase_order.meta, true)}`);
        }
      });
    };

    $scope.sent_message = () => $state.go('sellers.purchase_order');

    $scope.isSubmitting = null;
    $scope.result = null;
    $scope.cancel_subscription_options = {
      buttonDefaultText: 'Cancel Subscriptions',
      buttonSubmittingText: 'Working...',
      buttonSuccessText: 'Canceled',
      buttonDefaultClass: 'actionBar-btn-primary',
      buttonSubmittingClass: 'actionBar-btn-primary',
      buttonSuccessClass: 'actionBar-btn-primary',
      buttonErrorClass: 'actionBar-btn-danger'
    };
    $scope.cancel_options = {
      buttonDefaultText: 'Cancel Order',
      buttonSubmittingText: 'Working...',
      buttonSuccessText: 'Canceled',
      buttonDefaultClass: 'actionBar-btn-primary',
      buttonSubmittingClass: 'actionBar-btn-primary',
      buttonSuccessClass: 'actionBar-btn-primary',
      buttonErrorClass: 'actionBar-btn-danger'
    };
    return $scope.terms_options = {
      buttonDefaultText: 'Accept Order',
      buttonSubmittingText: 'Working...',
      buttonSuccessText: 'Accepted',
      buttonDefaultClass: 'actionBar-btn-primary',
      buttonSubmittingClass: 'actionBar-btn-primary',
      buttonSuccessClass: 'actionBar-btn-primary',
      buttonErrorClass: 'actionBar-btn-danger'
    };
  }
]);