angular.module('apruve.users.purchase_order')
  .factory "PurchaseOrder", ['$resource', ($resource) ->
    $resource( "/purchase_orders/:purchase_order_id.json",
      { purchase_order_id: "@token" },
      {
        update: {method: "PUT"},
        cancel: {method: "POST", url: "/purchase_orders/:purchase_order_id/cancel.json", isArray: false},
        query: {isArray: false}
      }
    )
  ]