import currency from 'currency.js';

/*
This is just a wrapper around currency.js that takes in the options once and gives you back a function that will call
currency() with the options with out having to pass it in.

For example:

// Without this service

import currency from 'currency.js';

const amount1 = currency(123.45, { symbol: '$', thousands_separator: ',', decimal_mark: '.', exponent: 2 })
const amount2 = currency(234.56, { symbol: '$', thousands_separator: ',', decimal_mark: '.', exponent: 2 })
const amount3 = currency(345.67, { symbol: '$', thousands_separator: ',', decimal_mark: '.', exponent: 2 })


// With this service
function(money) { // Some angular thing with the service injected

  const currency = money.currency({ symbol: '€', thousands_separator: '.', decimal_mark: ',', exponent: 3);

  const amount1 = currency(123.456)
  const amount2 = currency(234.560)
  const amount3 = currency(345678)
}


This just makes converting to a currency object less verbose considering there are almost no places in our app where we
need to cross currency options. If we do, simply don't use the service.
*/


// I copied this out of currency.js since we need to modify the result and it only allows replacing this function.
function currencyDefaultFormat(currency, settings) {
  var pattern = settings.pattern,
      negativePattern = settings.negativePattern,
      symbol = settings.symbol,
      separator = settings.separator,
      decimal = settings.decimal,
      groups = settings.groups,
      split = ('' + currency).replace(/^-/, '').split('.'),
      dollars = split[0],
      cents = split[1];
  return (currency.value >= 0 ? pattern : negativePattern).replace('!', symbol).replace('#', dollars.replace(groups, '$1' + separator) + (cents ? decimal + cents : ''));
}

class MoneyService {
  currency(config) {
    const iso_code = config.iso_code;
    // Currency.js doesn't know the iso code but we pass it in lib/currency/currency_data.rb
    return (amount) => {
      return currency(amount, {
        symbol: config.symbol,
        separator: config.thousands_separator,
        decimal: config.decimal_mark,
        precision: config.exponent,
        with_iso_code: !!iso_code,
        format: (curr, settings) => {
          const withoutIsoCode = currencyDefaultFormat(curr, settings);
          if (settings.with_iso_code) {
            return `${withoutIsoCode} ${iso_code}`;
          } else { // Fallback if no code was passed
            return withoutIsoCode;
          }
        }
      });
    }
  }
}

angular.module('apruve.common.money').service('money', [MoneyService]);