class ApplyInstructionModal {
  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  open_modal(payment, currency) {
    this.modal = this.$uibModal.open({
      animation: true,
      ariaLabelledBy: 'instructionModal',
      templateUrl: require('../../../../templates/payments/apply_instructions_modal.html'),
      windowClass: 'fade v2',
      resolve: {
        payment: function() { return payment },
        currency: function() { return currency },
      },
      controller: ['$scope', 'payment', 'currency', function($scope, payment, currency) {
        $scope.currency = currency;
        $scope.amount = currency(payment.amount);
        $scope.available_amount = currency(payment.available_amount)
      }]
    });
  }

  close_modal() {
    this.modal.close()
  }
}

angular.module('apruve.users.payment').service('applyInstructionModal', ['$uibModal', ApplyInstructionModal]);