
export default function setupSlidingLabels() {
    $( document ).ready(function() {
        slidingLabel();
    });

    document.addEventListener("turbolinks:load", function() {
        slidingLabel();
    });
}


function slidingLabel(){
  //check each form-control for disabled
  $(".form-control").each(function () {
    if ($(this).is(":disabled")) {
      $(this).closest(".form-group").addClass('is-disabled');
    }
  });
  //check each form-control for readonly
  $(".form-control").each(function () {
    if ($(this).prop("readonly")) {
      $(this).closest(".form-group").addClass('is-readonly');
    }
  });
  //check each form-control has a value
  $(".form-control").each(function () {
    if ($(this).val()) {
      $(this).closest(".form-group").addClass("has-value");
    } else {
      // It's possible the has-value class is already set so the form looks reasonable without js.
      // We should remove it so the label starts in the slid-down position.
      $(this).closest(".form-group").removeClass("has-value");
    }
  });
  //set the has-focus/not-focus class and check for input value
  $(".form--sliding-labels .form-control").focus(function () {
    $(this).closest(".form-group").addClass("has-focus");
  }).blur(function () {
    if (!$(this).val()) { //check to see if the input has a value
      $(this).closest(".form-group").removeClass("has-focus");
    } else {
      $(this).closest(".form-group").addClass("has-value");
      $(this).closest(".form-group").removeClass("has-focus");
    }
  });
  //on input change, if the value is empty, remove "has-value" class.
  $(".form--sliding-labels .form-control").on("change paste keyup", function () {
    if (!$(this).val()) { //check to see if the input has a value
      $(this).closest(".form-group").removeClass("has-value");
    }
  });
  $(".form--sliding-labels .form-control").attr("autocomplete", "false");
}