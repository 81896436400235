function confirmOrder(url) {
    $.ajax({
      url: url,
      method: 'PUT'
    }).done(function (response) {
        $('#quote-approved-text').show();
        location.reload();
    });
}

function denyOrder(url) {
  $.ajax({
    url: url,
    method: 'PUT'
  }).done(function(){
      $('#quote-denied-text').show();
      location.reload();
  });
}

window.denyOrder = denyOrder;
window.confirmOrder = confirmOrder;