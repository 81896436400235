angular.module('apruve.common.click_to_show')
.directive 'clickToShow', [() ->
  restrict: 'A'
  templateUrl: require('../../../../templates/common/click_to_show/click_to_show.html')
  transclude: true
  link: (scope, element, attributes) ->
    if attributes.classToShow?
      $(element).on('click', ->
        $("." + attributes.classToShow).css('display', 'block')
      )
    if attributes.classToHide?
      $(element).on('click', (event) ->
        $("." + attributes.classToHide).css('display', 'none')
      )
]