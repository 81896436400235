angular.module('apruve.sellers.corporate_account').controller "SellersPrepaymentNewController", [
  'SellersCorporateAccount', '$location', '$state', '$stateParams', 'corporate_account', (CorporateAccount, $location, $state, $stateParams, corporate_account) ->

    @account_name = if corporate_account.name then corporate_account.name else corporate_account.customer_name
    @self_financed = corporate_account.self_financed
    @submitting = null
    @result = null
    @display_errors = false
    @corporate_account = corporate_account

    @go_back = =>
      $state.go($stateParams.next_state, {corporate_account_id: $stateParams.corporate_account_id}, {reload: true})

    @submit_options =
      buttonDefaultText: 'Save'
      buttonSubmittingText: 'Working...'
      buttonSuccessText: 'Success!'

    @submit_form = () =>
      @submitting = true
      if @account_form.$invalid
        @result = 'error'
        @display_errors = true
        return false

      @display_errors = false
      CorporateAccount.create_prepayment {merchant_id: $stateParams.merchant_id, uuid: $stateParams.corporate_account_id}, @prepayment, (result) =>
        @result = 'success'
        @go_back()
      , (response) =>
        @result = 'error'
        @display_errors = true
        @prepayment = response.data
        TrackJS.console.error(response.data)

      return

    return
]