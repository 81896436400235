
angular.module('apruve.sellers.payment').controller("PaymentsController", [
  '$scope', '$state', '$stateParams', 'statusIconsService', 'merchant', '$http',
  function($scope, $state, $stateParams, statusIconsService, merchant, $http) {
    var _this = this;
    // paymentsCtrl
    this.scoped_account = !!$stateParams.scoped_account;
    this.statusIconsService = statusIconsService;
    this.url_params = $stateParams;
    this.url_params.page = parseInt(this.url_params.page);
    this.new_params = angular.copy(this.url_params);
    this.search_field = this.new_params.search;
    this.url = "/sellers/".concat($stateParams.merchant_id, "/payments.json");

    this.can_upload_payments = function() {
      return merchant.can_upload_payments;
    };

    this.navigate_to_payment = function(payment) {
      return $state.go('sellers.payment', {payment_uuid: payment.uuid, merchant_id: $stateParams.merchant_id});
    };

    this.search = function() {
      _this.new_params.page = 0;
      return _this.new_params.search = _this.search_field;
    };

    this.reset_search = function() {
      _this.search_field = '';
      return _this.search();
    };

    this.resort = function(clicked_on_col) {
      if (this.new_params.sort_column !== clicked_on_col) {
        this.new_params.sort_column = clicked_on_col;
        return this.new_params.sort_order = 'desc';
      } else {
        if (this.new_params.sort_order === 'desc') {
          return this.new_params.sort_order = 'asc';
        } else {
          return this.new_params.sort_order = 'desc';
        }
      }
    };

    this.sort_classes_for = function(column) {
      if (column === this.new_params.sort_column) {
        return ['apruve-icon-angle', this.new_params.sort_order];
      } else {
        return [];
      }
    };

    this.row_classes_for = function(payment) {
      var classes = [];
      classes.push('list-item-done');
      return classes;
    };

    this.load_new_page = function(new_value, old_value) {
      if ((new_value == null) || (old_value == null)) { return true; }
      if (new_value === old_value) { return true; }
      $state.go('.', new_value, {notify: false});
      if (new_value.page !== old_value.page) { return true; }
      return _this.url_params = angular.copy(new_value);
    };

    this.download_csv_template = function(){
      $http({method: 'GET', url: `/sellers/${merchant.id}/payments/download_csv_template.csv`}).
        success(function(data) {
          var anchor = angular.element('<a/>');
          anchor.attr({
              href: 'data:attachment/csv;charset=utf-8,' + encodeURI(data),
              target: '_blank',
              download: 'apruve_payment_upload_template.csv'
          })[0].click();
        }).
        error(function() {
          alert('Failed to download CSV Template')
        });
    };

    $scope.$watch("paymentsCtrl.new_params", this.load_new_page, true);
  }

]);