function refreshPage() {
  window.location.href = window.location.href;
}

function confirmReauthOrder(url) {
  $.ajax({
    type: "PUT",
    url: url,
    dataType: 'json',
    success: function () {
      refreshPage();
    },
    error: function(xhr, textStatus, error){
      console.log(xhr.statusText);
      console.log(textStatus);
      console.log(error);
    }
  });
}

function denyReauthOrder(url) {
  $.ajax({
    type: "PUT",
    url: url,
    dataType: 'json',
    success: function () {
      refreshPage();
    },
    error: function(xhr, textStatus, error){
      console.log(xhr.statusText);
      console.log(textStatus);
      console.log(error);
    }
  });
}

window.confirmReauthOrder = confirmReauthOrder;
window.denyReauthOrder = denyReauthOrder;