angular.module('apruve.users.bank_accounts').controller('PaymentMethodsBankAccountController', [
  '$scope', 'BankAccount', 'bank_accounts', '$window'
  ($scope, BankAccount, bank_accounts, $window) ->

    $scope.bank_account_resource = new BankAccount()
    $scope.bank_accounts = bank_accounts

    $scope.load_bank_accounts = () ->
      $scope.bank_accounts = BankAccount.query()

    $scope.cardTemplate = () ->
      require('../../../../templates/sellers/bank_account_card/debit_bank_account_card.html')

    $scope.modalTemplate = () ->
      require('../../../../templates/common/bank_accounts/bank_account_modal.html')

    $scope.start_verify = (account_id, account_strategy) ->
      if $scope.bank_accounts.length > 0
        account = $scope.bank_accounts[0]
        $('#verifyAccountModal').modal('show')
        $scope.$emit('bank_account.reset')
        $scope.$emit('verify_bank_account.load', account.uuid, account.strategy)

    $scope.$on 'bank_account.done', () ->
      $window.location.reload()

    $scope.$on 'bank_account.deleted', () ->
      $scope.load_bank_accounts()

    $scope.$on 'bank_account.verify_done', () ->
      $scope.load_bank_accounts()

    if $window.account_uuid
      $scope.start_create()
])