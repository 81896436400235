angular.module('apruve.sellers.funding_events').controller("SellersFundingEventController", [
    '$scope', '$state', '$stateParams', 'funding_event', 'fundingEventsSummary', SellersFundingEventController]);

function SellersFundingEventController($scope, $state, $stateParams, funding_event, fundingEventsSummary) {
  $scope.funding_event = funding_event;
  $scope.funding_details = funding_event.funding_details;
  $scope.is_ti = fundingEventsSummary.is_ti;
  $scope.merchant_id = $stateParams.merchant_id;

  const has_credit_memos = funding_event.funding_details.find(fd => fd.type === 'CreditMemo') !== undefined;
  $scope.invoice_or_credit_memo_label = has_credit_memos ? 'Invoice / Credit Memo' : 'Invoice';

  $scope.navigate_to_invoice_or_credit_memo = (invoice_or_credit_memo) => {
    if(invoice_or_credit_memo.type === 'CreditMemo') {
      $state.go('sellers.credit_memo', {
        credit_memo_uuid: invoice_or_credit_memo.invoice_uuid,
        merchant_id: $stateParams.merchant_id
      });
    } else {
      $state.go('sellers.invoice', {
        invoice_uuid: invoice_or_credit_memo.invoice_uuid,
        merchant_id: $stateParams.merchant_id
      });
    }
  }
}