function authHttpResponseInterceptor($q, $window, environment) {
  return {
    responseError: (rejection) => {
      if(rejection.status === 401) {
        $window.location = $window.location.pathname;
      } else if (rejection.status === 500) {
        let message = "Sorry, we appear to be experiencing technical problems. If this persists, please shoot us an email at support@apruve.com to let us know.";
        if(environment.is_test()) {
          message += angular.toJson(rejection.config, true);
        }
        alert(message);
      }
      return $q.reject(rejection);
    }
  }
}
angular.module('apruve.common.http')
  .factory('authHttpResponseInterceptor', ['$q', '$window', 'environment', authHttpResponseInterceptor]);
