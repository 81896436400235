angular.module('apruve.common.combodate').directive 'combodate', [() ->
  restrict: 'A'
  replace: true
  transclude: true
  require: 'ngModel'
  link: (scope, elem, attrs, ngModelCtrl) ->
    $(elem).combodate
      template: attrs['template'] || 'MMM DD YYYY'
      minYear: attrs['minYear'] || 1900
      customClass: attrs['customClass'] || ''
      firstItem: 'name'
      smartDays: true
      value: ngModelCtrl.$modelValue

    scope.$watch ->
      $(elem).combodate('getValue', ngModelCtrl.$modelValue)
    , (newVal) ->
      ngModelCtrl.$setViewValue newVal
      ngModelCtrl.$render()

    ngModelCtrl.$render = () ->
      $(elem).combodate('setValue', ngModelCtrl.$modelValue)
]
