angular.module('apruve.sellers.credit_memo').controller 'CancelCreditMemoController', [
  '$scope', 'CreditMemo', '$state', '$stateParams'
  ($scope, CreditMemo, $state, $stateParams) ->

    $scope.cancel_credit_memo = ->
      $scope.submitting = true
      credit_memo = new CreditMemo(merchant_id: $stateParams.merchant_id, uuid: $stateParams.credit_memo_uuid)
      credit_memo.$cancel (p) -> (
        $scope.result = 'success'
        $state.go('credit_memo', {}, {reload: true})
      ),
      (e) -> (
        $scope.result = 'error'
        console.error('Error while canceling credit_memo: ', angular.toJson(e.data, true))
        $scope.errors = e.data.errors
        return
      )

    $scope.button_options =
      buttonDefaultText: 'Cancel Credit Memo'
      buttonSubmittingText: 'Canceling...'
      buttonSuccessText: 'Canceled'
      buttonErrorText: 'Error'
      buttonDefaultClass: 'actionBar-btn-primary'
      buttonSubmittingClass: 'actionBar-btn-primary'
      buttonSuccessClass: 'actionBar-btn-primary'
      buttonErrorClass: 'actionBar-btn-danger'
]