import "core-js/stable";
import "regenerator-runtime/runtime";

import 'trackjs';

import 'angular';
import 'angular-ui-router';
import 'angular-ui-bootstrap';
import 'ui-select';
import 'angular-resource';
import 'angular-animate';
import 'angular-sanitize';
import 'angular-dropdowns';
import 'angular-paginate-anything';
import 'angular-xml';
import 'angular-translate';
import 'angular-translate-interpolation-messageformat';

import 'highcharts';
import 'highcharts/modules/exporting.js';
import 'highcharts/modules/offline-exporting.js';
import 'highcharts/modules/no-data-to-display.js';
import 'highcharts/modules/stock.js';
import 'highcharts/modules/map.js';
import 'highcharts/modules/heatmap.js';
import 'highcharts/modules/tilemap.js';
import 'highcharts-export-csv';

import 'lodash';
import 'clipboard';
import 'select2';
import 'google-libphonenumber';
import 'tv4';
import 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/datepicker';
import 'external/combodate';
import 'currency.js';

import setupSlidingLabels from "../javascripts/sliding-labels";
setupSlidingLabels();

import { passwordStrengthValidator } from "../javascripts/devise/password_strength";
passwordStrengthValidator();

require('script-loader!blueimp-file-upload/js/vendor/jquery.ui.widget.js');
require('script-loader!blueimp-file-upload/js/jquery.iframe-transport.js');
require('script-loader!blueimp-file-upload/js/jquery.fileupload.js');
require('script-loader!blueimp-tmpl/js/tmpl.js');

import 'external/s3_direct_upload';

require("expose-loader?$!jquery");
require('turbolinks').start();
require("@rails/ujs").start();
require('bootstrap');
require('ng-file-upload');


function importAll (r) {
  r.keys().forEach(r);
}

importAll(require.context('../javascripts/angular', true));
importAll(require.context('../javascripts/tablesorter', true));


require('admin_query_filter');
require('creditor_csv_filter');
require('ajax_error_handler.js.coffee');
require('api_key.js.coffee');
require('auto_submit.js.coffee');
require('bootstrap-fileinput.min.js');
require('bootstrap_confirm.js.coffee');
require('button_animation.js.coffee');
require('ga_env.js.coffee');
require('image_uploader.js.coffee');
require('main__clickable-tr.js.coffee');
require('ng-bs-animated-button.js');
require('postcode-validator.js');
require('quote.js');
require('reauth.js');
require('routing_number_validation.js');
require('sliding-labels.js');
require('smartscroll.js');
require('spin.min.js');
require('subscription_plan_form.js.coffee');
require('tab_nav.js.coffee');
require('textarea_character_counter.js.coffee');
require('user-agent-detect.js');
require('vendor_suggestion_validator.js.coffee');

/*
  Need to require it since we don't do so anywhere else (all the other templates have a require(...) where they are
  being used) This one is special cased because we are hardcoding the path due to a limitation on the external angular
  plugin we are using for the pagination.
*/
require('../templates/shared/paginate_anything.html');

importAll(require.context('images', true));
