angular.module('apruve.common.payment_modal').directive('paymentMethodCard', ['images', paymentMethodCard]);

function paymentMethodCard(images) {
  return {
    restrict: 'E',
    template: '<div ng-include="resolveTemplate()"></div>',
    scope: {
      paymentMethod: '=',
      cardTemplate: '='
    },
    link: function(scope) {
      scope.images = images; // Used in some templates
      scope.resolveTemplate = function() {
        return scope.cardTemplate;
      };
    }
  };
}