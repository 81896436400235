import { TrackJS } from 'trackjs'

angular.module('apruve.users.app.routes', [])
.config [
  '$stateProvider', '$urlRouterProvider', 'paymentModalStateProvider',
  ($stateProvider, $urlRouterProvider, paymentModalStateProvider) ->
    initialParams =
      search:
        value: ''
        squash: true
      page:
        value: '0'
        squash: true
      sort_column:
        value: ''
        squash: true
      sort_order:
        value: ''
        squash: true
    $urlRouterProvider
    .when('/personal', '/')
    .when('/dashboard', '/')
    .when('/', '/accounts')

    $stateProvider
    .state 'bank_accounts',
      title: 'Bank Accounts'
      url: '/payment_methods/bank_accounts'
      controller: 'PaymentMethodsBankAccountController'
      templateUrl: require('../../../templates/bank_accounts/index.html')
      resolve:
        bank_accounts: ['BankAccount', (BankAccount) ->
          BankAccount.query().$promise
        ]

    # PurchaseOrder
    $stateProvider
    .state 'purchase_order',
      title: 'Order'
      url: '/purchase_orders/:purchase_order_id'
      controller: "PurchaseOrderController"
      templateUrl: require('../../../templates/purchase_orders/show.html')
      resolve:
        purchase_order_data: ['PurchaseOrder', '$stateParams', (PurchaseOrder, $stateParams) ->
          PurchaseOrder.get(purchase_order_id: $stateParams.purchase_order_id).$promise
        ]
    .state 'purchase_order.invoices',
      title: 'Invoices on this order'
      url: '/invoices'
      controller: 'PurchaseOrderInvoicesController'
      templateUrl: require('../../../templates/purchase_orders/invoices.html')
    #.state 'purchase_order.po_number_update',
    #  title: 'Update Order'
    #  controller: "PurchaseOrderUpdateController"
    #  templateUrl: require('../../../templates/purchase_orders/update_po_number.html')
    .state 'purchase_order.approve_quote',
      title: 'Approve Quote'
      url: '/quotes/:quote_id/approve_in_app'
      controller: "QuoteController"
      templateUrl: require('../../../templates/quotes/approve_quote.html')
      resolve:
        quote_data: ['Quote', '$stateParams', (Quote, $stateParams) ->
          Quote.get(purchase_order_id: $stateParams.purchase_order_id, quote_id: $stateParams.quote_id).$promise
        ]
    .state 'purchase_order.reject_quote',
      title: 'Reject Quote'
      url: '/quotes/:quote_id/reject_in_app'
      controller: "QuoteController"
      templateUrl: require('../../../templates/quotes/reject_quote.html')
      resolve:
        quote_data: ['Quote', '$stateParams', (Quote, $stateParams) ->
          Quote.get(purchase_order_id: $stateParams.purchase_order_id, quote_id: $stateParams.quote_id).$promise
        ]

    # Payment
    $stateProvider
    .state 'payments',
      title: 'Payments'
      url: '/payments?search&page&sort_column&sort_order'
      controller: 'PaymentsController as ctrl'
      templateUrl: require('../../../templates/payments/index.html')
    .state 'payment',
      title: 'Payment'
      url: '/payments/:payment_uuid'
      controller: 'PaymentController'
      controllerAs: 'ctrl'
      templateUrl: require('../../../templates/payments/show.html')
      resolve:
        payment_data: ['Payment', '$stateParams', (Payment, $stateParams) ->
          Payment.get(uuid: $stateParams.payment_uuid).$promise
        ]
    .state 'payment.invoices',
      title: 'Payment Invoices'
      url: '/invoices?page'
      controller: 'PaymentInvoicesController'
      controllerAs: 'ctrl'
      templateUrl: require('../../../templates/payments/invoices.html')
      params: { page: { value: '0', squash: true } }
    .state 'payment.apply',
      title: 'Payment Apply'
      url: '/apply?search&page&sort_column&sort_order'
      controller: 'PaymentApplyController'
      controllerAs: 'ctrl'
      templateUrl: require('../../../templates/payments/apply.html')
      params: angular.copy initialParams
      resolve:
        invoices: ['payment_data', 'Payment', '$stateParams', (payment_data, Payment, $stateParams) ->
           Payment.available_invoices(uuid: $stateParams.payment_uuid).$promise
        ]
        auto_apply_invoices: ['payment_data', 'Payment', '$stateParams', (payment_data, Payment, $stateParams) ->
          Payment.auto_apply_invoices(uuid: $stateParams.payment_uuid).$promise
        ]
    .state 'payment.cancel',
      title: 'Cancel Payment'
      url: '/cancel'
      controller: "CancelPaymentController"
      templateUrl: require('../../../templates/payments/cancel_payment_form.html')

    # Credit Memo
    $stateProvider
    #.state 'credit_memos',
    #  title: 'Credit Memos'
    #  url: '/credit_memos?search&page&sort_column&sort_order'
    #  controller: 'CreditMemosController as ctrl'
    #  templateUrl: require('../../../templates/credit_memos/index.html')
    .state 'credit_memo',
      title: 'Credit Memo'
      url: '/credit_memos/:credit_memo_uuid'
      controller: 'CreditMemoController'
      controllerAs: 'ctrl'
      templateUrl: require('../../../templates/credit_memos/show.html')
      resolve:
        credit_memo_data: ['CreditMemo', '$stateParams', (CreditMemo, $stateParams) ->
          CreditMemo.get(uuid: $stateParams.credit_memo_uuid).$promise
        ]
    .state 'credit_memo.invoices',
      title: 'Credit Memo Invoices'
      url: '/invoices?page'
      controller: 'CreditMemoInvoicesController'
      controllerAs: 'ctrl'
      templateUrl: require('../../../templates/credit_memos/invoices.html')
      params: { page: { value: '0', squash: true } }
#    .state 'credit_memo.cancel',
#      title: 'Cancel Credit Memo'
#      url: '/cancel'
#      controller: "CancelCreditMemoController"
#      templateUrl: require('../../../templates/credit_memos/cancel_credit_memo_form.html')

    # Invoice
    $stateProvider
#    .state 'invoices',
#      title: 'Invoices'
#      url: '/invoices?search&page&sort_column&sort_order'
#      controller: 'InvoicesController'
#      controllerAs: 'invoicesCtrl'
#      templateUrl: require('../../../templates/invoices/index.html')
#      params: angular.copy initialParams
    .state 'invoice',
      title: 'Invoice'
      url: '/invoices/:invoice_uuid'
      resolve:
        invoice_data: ['Invoice', '$stateParams', 'payableModelService', (Invoice, $stateParams, payableModelService) ->
          invoice = Invoice.get(uuid: $stateParams.invoice_uuid).$promise
          payableModelService.set_invoice_model(invoice)
          invoice
        ],
        payment_instructions: ['$http', ($http) ->
          $http.get('/how_do_i_pay', headers: {
            'Accept': 'text/html,application/xhtml+xml'
          })
        ]
      controller: "InvoiceController"
      templateUrl: require('../../../templates/invoices/show.html')
    .state 'invoice.payments',
      title: 'Invoice Payments'
      url: '/payments'
      controller: 'InvoicePaymentsController'
      templateUrl: require('../../../templates/invoices/payments.html')
    .state 'invoice.shipments',
      title: 'Invoice Shipments'
      url: '/shipments'
      templateUrl: require('../../../templates/shipments/index.html')
      controller: 'ShipmentsController'
      controllerAs: 'shipments_ctrl'
      resolve:
        shipment_data: ['Shipment', '$stateParams', (Shipment, $stateParams) ->
          Shipment.query(invoice_id: $stateParams.invoice_uuid).$promise
        ]

    paymentModalStateProvider.createNestedState($stateProvider, 'invoice.payments');

    $stateProvider
    .state 'shipment',
      title: 'Shipment'
      templateUrl: require('../../../templates/shipments/show.html')
      resolve:
        shipment: ['Shipment', '$stateParams', (Shipment, $stateParams) ->
          Shipment.get(invoice_id: $stateParams.invoice_id, shipment_id: $stateParams.shipment_id).$promise
        ]
      url: '/invoices/:invoice_id/shipments/:shipment_id'
      controller: 'ShipmentController'
      controllerAs: 'shipment_ctrl'

    # Statement
    $stateProvider
#    .state 'statements',
#      title: 'Statements'
#      url: '/statements?search&page&sort_column&sort_order'
#      controller: 'StatementsController'
#      controllerAs: 'statementsCtrl'
#      templateUrl: require('../../../templates/statements/index.html')
#      params: angular.copy initialParams
    .state 'statement',
      title: 'Statement'
      url: '/statements/:statement_id?page'
      controller: 'StatementController'
      templateUrl: require('../../../templates/statements/show.html')
      resolve:
        statement_data: ['Statement', '$stateParams', 'payableModelService', (Statement, $stateParams, payableModelService) ->
          statement = Statement.get(id: $stateParams.statement_id).$promise
          payableModelService.set_statement_model(statement)
          statement
        ]
    .state 'statement.payments',
      title: 'Payments for Statement'
      url: '/payments?page'
      controller: 'StatementPaymentsController'
      controllerAs: 'statementpaymentsController'
      templateUrl: require('../../../templates/statements/payments.html')

    paymentModalStateProvider.createNestedState($stateProvider, 'statement.payments');

    # Corporate Accounts
    $stateProvider
    .state 'accounts',
      title: 'Accounts'
      templateUrl: require('../../../templates/accounts/index.html')
    .state 'accounts.active',
      controller: 'CorporateAccountsController'
      url: '/accounts'
      templateUrl: require('../../../templates/accounts/accounts.html')
      resolve:
        corporate_accounts_data: ['CorporateAccount', (CorporateAccount) ->
          CorporateAccount.query().$promise
        ]
    .state 'account',
      url: '/accounts/:corporate_account_id'
      controller: 'CorporateAccountController as corpAcctCtrl'
      templateUrl: require('../../../templates/accounts/show.html')
      resolve:
        corporate_account_data: ['CorporateAccount', '$stateParams', (CorporateAccount, $stateParams) ->
          CorporateAccount.get(uuid: $stateParams.corporate_account_id).$promise
        ]
        payment_methods: ['PaymentMethod', '$stateParams', (PaymentMethod, $stateParams) ->
          PaymentMethod.query(corp_acct_id: $stateParams.corporate_account_id).$promise
        ]
        account_performance: ['AccountPerformance', '$stateParams', (AccountPerformance, $stateParams) ->
          AccountPerformance.get(account_id: $stateParams.corporate_account_id).$promise
        ]
        corporate_account_payment_method: ['CorporateAccountPaymentMethod', '$stateParams', (CorporateAccountPaymentMethod, $stateParams) ->
          CorporateAccountPaymentMethod.get(account_id: $stateParams.corporate_account_id).$promise
        ]

    .state 'getting_started',
      url: '/accounts/:corporate_account_id/getting_started'
      title: 'Getting Started'
      controller: 'GettingStartedAccountController as getStartCtrl'
      templateUrl: require('../../../templates/getting_started/setup_account.html')
      resolve:
        corporate_account_data: ['CorporateAccount', '$stateParams', (CorporateAccount, $stateParams) ->
          CorporateAccount.get(uuid: $stateParams.corporate_account_id).$promise
        ]
    .state 'setup_team',
      url:  '/accounts/:corporate_account_id/setup_team'
      resolve:
        corporate_account_data: ['CorporateAccount', '$stateParams', (CorporateAccount, $stateParams) ->
          CorporateAccount.get(uuid: $stateParams.corporate_account_id).$promise
        ]
#      title: 'Setup Purchasing Team'
#      controller: 'GettingStartedTeamController as setupTeamCtrl'
#      templateUrl: require('../../../templates/getting_started/setup_team.html')
    .state 'getting_started.finished',
      url: '/finished'
      title: 'Finished'
      templateUrl: require('../../../templates/getting_started/finished.html')

    .state 'account.add_buyer',
      title: 'Add Buyer'
      url: '/add_buyer'
      controller: 'CorporateAccountController as corpAcctCtrl'
      templateUrl: require('../../../templates/accounts/add_buyer.html')
    .state 'account.request_limit_change',
      title: 'Request Credit Limit Change',
      url: '/credit_limit_change_requests/new',
      controller: 'CreditLimitChangeRequestController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/accounts/credit_limit_change_request.html')
    .state 'account.request_limit_change.success',
      title: 'Credit Limit Change Requested',
      url: '/success',
      controller: 'CreditLimitChangeRequestController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/accounts/credit_limit_change_requested.html')

    # Corporate Account Team Members
    #.state 'account.new_team_member',
    #  url: '/team_members/new'
    #  controller: 'TeamMemberController'
    #  controllerAs: '$ctrl'
    #  templateUrl: require('../../../templates/team_members/new.html')
    #  resolve:
    #    corporate_account: ['CorporateAccount', '$stateParams', (CorporateAccount, $stateParams) ->
    #      CorporateAccount.get(uuid: $stateParams.corporate_account_id).$promise
    #    ]
    #    team_member_data: ['TeamMember', '$stateParams', (TeamMember, $stateParams) ->
    #      new TeamMember(buyer: true)
    #    ]

    .state 'team_member',
      url: '/team_members/:team_member_id'
      controller: 'EditTeamMemberController'
      controllerAs: '$ctrl'
      templateUrl: require('../../../templates/team_members/show.html')
      resolve:
        team_member_data: ['TeamMember', '$stateParams', (TeamMember, $stateParams) ->
          TeamMember.get(uuid: $stateParams.team_member_id).$promise
        ]
]
.run ['$rootScope', '$state', ($rootScope, $state) ->
  $rootScope.$on '$stateChangeError', (event, toState, toParams, fromState, fromParams, error) ->
    TrackJS.console.error "Error changing state from #{fromState.name} to #{toState.name}. Error: #{JSON.stringify(error)}"
    return
]
