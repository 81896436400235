angular.module('apruve.common.bank_account').directive('bankAccountCard', function() {
  return {
    require: 'ngModel',
    restrict: 'E',
    scope: {
      bankAccount: '=',
      bankAccountResource: '=',
      cardTemplate: '=', // function to return template for the card
      modalTemplate: '=', // function to return template for the modal
      modalController: '@', // optional controller for the modal
      contextParams: '=', // optional parameters if you need to inject something extra for the context, e.g. corporate account
      countryCode: '='
    }, // optional if you want to allow non-usa bank accounts
    template: '<ng-include src="cardTemplate()"/>',

    controller: [
      '$scope', '$uibModal', 'currentUserService', 'environment', 'images',
      function($scope, $uibModal, currentUserService, environment, images) {

        $scope.load_bank_accounts = function() {
          return $scope.bankAccount.$get().then(function(bank_account) {
            return $scope.bankAccount = bank_account;
          });
        };

        $scope.verify_required = function() {
          return ($scope.bankAccount != null) && !$scope.bankAccount.verified;
        };

        $scope.account_exists = function() {
          return ($scope.bankAccount != null) && $scope.bankAccount.exists;
        };

        $scope.show_form = function() {
          return !$scope.account_exists();
        };

        $scope.delete_account = function() {
          return $scope.bankAccount.$delete(function() {
            $scope.mode = 'base';
            $scope.load_bank_accounts();
            $scope.$emit('bank_account.deleted');
            return $scope.close_modal($scope.deleteAccountModal);
          }
          , function(error) {
            return $scope.close_modal($scope.deleteAccountModal);
          });
        };

        $scope.start_create = function() {
          $scope.mode = 'create';
          return $scope.$emit('bank_account.reset');
        };

        $scope.start_verify = function() {
          $scope.mode = 'verify';
          $scope.$emit('bank_account.reset');
          return $scope.$emit('verify_bank_account.load', null, $scope.bankAccount.strategy);
        };

        $scope.close_modal = function(modal) {
          return modal.dismiss();
        };

        $scope.$on('start.create', function() {
          $scope.start_create();
          return $scope.open_modal();
        });

        // Both Forms
        $scope.$on('bank_account.done', function() {
          $scope.load_bank_accounts();
          $scope.mode = 'base';
          return $scope.close_modal($scope.bankAccountModal);
        });

        $scope.$on('bank_account.verify_done', function() {
          $scope.load_bank_accounts();
          $scope.mode = 'base';
          return $scope.close_modal($scope.bankAccountModal);
        });

        $scope.open_modal = function() {
          var left;
          var templateUrl = $scope.modalTemplate();
          $scope.images = images;
          return $scope.bankAccountModal = $uibModal.open({
            animation: !environment.is_test(),
            ariaLabelledBy: 'addBankAccountModal',
            templateUrl: templateUrl,
            controller: $scope.modalController || null,
            scope: $scope,
            windowClass: (left = environment.is_test()) != null ? left : {'v2' : 'v2 fade'},
            size: 'lg'
          });
        };

        $scope.open_delete_modal = function() {
          var left;
          return $scope.deleteAccountModal = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'deleteBankAccountModal',
            templateUrl: require('../../../../templates/common/bank_accounts/delete_bank_account_modal.html'),
            scope: $scope,
            windowClass: (left = environment.is_test()) != null ? left : {'modal v2' : 'modal v2 fade'}
          });
        };

      }
    ]
  };
});