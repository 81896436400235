angular.module('apruve.users.payment')
    .controller('PaymentApplyController',['$scope', 'payment_data', '$state', '$stateParams', '$window', 'Payment', 'invoices', 'auto_apply_invoices', 'money', 'applyInstructionModal', PaymentApplyController]);
function PaymentApplyController($scope, payment_data, $state, $stateParams, $window, Payment, invoices, auto_apply_invoices, money, applyInstructionModal) {
  let vm = this;
  vm.payment = payment_data;
  const currency = money.currency(vm.payment.currency_data);
  vm.invoices = invoices;
  vm.auto_apply_invoices = auto_apply_invoices
  vm.selected_invoices = [];
  vm.invoices_map = {};
  vm.currency_symbol = vm.payment.currency_data.symbol;
  vm.zero_placeholder = currency(0).format({ symbol: '' });

  vm.invoice_apply_data = {};
  vm.total_amount = currency(0);
  vm.remaining_amount = currency(payment_data.available_amount);
  vm.auto_apply_checked = false;

  vm.new_params = angular.copy($stateParams);
  vm.new_params.page = parseInt(vm.new_params.page);
  vm.url_params = angular.copy(vm.new_params);
  vm.max_per_page = 10;

  vm.search_field = vm.url_params.search;

  vm.submit_options = {
    buttonDefaultText: 'Apply Payment',
    buttonSubmittingText: 'Applying...',
    buttonSuccessText: 'Payment Applied',
    buttonErrorText: 'Invalid Amount Input',
    buttonDefaultClass: 'button',
    buttonSubmittingClass: 'button',
    buttonSuccessClass: 'button',
    buttonErrorClass: 'button'
  };

  vm.result = null;
  vm.submitting = false;

  vm.url_prefix = function () {
    if ($scope.$resolve.url_prefix) {
      return '/' + $scope.$resolve.url_prefix
    }
    return ''
  };

  vm.url_prop = function () {
    return vm.url_prefix() + '/payments/' + vm.payment.uuid + '/apply/available_invoices.json';
  };

  vm.url = function () {
    return vm.url_prefix() + '/payments/' + vm.payment.uuid + '/invoices.json';
  };

  vm.open_modal = () => {
    applyInstructionModal.open_modal(vm.payment, currency);
  };
  if (!$scope.$resolve.url_prefix) {
    vm.open_modal(); // Start with the modal opened
  }

  vm.amount_placeholder = (amount) => {
    return currency(amount).format({ symbol: '' })
  };

  vm.update_data = (uuid) => {
    vm.format_amount(uuid);
    vm.uncheck_if_needed(uuid);
    vm.total_amount = vm.calculate_total();
    vm.remaining_amount = vm.calculate_remaining(vm.total_amount)
  };

  vm.checkbox_updated = (uuid) => {
    let index = vm.selected_invoices.findIndex(invoice => invoice.uuid === uuid);
    if(vm.invoice_apply_data[uuid].apply){
      if (vm.invoices_map[uuid].amount) {
        vm.invoice_apply_data[uuid].amount = vm.invoices_map[uuid].amount
      }
      vm.format_amount(uuid);

      if (index === -1) { vm.selected_invoices.push(vm.invoices_map[uuid]) }
    } else {
      delete vm.invoice_apply_data[uuid];
      vm.selected_invoices.splice(index, 1)
    }
    vm.total_amount = vm.calculate_total();
    vm.remaining_amount = vm.calculate_remaining(vm.total_amount)
  };

  vm.uncheck_if_needed = (uuid) => {
    let index = vm.selected_invoices.findIndex(invoice => invoice.uuid === uuid);
    let invoice_entry = vm.invoice_apply_data[uuid];
    if(currency(invoice_entry.amount).intValue === 0 ) {
      vm.invoice_apply_data[uuid].apply = false;
      delete vm.invoice_apply_data[uuid];
      vm.selected_invoices.splice(index, 1)
    }
  };

  vm.check_invoice = (uuid) => {
    let invoice_entry = vm.invoice_apply_data[uuid];
    if(typeof invoice_entry === 'undefined') vm.invoice_apply_data[uuid] = {};
    vm.invoice_apply_data[uuid].apply = true
  };

  vm.format_amount = (uuid) => {
    let invoice_entry = vm.invoice_apply_data[uuid];
    let amount = currency(invoice_entry.amount);
    if(amount.intValue > currency(vm.invoices_map[uuid].amount_due_display).intValue) {
      amount = currency(vm.invoices_map[uuid].amount_due_display)
    }

    const remaining_excluding_current = vm.calculate_remaining(vm.calculate_total_with_exception(uuid));
    if(amount.intValue > remaining_excluding_current.intValue) {
      amount = remaining_excluding_current;
    }

    vm.invoice_apply_data[uuid].amount = amount.format({ symbol: '' });
  };

  vm.auto_apply_changed = () => {
    if(vm.auto_apply_checked) {
      vm.auto_apply();
    } else {
      vm.unapply_all();
    }

    vm.total_amount = vm.calculate_total();
    vm.remaining_amount = vm.calculate_remaining(vm.total_amount)
  };

  vm.auto_apply = () => {
    let remaining = currency(vm.payment.available_amount);
    vm.auto_apply_invoices.some((invoice) => {
      let index = vm.selected_invoices.findIndex(selected_invoice => invoice.uuid === selected_invoice.uuid);
      if (index === -1) { vm.selected_invoices.push(invoice) }
      console.log(invoice)
      const invoice_amount_due = currency(invoice.amount_due_display);
      const amount_to_apply = invoice_amount_due.intValue <= remaining.intValue ? invoice_amount_due : remaining;
      vm.invoice_apply_data[invoice.uuid] = { amount: amount_to_apply.format({ symbol: '' }), apply: true };
      remaining = remaining.subtract(amount_to_apply);
      return remaining.intValue <= 0
    })
  };

  vm.unapply_all = () => {
    Object.keys(vm.invoice_apply_data).forEach((uuid) => {
      let index = vm.selected_invoices.findIndex(selected_invoice => uuid === selected_invoice.uuid);
      vm.selected_invoices.splice(index, 1)
    });
    vm.invoice_apply_data = {};
  };

  vm.calculate_remaining = (total) => {
    return currency(vm.payment.available_amount).subtract(total)
  };

  vm.calculate_total_with_exception = (uuid_to_skip) => {
    const amount_to_skip = currency(vm.invoice_apply_data[uuid_to_skip].amount);
    return vm.calculate_total().subtract(amount_to_skip);
  };

  vm.calculate_total = () => {
    return Object.keys(vm.invoice_apply_data).filter((uuid) => {
      return vm.invoice_apply_data[uuid].apply
    }).map((uuid) => {
      return currency(vm.invoice_apply_data[uuid].amount);
    }).reduce((acc, amount) => acc.add(amount), currency(0));
  };

  vm.apply_amounts_valid = () => {
    let valid = true;
    if (Object.keys(vm.invoice_apply_data).length === 0){
      valid = false
    }
    for (const prop in vm.invoice_apply_data) {
      if (vm.invoice_apply_data[prop].apply && currency(vm.invoice_apply_data[prop].amount).intValue == 0) {
        valid = false
      }
    }
    return valid
  };

  vm.state_prefix = function () {
    if ($scope.$resolve.url_prefix) {
      return $scope.$resolve.url_prefix + '.'
    }
    return ''
  };

  vm.apply_payment = () => {
    vm.submitting = true;
    if (!vm.apply_amounts_valid()) {
      vm.result = 'error';
      return false
    }

    Payment.apply({ uuid: vm.payment.uuid, invoices_apply_data: vm.invoice_apply_data }).$promise.then((_data) => {
      vm.result = 'success';

      if (vm.url_prefix().replace('/','') === 'admin') {
        $window.location.assign(`/admin/payments/${vm.payment.uuid}`);
      }
      else {
        $state.go(vm.state_prefix() + 'payment.invoices');
      }
    }, (_error) => {
      vm.result = 'error';
    })
  };

  vm.search = function() {
    vm.new_params.page = 0;
    return vm.new_params.search = vm.search_field;
  };

  vm.reset_search = function() {
    vm.search_field = '';
    return vm.search();
  };

  vm.resort = function(clicked_on_col) {
    if (vm.new_params.sort_column !== clicked_on_col) {
      vm.new_params.sort_column = clicked_on_col;
      return vm.new_params.sort_order = 'desc';
    } else {
      if (vm.new_params.sort_order === 'desc') {
        return vm.new_params.sort_order = 'asc';
      } else {
        return vm.new_params.sort_order = 'desc';
      }
    }
  };

  vm.sort_classes_for = function(column) {
    if (column === vm.new_params.sort_column) {
      return ['apruve-icon-angle', vm.new_params.sort_order];
    } else {
      return [];
    }
  };

  vm.load_new_page = function(new_value, old_value) {
    if ((new_value == null) || (old_value == null) || (new_value === old_value)) { return true; }
    $state.go('.', new_value, {notify: false});
    if (new_value.page !== old_value.page) { return true; }
    return vm.url_params = angular.copy(new_value);
  };

  vm.invoices_updated_by_page = function(new_value, old_value) {
    new_value.forEach((invoice) => { vm.invoices_map[invoice.uuid] = invoice });
  };


  $scope.$watch("ctrl.new_params", vm.load_new_page, true);
  $scope.$watch("ctrl.invoices", vm.invoices_updated_by_page, true);
}
