angular.module('apruve.common.corporate_account').directive('creditLimitIncreaseLink', function() {
  return {
    restrict: 'E',
    scope: {
      corporateAccount: '=',
      nextState: '='
    },
    templateUrl: require('../../../../templates/common/corporate_accounts/credit_limit_increase_link.html'),
    link: function(scope) {

      scope.creditor_msts = function() {
        return scope.corporateAccount.creditor_name === 'MSTS';
      }
      scope.credit_limit_change_pending = function() {
        return scope.corporateAccount.credit_limit_change_pending;
      };

      scope.can_edit_credit_limit = function() {
        return scope.corporateAccount.self_financed && !scope.credit_limit_change_pending() && !scope.creditor_msts();
      };

      scope.can_request_increase = function() {
        return (!scope.corporateAccount.self_financed && scope.corporateAccount.has_credit_limit &&
                !scope.credit_limit_change_pending() && !scope.creditor_msts());
      };

      scope.show_bullet = function() {
        scope.corporateAccount.self_financed && (scope.credit_limit_change_pending() || scope.can_edit_credit_limit() || scope.can_request_increase())
      }
    }
  };
});