import { TrackJS } from 'trackjs'

angular.module('apruve.sellers.app').controller "SellersBaseController", [
  '$scope', '$stateParams', '$window', 'CurrentUser', 'icons', 'environment', 'images',
  ($scope, $stateParams, $window, CurrentUser, icons, environment, images) ->
    currentPath = $window.location.pathname.split('/')
    $scope.stateParams = $stateParams
    $scope.current_merchant_id = currentPath[2]
    $scope.current_user = CurrentUser
    $scope.environment = environment
    $scope.icons = icons;
    $scope.images = images;
    if $scope.current_user.merchants
      $scope.self_financed = false
      $scope.bank_financed = false
      for o in $scope.current_user.merchants
        if o.id == $scope.current_merchant_id
          $scope.self_financed = o.self_financed
          $scope.bank_financed = o.bank_financed

    $scope.$on '$stateChangeSuccess', (event, toState) ->
      $scope.current_merchant_id = $scope.stateParams.merchant_id
      TrackJS.console.log(toState.name)

    $scope.$on '$stateChangeError', (event, toState, toParams, fromState, fromParams, error) ->
      TrackJS.console.error "Error changing state from #{fromState.name} to #{toState.name}. Error: #{JSON.stringify(error)}"
      return
]