angular.module 'apruve.users.app', [
  'ui.router'
  'ngAnimate'
  'ngSanitize'
  'pascalprecht.translate'
  'apruve.common'
  'apruve.users.services'
  'apruve.users.app.routes'
  'apruve.users.corporate_account'
  'apruve.users.getting_started'
  'apruve.users.shipment'
  'apruve.users.team_member'
  'apruve.users.payment_method'
  'apruve.users.purchase_order'
  'apruve.users.invoice'
  'apruve.users.payment'
  'apruve.users.credit_memo'
  'apruve.users.quote'
  'apruve.users.statement'
  'apruve.users.bank_accounts'
  'apruve.users.credit_applications'
  'apruve.users.account_performance'
  'apruve.users.stats'
  'apruve.ng-bs-animated-button'
  'ui.bootstrap'
  'bgf.paginateAnything'
  'angular-aba-routing-validation'
  'apruve.common.angular_iban_validation'
  'apruve.common.bank_account_format'
  'apruve.common.moment'
]
