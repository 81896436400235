
angular.module('apruve.users.app')
.controller("BuyersBaseController", [
  '$scope', 'stats', 'currentUserService', '$uibModal', '$state', 'environment',
  function($scope, stats, currentUserService, $uibModal, $state, environment) {
    $scope.stats = stats;
    $scope.environment = environment;
    $scope.current_user = currentUserService.user;
    return $scope.current_user.$promise.then(user => {
      $scope.is_admin = $scope.current_user.is_admin;
      // set locale for buyers app
      localStorage.setItem("locale", user.locale);
      if (!user.msts_migration_complete && ($state.current.name = 'accounts.active')) {
        const msts_terms_modal = $uibModal.open({
          animation: true,
          backdrop: true,
          controller: 'MstsTosController',
          keyboard: true,
          templateUrl: require('../../../templates/msts/new_terms.html'),
          resolve: {
            current_user: [() => $scope.current_user
            ]
          }
        });
        return msts_terms_modal.result.then(user => {
          return console.log(`MSTS terms Modal dismissed at: ${new Date}`);
        });
      }
    });
  }

]);