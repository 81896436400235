angular.module("apruve.sellers.corporate_account").controller("CorporateAccountTeamMembersController", [
    "$stateParams", "statistics",
    function ($stateParams, statusIconsService) {
      this.statusIconsService = statusIconsService;
      this.url_params = $stateParams;
      this.url_params.page = parseInt(this.url_params.page);
      this.new_params = angular.copy(this.url_params);
      this.team_members_url = `/sellers/${this.url_params.merchant_id}/corporate_accounts/${this.url_params.corporate_account_id}/team_members.json`;
    },
  ]);
