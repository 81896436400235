import Highcharts from 'highcharts';

angular.module('apruve.sellers.performance').controller('PerformanceInvoiceController', [
  'Performance', 'chart', '$state', '$scope', '$stateParams', 'currentUserService', 'chartColors',
  function(Performance, chart, $state, $scope, $stateParams, currentUserService, chartColors) {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });

    $scope.$on('performance.period.update', (event, period) => {
      return Performance.Invoices.get({date_range: period.value, merchant_id: $stateParams.merchant_id}).$promise.then(data => {
        const series = {
          data: data.chart_data,
          pointStart: Date.parse(data.start_date),
          pointIntervalUnit: data.point_interval_unit
        };
        this.chartControl.updateSeries(series, 0);
        this.chart_data = data;
        return this.not_enough_data = chart.chart_data.reduce(this.all_values_zero, true);
      });
    });

    this.chartControl = {};
    this.tooltip_date_format_change = function (unit) {
      return (unit === 'month') ? '%B %Y' : '%B %e, %Y';
    };
    this.chartConfig = {
      chart: {
        backgroundColor: 'transparent',
        type: 'area',
        height: 240
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      plotOptions: {
        series: {
          fillOpacity: 0.1,
          lineWidth: 3,
          marker: {
            enabled: false
          },
          dataLabels: {
            enabled: false
          },
          label: {
            enabled: false
          }
        }
      },
      menuItemStyle: {
        fontWeight: 'normal',
        background: 'none'
      },
      menuItemHoverStyle: {
        fontWeight: 'bold',
        background: '#1784B0',
        color: '#fff'
      },
      tooltip: {
        backgroundColor: '#fff',
        borderRadius: 4,
        borderColor: '#ccc',
        borderWidth: 1,
        footerFormat: '</table>',
        headerFormat: '<small>{point.key}</small><table>',
        pointFormat: '<tr><td><b>${point.y}</b></td></tr>',
        shadow: false,
        shared: true,
        useHTML: true,
        split: false,
        valueDecimals: 2,
        xDateFormat: this.tooltip_date_format_change(chart.point_interval_unit)
      },
      series: [{
        data: chart.chart_data,
        color: chartColors.primary, // fill color
        pointStart: Date.parse(chart.start_date),
        pointIntervalUnit: chart.point_interval_unit,
        showInLegend: false,
        states: {
          hover: {
            enabled: false
          }
        }
      }],
      loading: false,
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%b %e',
          week: '%b %e',
          month: '%b, %Y',
          year: '%Y'
        }
      },
      yAxis: {
        opposite:  false,
        labels: {
          enabled: true
        },
        title: {
          text: 'Invoice Volume'
        }
      }
    };

    this.all_values_zero = (accumulator, currentValue) => accumulator && (currentValue === 0);
    this.chart_data = chart;
    this.not_enough_data = chart.chart_data.reduce(this.all_values_zero, true);

  }
]);
