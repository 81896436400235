angular.module('apruve.sellers.payment').controller('PaymentController', [
  '$scope', 'payment_data', '$state', '$stateParams', 'Payment',
  function($scope, payment_data, $state, $stateParams, Payment) {
    $scope.payment = payment_data;

    $scope.capture_modal_template =  () => require('../../../../templates/sellers/payments/capture_payment_modal.html');

    $scope.capture_payment = () =>
      Payment.capture(Object.assign({ merchant_id: $stateParams.merchant_id }, $scope.payment), () => $state.reload())
    ;

    return $scope.navigate_to_invoice = invoice => $state.go('sellers.invoice', {invoice_uuid: invoice.uuid, merchant_id: $state.params.merchant_id});
  }
]);