import 'jquery-ui/ui/widgets/datepicker'

$(function () {
  $( "#admin_query_start_date" ).datepicker();
  $( "#admin_query_end_date" ).datepicker();
  $("#query-selector-html").on("click", function () {
    var old_action = $( "#admin_query_form" ).attr('action');

    var new_action = old_action.replace(/^([^?]*)\.csv/, function(match, $1, offset, original) { return $1; });

    $( "#admin_query_form" ).attr('action', new_action);
    $( "#admin_query_form" ).submit();
  });
  $("#query-selector-csv").on("click", function () {
    var old_action = $( "#admin_query_form" ).attr('action');

    if(old_action.includes('.csv')){
      var new_action = old_action;
    } else {
      var new_action = old_action + '.csv';
    }

    $( "#admin_query_form" ).attr('action', new_action);
    $( "#admin_query_form" ).submit();
  });

  var dateFormat = "mm/dd/yy",
      start_date = $("#admin_query_start_date")
          .datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            numberOfMonths: 3
          })
          .on("change", function () {
            start_date.datepicker("option", "minDate", getDate(this));
          }),
      end_date = $("#admin_query_end_date").datepicker({
        defaultDate: "+1w",
        changeMonth: true,
        numberOfMonths: 3
      })
          .on("change", function () {
            end_date.datepicker("option", "maxDate", getDate(this));
          });

  function getDate(element) {
    var date;
    try {
      date = $.datepicker.parseDate(dateFormat, element.value);
    } catch (error) {
      date = null;
    }

    return date;
  }
})
;