angular.module('apruve.common.tooltip')
.controller 'TooltipController', [
  '$document'
  ($document) ->
    @toggleState = false

    @toggle = ->
      @toggleState = !@toggleState

    return
]
