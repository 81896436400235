angular.module('apruve.common.payment_modal')
  .controller('PaymentModalBankAccountController', ['payable_model', '$state', 'images', PaymentModalBankAccountController]);

function PaymentModalBankAccountController(payable_model, $state, images) {
  let vm = this;
  vm.payable_model = payable_model;
  vm.payment_action = $state.params.payment_action;
  vm.payment = vm.payment_action.payment;
  vm.images = images;
  vm.includes_credit_memos = (vm.payment_action.credit_memo_amount.intValue ?? 0 > 0);
  vm.amount_header_text = vm.includes_credit_memos ? 'Remaining Payment Amount' : 'Amount Due'
}
