angular.module('apruve.users.credit_memo').controller('CreditMemoInvoicesController', ['credit_memo_data', '$scope', '$state', '$stateParams', CreditMemoInvoicesController]);
function CreditMemoInvoicesController(credit_memo_data, $scope, $state, $stateParams) {
  this.url_params = $stateParams;
  this.url_params.page = parseInt(this.url_params.page);
  this.new_params = angular.copy(this.url_params);
  this.credit_memo = credit_memo_data;

  this.url = function () {
    return '/credit_memos/' + this.credit_memo.uuid + '/invoices.json';
  };

  this.navigate_to_invoice = function(invoice_payment) {
    $state.go('invoice', {invoice_uuid: invoice_payment.invoice_uuid});
  };

  this.load_new_page = function(new_value, old_value) {
    $state.go('.', new_value, { notify: false });
  };

  $scope.$watch('ctrl.new_params', this.load_new_page, true);
}
