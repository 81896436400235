import Rails from '@rails/ujs'

Rails.confirm = (message, js_element) ->
  # Pull additional data attributes (if applicable) from the link and apply them to the primary action button
  element = $(js_element)
  btnClass = element.data('buttonclass')
  btnText = element.data('buttontext')
  btnFeedback = element.data('buttonfeedback')
  # If there's no message, there's no data-confirm attribute,
  # which means there's nothing to confirm
  return true unless message
  # Clone the clicked element (probably a delete link) so we can use it in the dialog box.
  $link = element.clone()
# We don't necessarily want the same styling as the original link/button.
    .removeAttr('class')
# We don't want to pop up another confirmation (recursion)
    .removeAttr('data-confirm')
# We want a button
    .addClass('btn').addClass("#{btnClass || ''}")
# We want it to sound confirmy
    .html("#{btnText || 'Confirm'}")

  # Create the modal box with the message
  modal_html = """
               <div class="modal fade v2" id="confirmModal" role="dialog" tabindex="-1" aria-hidden=true aria-labelledby="confirmModal">
                 <div class="modal-dialog" role="document">
                   <div class="modal-content" id="modal-content">
                     <div class="modal-header">
                     <h3 class="modal-title">Are you sure?</h3>
                     </div>
                     <div class="modal-body">
                       <p>#{message}</p>
                     </div>
                     <div class="modal-footer actionButtons">
                       <a data-dismiss="modal" class="btn btn-link" id="modal-cancel">Cancel</a>
                     </div>
                   </div>
                 </div>
               </div>
               """
  # The button got clicked to open this modal, which messed up the url in the browser because there was an href attribute
  # Do a sanity check to ensure it hasn't changed again, then go back to the previous url to fix things.
  if window.location.href == $link[0].href
    window.history.back(1)
  # Prevent multiple modals
  if $('#confirmModal').length > 0
#    $('#confirmModal').html(modal_html)
    $('.modal-body').html("<p>#{message}</p>")
    $('#confirmModal').find('.btn-danger').remove()
    $('#confirmModal').find('[data-buttontext]').remove() # Remove the old one
    $('#confirmModal').find('.modal-footer').prepend($link)
    $('#confirmModal').modal('show')
  else
    $modal_html = $(modal_html)
    # Add the new button to the modal box
    $modal_html.find('.modal-footer').prepend($link)
    # Pop it up
    $modal_html.modal()
  #    $('.wrap').prepend($modal_html)
  # Add loading animation

  count = 0
  userFeedback = ->
    dots = new Array(count%6).join('.')
    count++
    $($link).text("#{btnFeedback}"+'....')
    btnWidth = $($link).css('width')
    $($link).css('width', btnWidth).addClass('main__btn-animated')
    $($link).text("#{btnFeedback}" + dots)
    $($link).attr('disabled', true)
  $($link).click ->
    if(typeof element.data('buttonfeedback') != 'undefined')
      setInterval(userFeedback, 175)
  # Prevent the original link from working
  return false