angular.module('apruve.common.helpers').directive 'simpleFormat', ->
  restrict: 'E'
  scope: true
  template: """
            <p ng-repeat='lines in paragraphs track by $index'>
              <span ng-repeat='line in lines track by $index'>
                <br ng-hide='$first' />
                {{ line }}
              </span>
            </p>
            """
  link: (scope, element, attributes) ->
    scope.$watch attributes.text, (text) ->
      scope.paragraphs = []
      if text?
        # remove weird newlines
        text.replace /\r\n|\r/g, '\n'

        # split lines and paragraphs
        paragraphs = text.split('\n\n')
        scope.paragraphs = _.map paragraphs, (lines) ->
          lines.split('\n')