angular.module('apruve.common.bank_account').directive 'verifyBankAccountForm', ->
  require: 'ngModel'
  templateUrl: require('../../../../templates/common/bank_accounts/verify_bank_account_form.html')
  controller: ['$scope', 'environment', ($scope, environment) ->

    $scope.environment = environment
    $scope.reset_verify_form = () ->
      $scope.display_errors = false
      if $scope.verify_bank_account_form
        $scope.verify_bank_account_form.$setPristine(true)
        $scope.verify_bank_account_form.$setUntouched(true)

    $scope.show_error_class = (elem, form) ->
      submitted = if form then form.$submitted else false
      !elem.$valid && (elem.$touched || elem.$dirty || submitted)

    $scope.$on 'verify_bank_account.load', (event, account_id, account_strategy) ->
      $scope.bankAccount.uuid = account_id
      $scope.bankAccount.strategy = account_strategy

    $scope.submit_verify_form = () ->
      $scope.submitting = true
      if $scope.verify_bank_account_form.$invalid
        $scope.display_errors = true
        $scope.result = 'error'
        return
      $scope.bankAccount.$verify null, (value, responseHeaders) ->
        $scope.result = 'success'
        $scope.bankAccount.meta.flash = null if $scope.bankAccount.meta?.flash?
        $scope.reset_verify_form()
        $scope.$emit('bank_account.verify_done')
      , (httpResponse) ->
        $scope.display_errors = true
        $scope.bankAccount.meta = httpResponse.data.meta
        $scope.result = 'error'
        
    $scope.cancel_verify = () ->
      $scope.$emit('bank_account.done')
    
    $scope.$on 'bank_account.reset', $scope.reset_verify_form

    $scope.verify_options =
      buttonDefaultText: 'Verify Bank Account'
      buttonSubmittingText: 'Verifying...'
      buttonSuccessText: 'Verified'
      buttonDefaultClass: 'button--inline'
      buttonSubmittingClass: 'button--inline'
      buttonSuccessClass: 'button--inline'
      buttonErrorClass: 'button--inline'
  ]