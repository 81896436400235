
function setupCreditorCsvForm(selector) {
  return () => {
    $(selector).each(function() {
      const root = $(this);
      root.find('#download_csv_submit').on("click", () => {
        const form = root.find('form');
        let old_action = form.attr('action');
        let new_action = old_action;
        if (!old_action.includes('.csv')) {
          new_action += '.csv';
        }
        form.attr('action', new_action);
        form.submit();
      });
    });

  }
}

$(setupCreditorCsvForm(".creditor-csv"));