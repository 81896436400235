angular.module('apruve.users.corporate_account').controller 'AccountAddPaymentMethodController', [
  '$state', '$window', 'payment_methods', 'corporate_account_data', '$scope', '$uibModalInstance', '$rootScope'
  ($state, $window, payment_methods, corporate_account_data, $scope, $uibModalInstance, $rootScope) ->
    $scope.payment_methods = payment_methods
    $scope.corporate_account = corporate_account_data
    $scope.isSubmitting = null
    $scope.newAccount = true

    $scope.save_payment = ->
      $scope.animated_button.submitting = true
      if $scope.newAccount
        $uibModalInstance.dismiss 'cancel'
        $rootScope.$broadcast 'start.create'
      else
        payment_method = $scope.corporate_account.payment_method
        $scope.isSubmitting = true
        $scope.corporate_account.$add_payment_method (corp_acct) =>
          $scope.animated_button.result = 'success'
          $uibModalInstance.close $scope.corporate_account.payment_method
          $rootScope.$broadcast 'setPayment.dismiss'
        , (corp_acct) =>
          $scope.corporate_account.meta = corp_acct.data.meta
          $scope.animated_button.result = 'error'
          console.error('Error while attaching payment_method to corporate_account: ', angular.toJson(ca.data, true))

    $scope.method_changed = (method) ->
      if method
        $scope.newAccount = false
        $scope.payment_options.buttonDefaultText = 'Set'
      else
        $scope.newAccount = true
        $scope.payment_options.buttonDefaultText = 'Add a new Account'
      $scope.$broadcast('apruve.ng-bs-animated-button.options_updated')

    $scope.close_modal = ->
      $rootScope.$broadcast 'setPayment.dismiss'
      $uibModalInstance.dismiss 'cancel'

    $scope.payment_options =
      buttonDefaultText: 'Add a new Account'
      buttonSubmittingText: 'Saving...'
      buttonSuccessText: 'Saved!'
      buttonErrorClass: ''
      buttonSubmittingClass: ''
      buttonSuccessClass: ''
      buttonDefaultClass: ''

    return
]