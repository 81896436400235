angular.module('apruve.sellers.billing_invoices').controller 'BillingPaymentsController', [
  '$state', '$stateParams', 'billing_invoice_data',
  ($state, $stateParams, billing_invoice_data) ->
    @url_params = $stateParams
    @url_params.page = parseInt @url_params.page
    @new_params = angular.copy(@url_params)
    @billing_invoice = billing_invoice_data
    # @billing_payments gets loaded later by the paginator in the haml, so it might be undefined, so we want to lazy-resolve it in a function like this
    @any_payment_has_refunds = () => (@billing_payments || []).some((p) -> p.has_refunds)
    @url = ->
      "/sellers/#{$stateParams.merchant_id}/billing_invoices/#{@billing_invoice.uuid}/payments.json"

    return
]