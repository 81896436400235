angular.module('apruve.users.corporate_account').directive('financingLoanProgressBar', [() => {
  return {
    restrict: 'E',
    templateUrl: require('../../../../templates/accounts/loan_progress_bar.html'),
    scope: {
      corporateAccount: '='
    },
    link: (scope, element) => {
      scope.loan_type = 'financing';
      scope.has_loan_of_type = scope.corporateAccount.financing_loan != null; // Some people have credit
      if(!scope.has_loan_of_type) return;
      scope.loan_percentage = 100 - scope.corporateAccount.financing_loan.percentage_spend;
      scope.progress_display = scope.corporateAccount.financing_loan.available_display;
      scope.progress_label = 'paid';
      scope.remainder_display = scope.corporateAccount.financing_loan.balance_display;
      scope.remainder_label = 'remaining';

      scope.show_increase_request_link =  scope.corporateAccount.user_can_update &&
          scope.corporateAccount.has_credit_limit &&
          scope.corporateAccount.has_creditor_term &&
          !scope.corporateAccount.credit_limit_change_pending &&
          !scope.corporateAccount.self_financed;
    }
  }
}]);
