angular.module('apruve.sellers.financing_applications').controller 'FinancingApplicationsController', [
  '$state', 'SellersFinancingApplication',
  ($state, SellersFinancingApplication) ->
    @submitting = false
    @result = null
    @current_merchant_id = $state.params.merchant_id
    @credit_application = new SellersFinancingApplication(merchant_id: $state.params.merchant_id)
    @create_options =
      buttonDefaultText: 'Send Application'
      buttonSubmittingText: 'Creating...'
      buttonSuccessText: 'Sent!'
      buttonDefaultClass: 'actionBar-btn-primary'
      buttonSubmittingClass: 'actionBar-btn-primary'
      buttonSuccessClass: 'actionBar-btn-primary'
      buttonErrorClass: 'actionBar-btn-danger'

    @create_application = =>
      return unless @credit_application_form.$valid
      @submitting = true
      @credit_application.$save (data) =>
        @result = 'success'
        $state.go('sellers.corporate_accounts', { status: 'in_progress' })
      , (error) =>
        @result = 'error'
        @submitting = false
        console.error("Error while creating account")

    @show_error_class = (elem, form) ->
      submitted = if form then form.$submitted else false
      !elem.$valid && (elem.$touched || elem.$dirty || submitted)

    return
]