import Highcharts  from 'highcharts/highstock';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);

angular.module('apruve.admin.performance', []).controller('AdminPerformanceController', [
  'Performance', 'merchants', 'dashboard_chart_names', 'dashboard_chart', '$scope', 'currentUserService',
  function(Performance, merchants, dashboard_chart_names, dashboard_chart, $scope, currentUserService) {
    currentUserService.user.$promise.then(() => {
      this.merchants = merchants.map(merchant =>
        ({
          name: merchant.name,
          id: merchant.id
        })
      );
      this.selectedMerchants = [];
      this.disableMerchantSelect = true;
      this.disableComparisonSelect = false;

      this.currentChartPredictive = false;

      // so angular <select> can have access to index (safely)
      this.dashboardChartNames = dashboard_chart_names.map((name, index) =>
        ({
          name,
          index
        })
      );
      this.selectedChart = 0;

      this.comparePeriods = [{
        name: 'Previous 30 Days',
        value: 'previous'
      }, {
        name: 'Last year',
        value: 'last_year'
      }, {
        name: 'None',
        value: 'none'
      }];
      this.comparePeriod = this.comparePeriods[0];

      $scope.chartControl = {};

      Highcharts.setOptions({
        lang: {
          thousandsSep: ','
        }
      });

      $scope.chartConfig = {
        chart: {
          backgroundColor: 'transparent'
        },

        credits: {
          enabled: false
        },

        navigator: {
          enabled: false
        },

        title: {
          text: dashboard_chart.name ? dashboard_chart.name : ''
        },

        lang: {
          noData: 'No data available for selected date range'
        },

        exporting: {
          buttons: {
            contextButton: {
              menuItems: Highcharts.getOptions().exporting.buttons.contextButton.menuItems.filter(item => item.textKey !== 'viewData')
            }
          }
        },

        plotOptions: {
          column: {
            negativeColor: '#ff8080',
            threshold: 0
          }
        },

        rangeSelector: {
          buttons: [{
            type: 'day',
            count: 30,
            text: '30d'
          }, {
            type: 'day',
            count:  90,
            text: '90d'
          }, {
            type: 'month',
            count: 6,
            text: '6m'
          }, {
            type: 'month',
            count: 12,
            text: '1yr'
          }, {
            type: 'all',
            text: 'all'
          }],
          selected: 2,
          inputEnabled: false,
          allButtonsEnabled: true
        },

        yAxis: [{
          labels: {
            align: 'left',
            x: -3
          },
          title: {
            text: dashboard_chart.y_axis_name ? dashboard_chart.y_axis_name : ''
          },
          height: '55%'
        }],

        xAxis: [{
          events: {
            // triggers when one of the rangeSelector buttons is selected
            setExtremes: event => {
              if (typeof(event.rangeSelectorButton) !== 'undefined') {
                const [type, count] = Array.from([event.rangeSelectorButton.type, event.rangeSelectorButton.count]);
                this.comparePeriods =
                  type === 'month' ?
                    [{
                      name: 'Last month',
                      value: 'previous'
                    }, {
                      name: "Last year",
                      value: 'last_year'
                    }]
                  : type === 'all' ?
                    [{name: 'Last year', value: 'last_year'}]
                  :
                    [];
                this.comparePeriods.push({name: 'None', value: 'none'});

                const oldComparePeriodValue = this.comparePeriod.value.slice(0);
                $scope.$apply(() => { // angular will not register this change in the watcher unless inside $apply here
                  return this.comparePeriod = this.comparePeriods[0];
              });

                if (this.comparePeriods[0].value === oldComparePeriodValue) {
                  return this.updateChart();
                }
              }
            }
          }
        }],
        tooltip: {
          backgroundColor: '#fff',
          borderRadius: 4,
          borderColor: '#ccc',
          borderWidth: 1,
          footerFormat: '</table>',
          headerFormat: '<small>{point.key}</small><table>',
          pointFormat: '<span style="color:{point.color}"><tr><td>{series.yAxis.axisTitle.textStr}: </td><td><b>{point.y}</b></td></tr></span>',
          shadow: false,
          shared: true,
          split: false,
          useHTML: true,
          valueDecimals: dashboard_chart.number_type === 'integer' ? 0 : 2,
          valuePrefix: dashboard_chart.number_type === 'money' ? '$' : '',
          xDateFormat: '%B %e, %Y'
        },
        series: [
          this.base_data_series(dashboard_chart.name, dashboard_chart.type, dashboard_chart.chart_data[0]),
          this.comparison_data_series(dashboard_chart.chart_data[1])
        ]
      };

      return $scope.chart_data = dashboard_chart;
    }); // to resolve resource promise in the view

    this.updateChart = () => {
      let chart = Highcharts.charts[0];
      if (chart.options.rangeSelector.selected == null) {
        chart.options.rangeSelector.selected = $scope.chartConfig.rangeSelector.selected;
      }
      const selectedRange = chart.options.rangeSelector.buttons[chart.options.rangeSelector.selected];
      const [type, count] = Array.from([selectedRange.type, selectedRange.count]);

      return Performance.DashboardCharts.get({
        'merchants[]': this.selectedMerchants.map(merchant => merchant.id),
        type,
        count,
        chart_index: this.selectedChart,
        compare_period: this.comparePeriod.value
      }).$promise.then(data => {
        let status, today, updated_config;
        if (data.name === "Credit Applications Pie Chart") {
          updated_config = {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            title: {
              text: data.name ? data.name : ''
            },
            lang: {
              noData: 'No data available for selected date range'
            },
            scrollbar: {
              enabled: false
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            yAxis: [{
              title: {
                text: data.y_axis_name ? data.y_axis_name : ''
              }
            }]
          };
        } else {
          updated_config = {
            title: {
              text: data.name ? data.name : ''
            },
            scrollbar: {
              enabled: false
            },
            tooltip: {
              valueDecimals: data.number_type === 'integer' ? 0 : 2,
              valuePrefix: data.number_type === 'money' ? '$' : '',
              xDateFormat: selectedRange.type !== 'day' ? '%B %Y' : '%B %e, %Y'
            },
            yAxis: [{
              title: {
                text: data.y_axis_name ? data.y_axis_name : ''
              }
            }]
          };
        }

        updated_config.legend = { enabled: (['Credit Applications', 'Total Merchants'].includes(data.name)) };

        this.disableComparisonSelect = ((data.name === 'Credit Applications Pie Chart') || (data.name === 'Credit Applications') || (data.name === 'Total Merchants'));
        this.disableMerchantSelect = data.name === 'Total Merchants';
        chart = Highcharts.charts[0];

        // manually reset extremes with new data
        if (data.name !== 'Credit Applications Pie Chart') {
          let dates = undefined;
          if (data.name === 'Credit Applications') {
            dates = data.chart_data['approved'];
          } else if (data.name === 'Number of Orders Placed') {
            dates = data.chart_data['Online'];
          } else if (data.name === 'Total Merchants') {
            dates = data.chart_data['live'];
          } else if (data.chart_data[0]) {
            dates = data.chart_data[0];
          }
          if (dates != null) {
            const sorted_dates = dates.map(d => d[0]).sort((left, right) => left - right);
            today = new Date();
            sorted_dates.push(today.getTime());
            chart.xAxis[0].max = today.getTime();
            chart.xAxis[0].setExtremes(sorted_dates[0], sorted_dates[sorted_dates.length - 1]);
          }
        }

        $scope.chartControl.updateChart(updated_config);

        while (chart.yAxis.length > 1) { $scope.chartControl.removeYAxis(1); }
        while (chart.series.length > 0) { $scope.chartControl.removeSeries(0); }

        // Too many cases to be too optimizey here. Since it only calls this once per update, can do extra work.
        // Strip down to same starting point every time and add what we need based on state
        if (data.name === 'Credit Applications Pie Chart') {
          $scope.chartControl.addSeries(this.pie_data_series(data.chart_data));
          chart.redraw();
        } else if ((data.name === 'Number of Orders Placed') || (data.name === 'Credit Applications')) {
          for (status of Array.from(Object.keys(data.chart_data))) {
            $scope.chartControl.addSeries(this.multi_bar_data_series(status, data.chart_data[status]));
          }
        } else if (data.name === "Total Merchants") {
          for (status of Array.from(Object.keys(data.chart_data))) {
            if (status === 'live') {
              $scope.chartControl.addSeries(this.multi_bar_data_series(status, data.chart_data[status]));
            } else {
              $scope.chartControl.addSeries(this.bar_label_data_series(status, data.chart_data[status]));
            }
          }
        } else {
          $scope.chartControl.addSeries(this.base_data_series(data.name, type, data.chart_data[0]));

          if (this.comparePeriod.value !== 'none') {
            if (type === 'day') {
              $scope.chartControl.addSeries(this.comparison_data_series(data.chart_data[1], type));
            } else {
              $scope.chartControl.addYAxis(this.percent_change_axis(type, data.summation));
              $scope.chartControl.addSeries(this.percent_change_data_series(data.chart_data[1], type, data.summation));
            }
          }
        }

        if ((data.name === 'Number of Orders Placed') && data.start_date) {
          chart.xAxis[0].setExtremes(Date.parse(data.start_date), today.getTime());
        }

        return this.currentChartPredictive = ['Total Fee Revenue', 'Total SaaS Revenue', 'Total Revenue (non-Adjustment)', 'Total Revenue'].includes(data.name);
    });
    };

    this.percent_change_axis = (type, summation) =>
      ({
        opposite: true,
        labels: {
          align: 'left',
          x: -3
        },
        title: {
          text: summation ? `New this ${type === 'all' ? 'year' : type}` : 'Percent Change'
        },
        top: '60%',
        height: '40%',
        offset: 0
      })
    ;

    this.percent_change_data_series = function(data, type, summation) {
      if (data == null) { data = [[], [], []]; } // highcharts does not like when data is undefined
      const dateFormatString = type === 'month' ? '%b %Y' : '%b %e, %y';
      const pointFormatString =
        summation ?
          `<span><tr><td>New this ${type === 'all' ? 'year' : type}: </td><td style='color:{point.color}'><b>{point.y}</b></td></tr></span>`
        :
          '<span><tr><td>Compared to {point.compareTime}: </td><td style="color:{point.color}"><b>{point.y}</b></td></tr></span>';

      const config_data = {
        yAxis: 1,
        type: 'column',
        name: 'Percent Change',
        data: data.map(d =>
          ({
            y: d[1],
            x: d[0],
            compareTime: Highcharts.dateFormat(dateFormatString, d[2])
          })
        ),
        color: '#5B921A', // fill color
        lineColor: '#5B921A',
        lineWidth: 3,
        marker: {
          fillColor: '#5B921A',
          radius: '3'
        },
        showInLegend: false,
        tooltip: {
          xDateFormat: dateFormatString,
          pointFormat: pointFormatString
        },
        states: {
          hover: {
            enabled: false
          }
        }
      };
      if (!summation) { config_data['tooltip']['valuePrefix'] = ''; }
      if (!summation) { config_data['tooltip']['valueSuffix'] = '%'; }
      return config_data;
    };

    this.comparison_data_series = function(data, type) {
      if (data == null) { data = [[0], [0], [0]]; } // highcharts does not like when data is undefined
      const dateFormatString = type === 'month' ? '%b %Y' : '%b %e, %y';
      return {
        type: 'line',
        name: 'Comparison data',
        data: data.map(d =>
          ({
            y: d[1],
            x: d[0],
            compareTime: Highcharts.dateFormat(dateFormatString, d[2])
          })
        ),
        color: '#96C7DB',
        lineColor: '#96C7DB',
        lineWidth: 3,
        marker: {
          fillColor: '#96C7DB',
          radius: '3'
        },
        showInLegend: false,
        tooltip: {
          xDateFormat: dateFormatString,
          pointFormat: '<span><tr><td>Compared to {point.compareTime}: </td><td style="color:{point.color}"><b>{point.y}</b></td></tr></span>'
        },
        states: {
          hover: {
            enabled: false
          }
        }
      };
    };

    this.base_data_series = (name, type, data) =>
      ({
        name: name || '',
        yAxis: 0,
        type: type === 'day' ? 'line' : 'column',
        data: data || [],
        color: '#116283', // fill color
        lineColor: '#116283',
        lineWidth: 3,
        marker: {
          fillColor: '#116283',
          radius: '3'
        },
        showInLegend: false,
        states: {
          hover: {
            enabled: false
          }
        }
      })
    ;

    this.bar_label_data_series = (name, data) =>
      ({
        name: name || '',
        yAxis: 0,
        type: 'column',
        data: data.map(d =>
          ({
            x: d[0],
            y: d[1][0],
            z: d[1][1].join(', ')
          })
        ),
        lineWidth: 3,
        marker: {
          radius: '3'
        },
        showInLegend: true,
        states: {
          hover: {
            enabled: false
          }
        },
        tooltip: {
          pointFormat: '<span><tr><td style="color:{point.series.color}">{series.name}: </td><td style="padding-left:15px"><b>{point.y}</b></td><td style="padding-left:15px"<b>{point.z}</b></td></tr></span>'
        }
      })
    ;

    this.multi_bar_data_series = (name, data) =>
      ({
        name: name || '',
        yAxis: 0,
        type: 'column',
        data: data || [],
        lineWidth: 3,
        marker: {
          radius: '3'
        },
        showInLegend: true,
        states: {
          hover: {
            enabled: false
          }
        },
        tooltip: {
          pointFormat: '<span><tr><td style="color:{point.series.color}">{series.name}: </td><td style="padding-left:15px"><b>{point.y}</b></td></tr></span>'
        }
      })
    ;

    this.pie_data_series = function(data) {
      let pie_data = [];
      if (data['in_progress'] || data['approved'] || data['declined'] || data['sent']) {
        const submissions = data['sent'] ? data['sent'] : 0.0;
        const pends = data['pending'] ? data['pending'] : 0.0;
        const attempts = data['in_progress'] ? data['in_progress'] : 0.0;
        const approvals = data['approved'] ? data['approved'] : 0.0;
        const rejections = data['declined'] ? data['declined'] : 0.0;
        const expirations = data['expired'] ? data['expired'] : 0.0;
        pie_data = [
          {
            name: `Approved (${approvals})`,
            y: approvals
          }, {
            name: `Declined (${rejections})`,
            y: rejections
          }, {
            name: `Submitted (${submissions + pends})`,
            y: submissions + pends
          }, {
            name: `Started (${attempts})`,
            y: attempts
          }, {
            name: `Expired (${expirations})`,
            y: expirations
          }
        ];
      }
      return {
        type: 'pie',
        name: data.name ? data.name : '',
        data: pie_data
      };
    };

    
  } // so that this or @ evaluates to the controller, not the last defined function here
]);
