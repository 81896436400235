angular.module('apruve.users.invoice').controller("InvoicesController", [
  '$scope', '$state', '$stateParams', 'statusIconsService',
  function($scope, $state, $stateParams, statusIconsService) {
    var _this = this;
    this.statusIconsService = statusIconsService;
    this.new_params = angular.copy($stateParams);
    this.new_params.page = parseInt(this.new_params.page);
    this.url_params = angular.copy(this.new_params);
    this.search_field = this.url_params.search;
    this.url = "/invoices.json";
    this.csv_url = "/invoices.csv";

    this.navigate_to_invoice = function(invoice) {
      return $state.go('invoice', {invoice_uuid: invoice.uuid});
    };

    this.search = function() {
      _this.new_params.page = 0;
      return _this.new_params.search = _this.search_field;
    };

    this.reset_search = function() {
      _this.search_field = '';
      return _this.search();
    };

    this.resort = function(clicked_on_col) {
      if (this.new_params.sort_column !== clicked_on_col) {
        this.new_params.sort_column = clicked_on_col;
        return this.new_params.sort_order = 'desc';
      } else {
        if (this.new_params.sort_order === 'desc') {
          return this.new_params.sort_order = 'asc';
        } else {
          return this.new_params.sort_order = 'desc';
        }
      }
    };

    this.sort_classes_for = function(column) {
      if (column === this.new_params.sort_column) {
        return ['apruve-icon-angle', this.new_params.sort_order];
      } else {
        return [];
      }
    };

    this.row_classes_for = function(invoice) {
      var classes = [];
      if (['closed', 'canceled'].includes(invoice.status)) {
        classes.push('list-item-done');
      }
      return classes;
    };

    this.is_overdue = function(invoice) {
      if (invoice.status_display === 'overdue') {
        return true;
      }
      return false;
    };

    this.is_due = function(invoice) {
      if (invoice.status_display === 'due soon') {
        return true;
      }
      return false;
    };

    this.is_waiting = function(invoice) {
      return (invoice.needs_payment === false) && ((invoice.status === 'open') || (invoice.status === 'pending'));
    };

    this.load_new_page = function(new_value, old_value) {
      if ((new_value == null) || (old_value == null)) { return true; }
      if (new_value === old_value) { return true; }
      $state.go('.', new_value, {notify: false});
      if (new_value.page !== old_value.page) { return true; }
      return _this.url_params = angular.copy(new_value);
    };

    $scope.$watch("invoicesCtrl.new_params", this.load_new_page, true);
  }
]);