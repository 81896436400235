angular.module('apruve.users.corporate_account').controller "CorporateAccountsController", [
  '$scope', '$state', 'currentUserService', 'corporate_accounts_data',
  ($scope, $state, currentUserService, corporate_accounts_data) ->
    $scope.corporate_accounts = corporate_accounts_data

    $scope.url_params or= {}
    $scope.current_user = currentUserService.user

    $scope.navigate_to = (corporate_account) ->
      $state.go('account', corporate_account_id: corporate_account.uuid)

    $scope.statement_percentage = (corporate_account) ->
      (corporate_account.credit_limit.balance / corporate_account.credit_limit.amount) * 100

    $scope.verified_account = (corporate_account) ->
      corporate_account.verified_payment_method

    $scope.default_account = (corporate_account) ->
      corporate_account.default_payment_method
]
