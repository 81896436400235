/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('apruve.sellers.purchase_order').controller("PurchaseOrdersController", [
  '$scope', '$state', '$stateParams', '$window', 'statusIconsService',
  function($scope, $state, $stateParams, $window, statusIconsService) {
    this.statusIconsService = statusIconsService;
    this.url_params = $stateParams;
    this.url_params.page = parseInt(this.url_params.page);
    this.new_params = angular.copy(this.url_params);
    this.search_field = this.new_params.search;
    this.url = `/sellers/${$stateParams.merchant_id}/purchase_orders.json`;
    this.scoped_account = !!$stateParams.scoped_account;
    this.customer = $stateParams.customer;
    this.start_date = this.new_params.start_date ? new Date(this.new_params.start_date) : null;
    this.end_date = this.new_params.end_date ? new Date(this.new_params.end_date) : null;

    this.filter_by_customer = (orders) => {
      return orders.filter(order => order.customer_account_id == this.customer)
    };

    this.search = () => {
      this.new_params.page = 0;
      return this.new_params.search = this.search_field;
    };

    this.reset_search = () => {
      this.search_field = '';
      return this.search();
    };

    this.navigate_to = function(purchase_order) {
      $state.go('sellers.purchase_order', {purchase_order_id: purchase_order.token, merchant_id: $stateParams.merchant_id});
    };

    this.resort = function(clicked_on_col) {
      if (this.new_params.sort_column !== clicked_on_col) {
        this.new_params.sort_column = clicked_on_col;
        return this.new_params.sort_order = 'asc';
      } else {
        if (this.new_params.sort_order === 'desc') {
          return this.new_params.sort_order = 'asc';
        } else {
          return this.new_params.sort_order = 'desc';
        }
      }
    };

    this.sort_classes_for = function(column) {
      if (column === this.new_params.sort_column) {
        return ['apruve-icon-angle', this.new_params.sort_order];
      } else {
        return [];
      }
    };

    this.row_classes_for = function(purchase_order) {
      const classes = [];
      if (purchase_order.completed) {
        classes.push('list-item-done');
      }
      return classes;
    };

    this.load_new_page = (new_value, old_value) => {
      if ((new_value == null) || (old_value == null)) { return true; }
      if (new_value === old_value) { return true; }
      $state.go('.', new_value, {notify: false});
      if (new_value.page !== old_value.page) { return true; }
      this.url_params = angular.copy(new_value);
      return (this.csv_url = `/sellers/${$state.params.merchant_id}/purchase_orders.csv?status=${this.url_params.status}`);
    };

    this.searchByDate = () => {
      this.new_params = {
        ...this.new_params,
        start_date: this.start_date || new Date(2012, 1, 1 ),
        end_date: this.end_date || new Date(),
      };

      return $state.go(".", this.new_params, { reload: true });
    };

    this.downloadCsv = () => {
      const startDateParam = this.new_params.start_date
          ? `&start_date=${this.new_params.start_date}`
          : "";
      const endDateParam = this.new_params.end_date
          ? `&end_date=${this.new_params.end_date}`
          : "";

      $window.open(
          `/sellers/${$state.params.merchant_id}/purchase_orders.csv?${startDateParam}${endDateParam}`
      );
    };

    $scope.$watch("posCtrl.new_params", this.load_new_page, true);
  }
]);