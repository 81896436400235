var IBAN = require('iban');
'use strict';
(function() {
  angular.module('apruve.common.angular_iban_validation', [])
      .directive('iban', IBanValidation);

  function IBanValidation() {
    return {
      restrict: 'A',
      require: 'ngModel',
      compile: function(element, attributes) {
       attributes.$set('xAutocompletetype', 'iban');

        return function(scope, elm, attrs, ngModel) {
          ngModel.$validators.iban = function(value) {
            var valid = IBAN.isValid(value);
            return valid
          };
        };
      },
    };
  }
})();