angular.module('apruve.admin.app', [
  'ui.router',
  'ui.select',
  'ngAnimate',
  'ngSanitize',
  'ngResource',
  'pascalprecht.translate',
  'apruve.common',
  'apruve.admin.app.routes',
  'apruve.admin.payment',
  'apruve.admin.performance',
  'apruve.admin.merchants',
  'apruve.ng-bs-animated-button',
  'ui.bootstrap',
  'bgf.paginateAnything'
]);
