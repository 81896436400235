import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);

angular.module('apruve.admin.merchants', []).controller('AdminMerchantsController', [
  'Merchants', '$stateParams', '$scope', '$parse', 'currentUserService', function(Merchants, $stateParams, $scope, $parse, currentUserService) {
    currentUserService.user.$promise.then(() => {

      Highcharts.setOptions({
        lang: {
          thousandsSep: ','
        }
      });

      Merchants.PerformanceData.get({
        merchant_id: $stateParams.merchant_id,
        data_set: 'projections'
      }).$promise.then(data => {
        return $scope['projections'] = data;
      });
        
      return (() => {
        const result = [];
        for (let data_set of ['revenue', 'corporate_accounts', 'average_order_size', 'order_frequency', 'invoice_volume', 'credit_utilization', 'merchant_health']) {
          result.push(load_data_set(data_set, !['credit_utilization', 'merchant_health'].includes(data_set)));
        }
        return result;
      })();
    });

    const options_from_query = (data_set_scope, query) => {
      return config_from_query(data_set_scope, query).then(config => {
        return generate_options(config);
      });
    };

    var config_from_query = (data_set_scope, query) => {
      const data = Merchants.PerformanceData.get(query).$promise;
      data_set_scope.chart_data = data;
      return data.then(data => {
        for (let value = 0; value < data.query.length; value++) {
          const key = data.query[value];
          query[key] = value;
        }
        data_set_scope.previous_query = query;
        return data.config;
      });
    };

    var load_data_set = (data_set, has_percentage_toggle) => {
      let data_set_scope;
      if (has_percentage_toggle == null) { has_percentage_toggle = false; }
      const query = {
        merchant_id: $stateParams.merchant_id,
        data_set,
        end_date: new Date().getTime()
      };
      if (has_percentage_toggle) { query['as_percentage'] = true; }
      $scope[data_set] = (data_set_scope =
        {chartControl: {}});
      return options_from_query(data_set_scope, query).then(options => {
        options.rangeSelector = {
          buttons: [{
            type: 'month',
            count: 6,
            text: '6m'
          }, {
            type: 'year',
            count: 1,
            text: '1yr'
          }, {
            type: 'all',
            text: 'all'
          }],
          selected: 2,
          inputEnabled: false,
          allButtonsEnabled: true
        };
        if (has_percentage_toggle) {
          options.chart.events =
            {click: as_percentage($scope[data_set])};
        }
        for (let button of Array.from(options.rangeSelector.buttons)) {
          button.events =
            {click: with_data_range($scope[data_set])};
        }
        options.xAxis = {
          events: {
            setExtremes: with_extremes($scope[data_set])
          }
        };
        return data_set_scope.chartOptions = options;
      });
    };

    var as_percentage = data_set_scope => { return event => {
      const query = data_set_scope.previous_query;
      query.as_percentage ^= true;
      options_from_query(data_set_scope, query).then(options => {
        data_set_scope.chartControl.updateChart(options);
        return data_set_scope.chartControl.redraw();
      });
      return false;
    }; };

    var with_data_range = data_set_scope => event => {
      return data_set_scope.chartControl.setSeriesData([[0, null], [new Date().getTime(), null]], 0);
    };

    var with_extremes = data_set_scope => { return event => {
      if (event.trigger === 'rangeSelectorButton') {
        const query = data_set_scope.previous_query;
        if (event.rangeSelectorButton.type === 'all') {
          delete query.start_date;
        } else {
          query.start_date = event.min;
        }
        query.end_date = event.max;
        config_from_query(data_set_scope, query).then(config => {
          data_set_scope.chartControl.setSeriesData(config.chart_data[0], 0);
          data_set_scope.chartControl.setXAxisExtremes(0, null, null);
          return data_set_scope.chartControl.redraw();
        });
        return false;
      }
      return true;
    }; };

    var generate_options = config => {
      return {
        chart: {
          backgroundColor: 'transparent'
        },

        credits: {
          enabled: false
        },

        navigator: {
          enabled: false
        },

        title: {
          text: config.name ? config.name : ''
        },

        lang: {
          noData: 'No data available for selected date range'
        },

        exporting: {
          buttons: {
            contextButton: {
              menuItems: Highcharts.getOptions().exporting.buttons.contextButton.menuItems.filter(item => item.textKey !== 'viewData')
            }
          }
        },

        plotOptions: {
          column: {
            negativeColor: '#ff8080',
            threshold: 0
          }
        },

        yAxis: [{
          labels: {
            align: 'left',
            x: -3
          },
          title: {
            text: config.y_axis_name ? config.y_axis_name : ''
          }
        }],

        tooltip: {
          backgroundColor: '#fff',
          borderRadius: 4,
          borderColor: '#ccc',
          borderWidth: 1,
          footerFormat: '</table>',
          headerFormat: '<small>{point.key}</small><table>',
          pointFormat: '<span style="color:{point.color}"><tr><td>{series.yAxis.axisTitle.textStr}: </td><td><b>{point.y}</b></td></tr></span>',
          shadow: false,
          shared: true,
          split: false,
          useHTML: true,
          valueDecimals: config.number_type === 'integer' ? 0 : 2,
          valuePrefix: config.number_type === 'money' ? '$' : '',
          xDateFormat: '%B %Y'
        },
        series: [
          this.base_data_series(config.name, config.type, config.chart_data[0]),
        ]
      };
    };

    this.base_data_series = (name, type, data) =>
      ({
        name: name || '',
        yAxis: 0,
        type: type === 'day' ? 'line' : 'column',
        data: data || [],
        color: '#116283', // fill color
        lineColor: '#116283',
        lineWidth: 3,
        marker: {
          fillColor: '#116283',
          radius: '3'
        },
        showInLegend: false,
        states: {
          hover: {
            enabled: false
          }
        }
      })
    ;

    
  } // so that this or @ evaluates to the controller, not the last defined function here
]);
