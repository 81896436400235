import _ from 'lodash';

angular.module('apruve.common.payment_modal')
  .controller('PaymentMethodModalController', ['$state', 'paymentMethodWorkflowFactory', 'CorporateAccount', 'Payment', 'payable_model', 'corporate_account_data', 'money', 'paymentActionDecorator', PaymentMethodModalController]);

function PaymentMethodModalController($state, paymentMethodWorkflowFactory, CorporateAccount, Payment, payable_model, corporate_account_data, money, paymentActionDecorator) {
  this.payable_model = payable_model;
  this.corporate_account_data = corporate_account_data;
  const currency = money.currency(this.payable_model.currency_data);
  this.currency_symbol = this.payable_model.currency_data.symbol;
  this.zero_placeholder = currency(0).format({ symbol: '' });

  this.payment = new Payment({
    invoice_uuids: this.payable_model.invoice_ids,
    statement_id: this.payable_model.statement_id,
    tos_accepted: false
  });

  this.paymentMethodWorkflowFactory = paymentMethodWorkflowFactory;

  this.payment_method_priority_order = category => {
    const priority = {
      BankAccount: 0,
      PaperCheck: 1
    }[category];
    return typeof priority === 'undefined' ? 1000 : priority;
  };

  const credit_memo_payment_method = this.payable_model.valid_payment_methods.find(payment_method => ['CreditorCreditMemo', 'MerchantCreditMemo'].includes(payment_method.category));

  const show_credit_memos = this.payable_model.show_credit_memo_payments || credit_memo_payment_method;

  this.credit_memos = show_credit_memos ? this.payable_model.credit_memos ?? []: [] ;
  this.credit_memos_by_id = _(this.credit_memos).keyBy('uuid').value();

  this.update_credit_memos_selection = credit_memo_id => {
    this.credit_memos_by_id[credit_memo_id].applied_amount = null;
    this.update_credit_memos_applied_amount();
  };

  this.floor_memo_amounts = () => {
    if (this.payment.credit_memo_amount.value <= this.payable_model.amount_due) return;

    let remaining_sum = this.payable_model.amount_due;
    for ( const memo of this.credit_memos ) {
      if((memo.applied_amount ?? 0) <= remaining_sum) {
        remaining_sum -= memo.applied_amount ?? 0;
      } else {
        memo.applied_amount = remaining_sum || null;
        remaining_sum = 0;
      }
    }
  };

  this.update_credit_memos_applied_amount = () => {
    this.payment.credit_memo_amount = _(this.credit_memos).map(m => currency(m?.applied_amount)).reduce((acc, amount) => acc.add(amount), currency(0));
    this.floor_memo_amounts();
    this.payment.credit_memo_amount = _(this.credit_memos).map(m => currency(m?.applied_amount)).reduce((acc, amount) => acc.add(amount), currency(0));
    const remaining_amount = currency(this.payable_model.amount_due).subtract(currency(this.payment.credit_memo_amount));
    this.payment.payment_amount = remaining_amount.intValue > 0 ? remaining_amount : currency(0);
    const payment_method_selected = !['CreditorCreditMemo', 'MerchantCreditMemo', null].includes(this.payment.selected_category ?? null);
    this.payment.amount = (this.payment.credit_memo_amount.add(payment_method_selected ? this.payment.payment_amount : currency(0)));
  };

  this.payment_methods = this.payable_model.valid_payment_methods.sort((pm1, pm2) => {
    let cat1 = this.payment_method_priority_order(pm1.category);
    let cat2 = this.payment_method_priority_order(pm2.category);

    if (cat1 < cat2) return -1;
    if (cat1 > cat2) return 1;

    // Lastly use alphabetical
    return pm1.category < pm2.category ? -1 : 1;
  }).filter(payment_method => !['CreditorCreditMemo', 'MerchantCreditMemo'].includes(payment_method.category));

  this.missing_payment_methods_text = () => {
    if (this.credit_memos.length > 0) {
      return 'In order to configure additional payment options for this account, please contact';
    } else {
      return 'Payment options are not configured yet for this account. Please contact';
    }
  };

  // If they don't have a bank account, show the option to add one if they have that configured
  if (this.payable_model.allowed_payment_types.indexOf('ECheckAccount') !== -1 &&
      (this.payment_methods.length === 0 || this.payment_methods[0].category !== 'BankAccount') &&
      this.corporate_account_data.allows_bank_account) {
    this.payment_methods = [{
      category: 'NewBankAccount'
    }].concat(this.payment_methods);
  }

  this.payment_method_for_category = payment_method_category => {
    return this.payment_methods.find(pm => pm.category === payment_method_category);
  };

  if(this.credit_memos.length !== 0) {
    this.payment.payment_method = credit_memo_payment_method;
  } else {
    this.payment.payment_method = this.payment_methods?.values()?.next()?.value;
  }
  this.payment.selected_category = this.payment.payment_method?.category;
  this.update_credit_memos_applied_amount();

  this.update_payment_method = () => {
    this.update_credit_memos_applied_amount();
    this.payment.payment_method = this.payment_method_for_category(this.payment.selected_category);
  };

  this.workflow = payment_method_category => {
    return this.paymentMethodWorkflowFactory.workflow_for_payment_method_category(payment_method_category);
  };

  this.final_workflow = payment => {
    return this.paymentMethodWorkflowFactory.workflow_for_payment(payment);
  };

  this.card_template = payment_method_category => {
    return this.workflow(payment_method_category).card_template;
  };

  this.checkbox = () => {
    let workflow = this.workflow(this.payment.payment_method?.category);
    return {
      checkbox: workflow?.checkbox || false,
      checkbox_text: workflow?.checkbox_text || ''
    };
  };

  this.submit_disabled = () => {
    if (this.payment_method_form.$invalid || currency(this.payment.amount).intValue > currency(this.payable_model.amount_due).intValue) {
      return true;
    }
    if (['CreditorCreditMemo', 'MerchantCreditMemo', null].includes(this.payment.selected_category ?? null)) {
      return currency(this.payment.amount).intValue !== currency(this.payable_model.amount_due).intValue;
    } else {
      return this.checkbox().checkbox && !this.payment.tos_accepted;
    }
  };

  this.payment_method_amount = (payment_method) => {
    if(this.payment.selected_category === payment_method.category) {
      return this.payment.payment_amount;
    } else {
      return currency(0);
    }
  };

  this.build_payment_action = () => {
    const payment_action = { payment: this.payment };
    payment_action.currency_data = this.payable_model.currency_data;
    payment_action.credit_memos = this.credit_memos.filter(credit_memo => currency(credit_memo.applied_amount).intValue > 0);
    payment_action.payment.credit_memos = payment_action.credit_memos;
    return paymentActionDecorator.decorate(payment_action);
  };

  this.next_state = () => {
    if(this.payment_method_form.$invalid) {
      // TODO other error messaging
      return false;
    }
    this.submitting = true;
    const payment_action = this.build_payment_action();
    this.final_workflow(payment_action).resolve_next_state(payment_action, $state);
    return true;
  };

  this.next_state_button_options = {
    buttonDefaultText: 'Confirm Payment',
    buttonSubmittingText: 'Submitting...',
    buttonSuccessText: 'Payment Confirmed',
    buttonDefaultClass: 'button',
    buttonSubmittingClass: 'button',
    buttonSuccessClass: 'button',
    buttonErrorClass: 'button'
  };
  this.result = null;
  this.submitting = false;

}
