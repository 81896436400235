angular.module('apruve.common.alerts')
.factory 'apruveFlashInterceptor', ['$rootScope', '$interval', '$q', ($rootScope, $interval, $q) ->
  processor = (response) ->
    destroyMessage = ->
      $rootScope.alerts.pop()
    if response.data?.meta?.flash?
      for message in response.data.meta.flash
        $rootScope.alerts = [message].concat $rootScope.alerts
        $interval(destroyMessage, 7000, 1) # This is basically a timeout that won't halt our tests
    response
  failureProcessor = (response) ->
    $q.reject(processor(response))
  response: processor
  responseError: failureProcessor
]