import business_representative_form from '../../../../templates/sellers/bank_account_card/business_representative_form.html'
import merchant_details_form from '../../../../templates/sellers/bank_account_card/merchant_details_form.html'

angular.module('apruve.sellers.bank_account').controller 'PayoutAccountFormController', [
  'Merchant', 'currentUserService', '$scope', '$uibModalInstance',
  (Merchant, currentUserService, $scope, $uibModalInstance) ->

    $scope.business_representative_form = business_representative_form
    $scope.merchant_details_form = merchant_details_form

    $scope.reload_merchant = =>
      currentUserService.user.$promise.then (user) ->
        Merchant.Form.get {merchant_id: currentUserService.currentContext.id}, (merchant) ->
          $scope.merchant = merchant
    $scope.reload_merchant()

    $scope.business_ein_pattern =
      '^((?!000)(?!666)(?:[0-6][0-9]{2}|7[0-2][0-9]|73[0-3]|7[5-6][0-9]|77[0-2]))-((?!00)[0-9]{2})-((?!0000)[0-9]{4})$|^[0-9]{2}-?[0-9]{7}$'

    $scope.personal_id_number_pattern =
      '^((?!000)(?!666)(?:[0-6][0-9]{2}|7[0-2][0-9]|73[0-3]|7[5-6][0-9]|77[0-2]))-((?!00)[0-9]{2})-((?!0000)[0-9]{4})$'

    $scope.tooltip_template =
      '<div>This section is used to verify the identity of the business representative as part of our bank\'s anti-fraud measures.</div>'

    $scope.form_page = 0

    $scope.show_error_class = (elem, form) ->
      submitted = if form then form.$submitted else false
      !elem.$valid && (elem.$touched || elem.$dirty || submitted)

    $scope.show_dob_error_class = (elem, form) ->
      submitted = if form then form.$submitted else false
      !elem.$valid && submitted

    $scope.submitMerchantDetails = (merchantDetailsForm) ->
      # return if there are errors other than on masked_business_ein
      for own error_key of merchantDetailsForm.$error
        if error_key != 'pattern'
          return
        else if merchantDetailsForm.$error[error_key].length > 1
          return
        else if merchantDetailsForm.$error[error_key][0].$name != 'masked_business_ein'
          return

      # only set ein if user changed the value and it is valid
      if merchantDetailsForm.masked_business_ein.$dirty && merchantDetailsForm.masked_business_ein.$invalid
        return
      else if merchantDetailsForm.masked_business_ein.$valid
        $scope.merchant.business_ein = merchantDetailsForm.masked_business_ein.$$lastCommittedViewValue

      Merchant.Form.update {merchant_id: $scope.merchant.id}, $scope.merchant, (merchant) ->
        $scope.form_page = 1
        $scope.reload_merchant()

    $scope.submitBusinessRepresentative = (businessRepresentativeForm) ->
      if businessRepresentativeForm.$valid
        Merchant.PayoutAccount.update {merchant_id: $scope.merchant.id}, {merchant: $scope.merchant}, (merchant) ->
          $scope.form_page = 2
          $scope.start_create()
          $scope.reload_merchant()

    $scope.close_modal = () ->
      $uibModalInstance.dismiss()

    return
]