angular.module('apruve.users.payment').controller "PaymentsController", [
  '$scope', '$state', '$stateParams' ,'statusIconsService',
  ($scope, $state, $stateParams, statusIconsService) ->
    @statusIconsService = statusIconsService
    @url = "/payments.json"
    @url_params = $stateParams
    @url_params.page = @url_params.page && parseInt(@url_params.page)
    @url_params.search ||= ''
    @new_params = angular.copy(@url_params)
    @search_field = @new_params.search

    @navigate_to_payment = (payment) =>
      $state.go('payment', payment_uuid: payment.uuid)

    @search = =>
      @new_params.page = 0
      @new_params.search = @search_field

    @reset_search = =>
      @search_field = ''
      @search()

    @resort = (clicked_on_col) =>
      if @new_params['sort_column'] != clicked_on_col
        @new_params['sort_column'] = clicked_on_col
        @new_params['sort_order'] = 'desc'
      else
        if @new_params['sort_order'] == 'desc'
          @new_params['sort_order'] = 'asc'
        else
          @new_params['sort_order'] = 'desc'

    @sort_classes_for = (column) =>
      if column == @url_params['sort_column']
        ['apruve-icon-angle', @url_params['sort_order']]
      else
        []

    @row_classes_for = (payment) =>
      classes = []
      unless payment.unapplied_amount_needs_attention
        classes.push 'list-item-done'
      classes

    @load_new_page = (new_value, old_value) =>
      return true if !new_value? or !old_value?
      return true if new_value == old_value
      $state.go '.', new_value, notify: false
      @url_params = angular.copy(new_value)
      return true if new_value.page != old_value.page

    $scope.$watch "ctrl.new_params", @load_new_page, true

    return this
]