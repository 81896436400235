import { en } from 'translations/users/en.yml';
import { ru } from 'translations/users/ru.yml';
import { zh } from 'translations/users/zh.yml';
angular.module('apruve.admin.app')
    .config(['$translateProvider',
      function ($translateProvider) {
        // Stop XSS attacks
        $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
        // make messageformat interpolation available but not the default
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        $translateProvider.translations('en', en);
        $translateProvider.translations('ru', ru);
        $translateProvider.translations('zh', zh);

        // TODO: use user's preferred language
        $translateProvider.preferredLanguage('en');
      }
    ])
    .run(['$rootScope', '$translate', function ($rootScope, $translate) {
      $rootScope.$on('language.change', (event, args) => $translate.use(args.lang));
    }]);
