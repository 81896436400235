import moment from 'moment';
angular.module('apruve.users.services')
.factory('statusIconsService', [
  function() {
    let StatusIconsService;
    return new (StatusIconsService = class StatusIconsService {
      payment_class(payment) {
        const status_class = (() => { switch (payment.status) {
          case 'pending': return 'list-item-status--pending';
          case 'processing': return 'list-item-status--processing';
          case 'captured': return 'list-item-status--captured';
          case 'failed': return 'list-item-status--failed';
          case 'canceled': return 'list-item-status--canceled';
          default: return 'list-item-status--unknown';
        } })();
        return [status_class];
      }
      invoice_class(invoice) {
        const status_class = (() => { switch (invoice.status) {
          case 'closed': return 'list-item-status--closed';
          case 'canceled': return 'list-item-status--canceled';
          case 'open': case 'funded':
            switch (false) {
              case invoice.status_display !== 'overdue': return 'list-item-status--urgent';
              case invoice.status_display !== 'due soon': return 'list-item-status--due';
              case invoice.status_display !== 'pending': return 'list-item-status--pending';
              default: return 'list-item-status--open';
            }
          default: return 'list-item-status--unknown';
        } })();
        return [status_class];
      }
      statement_class(statement) {
        const status_class = (() => { switch (statement.status) {
          case 'closed': return 'list-item-status--closed';
          case 'issued':
            if (statement.type === 'Statement') {
              switch (false) {
                case ((!(moment() > moment(statement.due_at)))) || (statement.amount_due === "$0.00"): return 'list-item-status--urgent';
                case ((!(moment(statement.due_at) < (moment().add(5, 'days'))))) || (statement.amount_due === "$0.00"): return 'list-item-status--due';
                default: return 'list-item-status--issued';
              }
            }
          case 'open': return 'list-item-status--open';
          default: return 'list-item-status--unknown';
        } })();
        return [status_class];
      }
      purchase_order_class(purchase_order) {
        const status_class = (() => { switch (purchase_order.status) {
          case 'accepted': return 'list-item-status--approved';
          case 'canceled': return 'list-item-status--rejected';
          case 'pending': return 'list-item-status--open';
          default: return 'list-item-status--unknown';
        } })();
        return [status_class];
      }
    });
  }
]);