import Highcharts from 'highcharts';

angular.module('apruve.common.corporate_account')
.directive 'creditLimitDonut', [
  'loanDonut', 'chartColors',
  (loanDonut, chartColors) ->

    remaining_green = chartColors.secondaryLight
    invoiced_blue = chartColors.primary
    authorized_blue = chartColors.primaryLight
    colors = [remaining_green, invoiced_blue, authorized_blue]
    name = 'Credit Limit'
    data = (scope) -> [
      ['Credit Remaining', scope.creditAvailable]
      ['Credit Spent', scope.creditInvoiced]
      ['Credit Authorized', scope.creditAuthorized]
    ]

    loan_options = loanDonut.loanOptions()
    loan_options['scope'] =
      creditAvailable: '='
      creditAuthorized: '=' # Uncaptured auth amount
      creditInvoiced: '='
      percentageSpend: '='
    loan_options['link'] = (scope, element, attr) ->
      chart = Highcharts.chart element[0].querySelector('#credit-limit-donut-chart'), loanDonut.chartOptions(colors, name, data(scope))
    loan_options['controller'] = ['$scope', ($scope) -> $scope.percentageLabel = 'credit spent']
    loan_options
]