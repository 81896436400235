angular.module('apruve.common.alerts')
.directive 'apruveAlert', ->
  restrict: 'E'
  scope:
    alerts: '='
  template: """
            <div class='alertContainer'>
              <div class='alert page-level fade in notification {{ (alert[0] == "notice" ? "alert-positive" : (alert[0] == "error" ? "alert-negative" : "")) }} role="alert"' ng-repeat='alert in alerts'>
                <div class='alert-body'>
                  <div class='icon'>
                    <span class='apruve-icon-circle-info'></span>
                  </div>
                  <div id='alert-body-content' class='content'>
                  {{alert[1]}}
                  </div>
                </div>
              </div>
            </div>
            """