angular.module('apruve.common.charts', [])
  .factory 'hcPerformance', ->
    this.hcLink = (scope, chart) ->
      # Kinda hacky way of allowing directive methods to be called. I wanted the logic of modifying a highcharts
      # chart to be in this directive, so that it can be reused.
      scope.chartControl = scope.chartControl || {}

      scope.chartControl.addYAxis = (axis) ->
        chart.addAxis axis

      scope.chartControl.addXAxis = (axis) ->
        chart.addAxis axis, isX: true

      scope.chartControl.removeYAxis = (axisIndex) ->
        chart.yAxis[axisIndex].remove()

      scope.chartControl.removeXAxis = (axisIndex) ->
        chart.xAxis[axisIndex].remove()

      scope.chartControl.addSeries = (series) ->
        chart.addSeries series

      scope.chartControl.removeSeries = (seriesIndex) ->
        chart.series[seriesIndex].remove()

      scope.chartControl.updateSeries = (series, seriesIndex) ->
        chart.series[seriesIndex].update series

      # When you only need to update the data for a series (no options), this is fastest
      scope.chartControl.setSeriesData = (data, seriesIndex) ->
        chart.series[seriesIndex].setData data

      # Generic update function for chart settings
      scope.chartControl.updateChart = (options) ->
        chart.update options, false

      scope.chartControl.setXAxisExtremes = (axisIndex, min, max) ->
        chart.xAxis[axisIndex].setExtremes min, max

      scope.chartControl.redraw = ->
        chart.redraw()

      scope.chartControl.updateTooltipDateFormat = (format) ->
        chart.userOptions.tooltip.xDateFormat = format

    this.hcOptions = ->
      restrict: 'E',
      template: '<div></div>'
      scope:
        options: '='
        chartControl: '='

    this