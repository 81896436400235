angular.module('apruve.sellers.corporate_account').controller "SellersCreditLimitEditController", [
  'SellersCorporateAccount', 'corporate_account', '$location', '$state', '$stateParams', (CorporateAccount, corporate_account, $location, $state, $stateParams) ->

    @corporate_account = corporate_account
    @submitting = null
    @result = null
    @display_errors = false

    @go_back = =>
      $state.go($stateParams.next_state, {corporate_account_id: @corporate_account.uuid})

    @remove_limit = () =>
      @corporate_account.credit_limit._destroy = true
      CorporateAccount.update @corporate_account, (result) ->
        @result = 'success'
        @go_back()
      , (response) =>
        @result = 'error'
        @display_errors = true
        @corporate_account = response.data
        console.error(response.data)

    @submit_options =
      buttonDefaultText: 'Save'
      buttonSubmittingText: 'Working...'
      buttonSuccessText: 'Success!'

    @submit_form = () =>
      @submitting = true
      if @account_form.$invalid
        @result = 'error'
        @display_errors = true
        return false

      @display_errors = false
      CorporateAccount.update @corporate_account, (result) =>
        @result = 'success'
        @go_back()
      , (response) =>
        @result = 'error'
        @display_errors = true
        @corporate_account = response.data
        console.error(response.data)

      return

    return
]