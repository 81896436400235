'use strict';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
angular.module('apruve.common.helpers').filter('format_phone', () => {
    return input => {
        if (!input) {
            return input;
        }
        return formatPhoneNumber(input, 'US') ?? input;
    };
});


function formatPhoneNumber(number, country) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        const parsedNumber = phoneUtil.parse(number, country);
        if (!phoneUtil.isValidNumberForRegion(parsedNumber, country)) {
            return;
        }
        return phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
    } catch (e) {
        // Same case as not valid for region - return undefined for failure (since it's a normal case)
    }
}