angular.module('apruve.common.tooltip')
.directive 'tooltip', ->
  restrict: 'E'
  scope:
    alerts: '='
  transclude: true
  controller: 'TooltipController'
  controllerAs: 'tooltipCtrl'
  template: """
            <div class='tooltip__container'>
              <div class='tooltip__backdrop' ng-show='tooltipCtrl.toggleState' ng-click='tooltipCtrl.toggle()'></div>
              <a class='tooltip__toggle link--plain' ng-click='tooltipCtrl.toggle()'>
                <span class='glyphicon glyphicon-question-sign'></span>
              </a>
              <div class='tooltip__content' ng-show='tooltipCtrl.toggleState' ng-transclude></div>
            </div>
            """
