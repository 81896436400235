/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import moment from 'moment';
angular.module('apruve.sellers.app.routes', []).config([
  '$stateProvider', '$urlRouterProvider',
  function($stateProvider, $urlRouterProvider) {

    var initialParams = {
      search: {
        value: '',
        squash: true
      },
      page: {
        value: '0',
        squash: true
      },
      sort_column: {
        value: '',
        squash: true
      },
      sort_order: {
        value: '',
        squash: true
      }
    };

    $stateProvider
    .state('sellers.checklist', {
      title: 'Checklist',
      url: '/checklist',
      templateUrl: require("../../../templates/sellers/checklist/index.html.haml"),
      controller: 'ChecklistController',
      controllerAs: 'checklistCtrl'
    }
    );

    $stateProvider
    .state('sellers', {
      controller: 'SellersBaseController',
      url: '/sellers/:merchant_id',
      resolve: {
        CurrentUser: ['CurrentUser', function(CurrentUser) {
          return CurrentUser.get().$promise;
        }
        ]
      }
    })
    .state('sellers.performance', {
      url: '/performance?date_range',
      controller: 'PerformanceController',
      controllerAs: 'performanceCtrl',
      params: {
        date_range: 'last_30'
      },
      "abstract": true, // not a valid state on its own
      templateUrl: require('../../../templates/sellers/performance/index.html')
    }).state('sellers.performance.invoices', {
      url: '/invoices',
      templateUrl: require('../../../templates/sellers/performance/invoices.html'),
      controller: 'PerformanceInvoiceController',
      controllerAs: 'performanceInvoiceCtrl',
      parent: 'sellers.performance',
      resolve: {
        chart: ['Performance', '$stateParams', function(Performance, $stateParams) {
          return Performance.Invoices.get({date_range: $stateParams.date_range, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    })
    .state('sellers.performance.customers', {
      url: '/customers',
      templateUrl: require('../../../templates/sellers/performance/customers.html'),
      controller: 'PerformanceCustomerController',
      controllerAs: 'performanceCustomerCtrl',
      parent: 'sellers.performance',
      resolve: {
        chart: ['Performance', '$stateParams', function(Performance, $stateParams) {
          return Performance.Customers.get({date_range: $stateParams.date_range, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    })
    .state('sellers.performance.aging_report', {
      url: '/aging_report',
      templateUrl: require('../../../templates/sellers/performance/aging_report.html'),
      controller: 'PerformanceAgingReportController',
      controllerAs: 'performanceAgingReportCtrl',
      parent: 'sellers.performance',
      resolve: {
        data: ['Performance', '$stateParams', function(Performance, $stateParams) {
          return Performance.AgingReport.get({merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    })
    .state('sellers.performance.credit', {
      url: '/credit',
      templateUrl: require('../../../templates/sellers/performance/credit.html'),
      controller: 'PerformanceCreditController',
      controllerAs: 'performanceCreditCtrl',
      resolve: {
        data: ['Performance', '$stateParams', function(Performance, $stateParams) {
          return Performance.Credit.get({date_range: $stateParams.date_range, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    });

    // Placeholders for settings routes so that we retain angular sellers context and can return to normal ng sellers app
    $stateProvider
    .state('sellers.technical', {
      title: 'Edit Technical',
      url: '/technical'
    }
    );

    // Invoices
    $stateProvider
    .state('sellers.invoices', {
      title: 'Invoices',
      url: '/invoices?search&page&sort_column&sort_order',
      controller: 'InvoicesController',
      controllerAs: 'invoicesCtrl',
      templateUrl: require('../../../templates/sellers/invoices/index.html'),
      params: angular.copy(initialParams)
    }).state('sellers.invoice', {
      title: 'Invoice',
      url: '/invoices/:invoice_uuid',
      resolve: {
        invoice_data: ['Invoice', '$stateParams', function(Invoice, $stateParams) {
          return Invoice.get({uuid: $stateParams.invoice_uuid, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      },
      controller: "InvoiceController",
      templateUrl: require("../../../templates/sellers/invoices/show.html.haml")
    }).state('sellers.invoice.shipments', {
      title: 'Invoice Shipments',
      url: '/shipments',
      templateUrl: require('../../../templates/sellers/invoices/shipments.html'),
      controller: 'ShipmentsController',
      controllerAs: 'shipments_ctrl',
      resolve: {
        shipment_data: ['Shipment', '$stateParams', function(Shipment, $stateParams) {
          return Shipment.query({merchant_id: $stateParams.merchant_id, invoice_id: $stateParams.invoice_uuid}).$promise;
        }
        ]
      }
    })
    .state('sellers.invoice.funding_details', {
      title: 'Funding Details',
      url: '/funding_details',
      templateUrl: require('../../../templates/sellers/invoices/funding_details.html'),
      controller: 'FundingDetailsController',
      controllerAs: 'funding_details_ctrl',
      resolve: {
        funding_detail_data: ['FundingDetail', '$stateParams', function(FundingDetail, $stateParams) {
          return FundingDetail.query({merchant_id: $stateParams.merchant_id, invoice_id: $stateParams.invoice_uuid}).$promise;
        }
        ]
      }
    })
    .state('sellers.invoice.payments', {
      title: 'Invoice Payments',
      url: '/payments',
      controller: 'InvoicePaymentsController',
      templateUrl: require('../../../templates/sellers/invoices/payments.html')
    }).state('sellers.invoice.payments.new', {
      title: 'Record New Payment',
      resolve: {
        payment_data: ['Payment', '$stateParams', function(Payment, $stateParams) {
          return new Payment({invoice_uuids: [$stateParams.invoice_uuid], merchant_id: $stateParams.merchant_id, captured_at: moment()});
        }
        ],
        corporate_account: ['Invoice', 'SellersCorporateAccount', '$stateParams', function(Invoice, SellersCorporateAccount, $stateParams) {
          return Invoice.get({merchant_id: $stateParams.merchant_id, uuid: $stateParams.invoice_uuid})
            .$promise.then(function(inv) {
            return SellersCorporateAccount.get({merchant_id: $stateParams.merchant_id, uuid: inv.corporate_account_uuid}).$promise;
          });
        }
        ]
      },
      controller: 'NewPaymentController',
      url: '/new',
      templateUrl: require('../../../templates/sellers/invoices/payment_form.html')
    }).state('sellers.invoice.payment', {
      url: '/payments/:payment_uuid',
      template: '<ui-view/>'
    }).state('sellers.invoice.close', {
      title: 'Close Invoice',
      templateUrl: require('../../../templates/sellers/invoices/close_invoice_form.html')
    }).state('sellers.invoice.cancel', {
      title: 'Cancel Invoice',
      templateUrl: require('../../../templates/sellers/invoices/cancel_invoice_form.html')
    }).state('sellers.invoice.contact_customer', {
      title: 'Contact Customer',
      url: '/contact_customer',
      templateUrl: require('../../../templates/sellers/invoices/contact_customer.html')
    }).state('sellers.invoice.new_return', {
      title: 'Record a Return',
      url: '/invoice_returns/new',
      resolve: {
        return_data: ['InvoiceReturn', '$stateParams', function(InvoiceReturn, $stateParams) {
          return new InvoiceReturn({merchant_id: $stateParams.merchant_id, invoice_uuid: $stateParams.invoice_uuid});
        }
        ],
        invoice_data: ['Invoice', '$stateParams', function(Invoice, $stateParams) {
          return Invoice.get({uuid: $stateParams.invoice_uuid, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      },
      controller: 'InvoiceReturnController as ctrl',
      templateUrl: require('../../../templates/sellers/invoices/return_form.html')
    }
    );

    // Billing Invoices
    $stateProvider
    .state('sellers.billing_invoices', {
      title: 'Billing',
      url: '/billing_invoices',
      controller: 'BillingInvoicesController',
      controllerAs: 'ctrl',
      resolve: {
        fee_tier: ['FeeTier', '$stateParams', function(FeeTier, $stateParams) {
          return FeeTier.query({merchant_id: $stateParams.merchant_id}).$promise.then(function(data) {
            return data;
          }
          , function(e) {
            if (e.status === 404) { return null; }
            throw e;
          });
        }
        ],
        saas_fee_tier: ['SaasFeeTier', '$stateParams', function(SaasFeeTier, $stateParams) {
          return SaasFeeTier.query({merchant_id: $stateParams.merchant_id}).$promise.then(function(data) {
            return data;
          }
          , function(e) {
            if (e.status === 404) { return null; }
            throw e;
          });
        }
        ],
        debit_account: ['DebitBankAccount', '$stateParams', function(DebitBankAccount, $stateParams) {
          return DebitBankAccount.get({merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      },
      templateUrl: require('../../../templates/sellers/billing_invoices/index.html')
    }).state('sellers.billing_invoice', {
      title: 'Billing Invoice',
      url: '/billing_invoices/:billing_invoice_uuid',
      resolve: {
        billing_invoice_data: ['BillingInvoice', '$stateParams', function(BillingInvoice, $stateParams) {
          return BillingInvoice.get({uuid: $stateParams.billing_invoice_uuid, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      },
      controller: 'BillingInvoiceController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/sellers/billing_invoices/show.html')
    }).state('sellers.billing_invoice.payments', {
      title: 'Billing Payments',
      url: '/payments',
      controller: 'BillingPaymentsController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/sellers/billing_invoices/payments.html'),
      resoulve: {
        payments_for_invoice: ['']
      }
    })
    .state('sellers.billing_invoice.fees', {
      title: 'Billing Payments',
      url: '/fees',
      controller: 'FeesController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/sellers/billing_invoices/fees.html')
    }
    );

    // Payment
    $stateProvider
    .state('sellers.payments', {
      title: 'Payments',
      url: '/payments?search&page&sort_column&sort_order',
      controller: 'PaymentsController',
      controllerAs: 'paymentsCtrl',
      templateUrl: require('../../../templates/sellers/payments/index.html'),
      params: angular.copy(initialParams),
      resolve: {
        merchant: ['Merchant', '$stateParams', function(Merchant, $stateParams) {
          return Merchant.Form.get({merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
      // stateProvider
    }).state('sellers.payments.new', {
      templateUrl: require('../../../templates/common/payment_modal/modal.html'),
      title: 'New Payment',
      abstract: true,
      url: '/new',
      bindToController: true,
      resolve: {
        payable_model: ['payableModelService', function(payableModelService) {
          return payableModelService.get_payable_model();
        }]
      },
      onEnter: ['payableModelService', 'payable_model', function() {
        $(function() {
          if($('#payment-modal')) {
            $('#payment-modal').modal({backdrop: 'static', keyboard: false});
            $('#payment-modal').modal('show');
          }
        })
      }],
      onExit: function() {
        $('#payment-modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
      }
    })
    .state('sellers.payments.new.payment_methods', {
      templateUrl: require('../../../templates/sellers/payments/upload_payment_csv.html'),
      title: 'Upload Payments',
      url: '',
      controller: 'UploadPaymentsController',
      controllerAs: 'uploadPaymentsCtrl',
      bindToController: true
    }).state('sellers.payment', {
      title: 'Payment',
      url: '/payments/:payment_uuid',
      controller: 'PaymentController',
      templateUrl: require('../../../templates/sellers/payments/show.html'),
      resolve: {
        payment_data: ['Payment', '$stateParams', function(Payment, $stateParams) {
          return Payment.get({uuid: $stateParams.payment_uuid, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    })
    .state('sellers.payment.invoices', {
      title: 'Payment Invoices',
      url: '/invoices',
      templateUrl: require('../../../templates/sellers/payments/invoices.html'),
      controller: 'PaymentInvoicesController',
      controllerAs: 'paymentInvoicesCtrl'
    }).state('sellers.payment.new_refund', {
        title: 'New Refund',
        url: '/refunds/new',
        resolve: {
            refund_data: ['Refund', '$stateParams', function(Refund, $stateParams) {
              return new Refund({payment_uuid: $stateParams.payment_uuid, merchant_id: $stateParams.merchant_id});
            }
            ]
          },
        controller: 'RefundController',
        templateUrl: require('../../../templates/sellers/payments/refund_form.html')
      }
    );

    // Credit Memo
    $stateProvider
    .state('sellers.credit_memos', {
      title: 'Credit Memos',
      url: '/credit_memos?search&page&sort_column&sort_order',
      controller: 'CreditMemosController',
      controllerAs: 'creditMemosCtrl',
      templateUrl: require('../../../templates/sellers/credit_memos/index.html'),
      params: angular.copy(initialParams),
      resolve: {
        merchant: ['Merchant', '$stateParams', function(Merchant, $stateParams) {
          return Merchant.Form.get({merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    })
    .state('sellers.credit_memo', {
      title: 'Credit Memo',
      url: '/credit_memos/:credit_memo_uuid',
      controller: 'CreditMemoController',
      templateUrl: require('../../../templates/sellers/credit_memos/show.html'),
      resolve: {
        credit_memo_data: ['CreditMemo', '$stateParams', function(CreditMemo, $stateParams) {
          return CreditMemo.get({uuid: $stateParams.credit_memo_uuid, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    })
    .state('sellers.credit_memo.invoices', {
      title: 'Credit Memo Invoices',
      url: '/invoices',
      templateUrl: require('../../../templates/sellers/payments/invoices.html'),
      controller: 'CreditMemoInvoicesController',
      controllerAs: 'paymentInvoicesCtrl'
    }).state('sellers.credit_memo.cancel', {
      title: 'Cancel Credit Memo',
      url: '/cancel',
      controller: "CancelCreditMemoController",
      templateUrl: require('../../../templates/sellers/credit_memos/cancel_credit_memo_form.html')
    }
    );

    // Payout
    $stateProvider
    .state('sellers.payouts', {
      title: 'Payouts',
      url: '/payouts?search&page&sort_column&sort_order',
      templateUrl: require('../../../templates/sellers/payouts/index.html'),
      controller: 'PayoutsController',
      controllerAs: 'ctrl',
      params: angular.copy(initialParams),
      resolve: {
        payoutsSummary: ['SellersPayoutSummary', '$stateParams', function(SellersPayoutSummary, $stateParams) {
          return SellersPayoutSummary.get({ merchant_id: $stateParams.merchant_id }).$promise;
        }
        ],
        payout_account: ['SellerBankAccount', '$stateParams', function(SellerBankAccount, $stateParams) {
          return SellerBankAccount.get({merchant_id: $stateParams.merchant_id}).$promise;
        }
        ],
        merchant: ['Merchant', '$stateParams', function(Merchant, $stateParams) {
          return Merchant.Form.get({merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    })
    .state('sellers.payout', {
      title: 'Payout',
      url: '/payouts/:payout_id',
      templateUrl: require('../../../templates/sellers/payouts/show.html'),
      controller: 'PayoutController',
      controllerAs: 'ctrl',
      resolve: {
        payout_data: ['Payout', '$stateParams', function(Payout, $stateParams) {
          return Payout.get({uuid: $stateParams.payout_id, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    });

    // PurchaseOrder
    $stateProvider
    .state('sellers.purchase_orders', {
      title: 'Orders',
      url: '/purchase_orders?search&page&sort_column&sort_order',
      controller: 'PurchaseOrdersController',
      controllerAs: 'posCtrl',
      templateUrl: require('../../../templates/sellers/purchase_orders/index.html'),
      params: angular.copy(initialParams)
    }).state('sellers.purchase_order', {
      title: 'Order',
      url: '/purchase_orders/:purchase_order_id',
      resolve: {
        purchase_order_data: ['PurchaseOrder', '$stateParams', function(PurchaseOrder, $stateParams) {
          return PurchaseOrder.get({purchase_order_id: $stateParams.purchase_order_id, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      },
      controller: 'PurchaseOrderController',
      templateUrl: require('../../../templates/sellers/purchase_orders/show.html')
    }).state('sellers.purchase_order.invoices', {
      title: 'Order Invoices',
      url: '/invoices',
      controller: 'PurchaseOrderInvoicesController',
      controllerAs: 'poInvoiceCtrl',
      templateUrl: require('../../../templates/sellers/purchase_orders/invoices.html'),
      resolve: {
        invoice_data: ['Invoice', '$stateParams', 'purchase_order_data', function(Invoice, $stateParams, purchase_order_data) {
          var currency = 'USD';
          if (purchase_order_data.currency) {
            currency = purchase_order_data.currency;
          }
          for (var _i = 0, _Array$from = Array.from(purchase_order_data.line_items); _i < _Array$from.length; _i++) {
            var item = _Array$from[_i];
            item.price_total = item.amount;
            item.currency = currency;
          }
          return new Invoice({merchant_id: $stateParams.merchant_id, purchase_order_uuid: $stateParams.purchase_order_id, shipping: purchase_order_data.shipping, amount: purchase_order_data.amount, tax: purchase_order_data.tax, subtotal: purchase_order_data.subtotal, currency: currency, invoice_items: purchase_order_data.line_items});
        }
        ]
      }
    })
    .state('sellers.purchase_order.invoices.new', {
      title: 'Order Create Invoice',
      url: '/new',
      views: {
        "new-invoice-view": {
          templateUrl: require('../../../templates/sellers/purchase_orders/new_invoice.html')
        }
      }
    })
    .state('sellers.purchase_order.cancel_order', {
      title: 'Cancel Order',
      templateUrl: require('../../../templates/sellers/purchase_orders/cancel_form.html')
    }).state('sellers.purchase_order.contact_customer', {
      title: 'Contact Customer',
      url: '/contact_customer',
      templateUrl: require('../../../templates/sellers/purchase_orders/contact_customer.html')
    }).state('sellers.purchase_order.subscriptions', {
      title: 'Subscriptions',
      url: '/subscriptions',
      templateUrl: require('../../../templates/sellers/purchase_orders/subscriptions.html')
    }).state('sellers.purchase_order.cancel_subscriptions', {
      title: 'Cancel Subscriptions',
      url: '/subscriptions/cancel',
      templateUrl: require('../../../templates/sellers/purchase_orders/cancel_subscription_form.html')
    }
    );

    // Statements
    $stateProvider
    .state('sellers.statements', {
      title: 'Statements',
      url: '/statements?search&page&sort_column&sort_order',
      controller: 'StatementsController',
      controllerAs: 'statementsCtrl',
      templateUrl: require('../../../templates/sellers/statements/index.html'),
      params: angular.copy(initialParams)
    }).state('sellers.statement', {
      title: 'Statement',
      url: '/statements/:statement_id?page',
      controller: 'StatementController',
      controllerAs: 'statementCtrl',
      templateUrl: require('../../../templates/sellers/statements/show.html'),
      resolve: {
        statement_data: ['Statement', '$stateParams', function(Statement, $stateParams) {
          return Statement.get({id: $stateParams.statement_id, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    })
    .state('sellers.statement.payments', {
      title: 'Payments for Statement',
      url: '/payments?page',
      controller: 'StatementPaymentsController',
      controllerAs: 'statementPaymentsController',
      templateUrl: require('../../../templates/sellers/statements/payments.html')
    }
    );


    // Administrators
    $stateProvider
    .state('sellers.account_administrators', {
      title: 'Account Administrators',
      url: '/administrators?page',
      templateUrl: require('../../../templates/sellers/account_administrators/index.html'),
      controller: 'SellersTeamAdminController',
      controllerAs: 'ctrl',
      resolve: {
        roles: ['Role', '$stateParams', function(Role, $stateParams) {
          return Role.query({merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    })

    .state('sellers.account_administrator', {
      title: 'Account Administrator',
      url: '/administrators/:account_admin_id',
      templateUrl: require('../../../templates/sellers/account_administrators/show.html'),
      controller: 'SellersEditTeamAdminController',
      controllerAs: 'ctrl',
      resolve: {
        team_admin: ['SellersTeamAdmin', '$stateParams', function(SellersTeamAdmin, $stateParams) {
          return SellersTeamAdmin.get({merchant_id: $stateParams.merchant_id, uuid: $stateParams.account_admin_id}).$promise;
        }
        ],
        roles: ['Role', '$stateParams', function(Role, $stateParams) {
          return Role.query({merchant_id: $stateParams.merchant_id}).$promise;
        }
        ]
      }
    });

    // Funding Details
    $stateProvider
    .state('sellers.funding_events', {
      title: 'Funding Activity',
      url: '/funding_events',
      controller: 'SellersFundingEventsController as sellerFundEventsCtrl',
      templateUrl: require('../../../templates/sellers/funding_events/index.html'),
      resolve: {
        fundingEventsSummary: ['SellersFundingEventsSummary', '$stateParams', function(SellersFundingEventsSummary, $stateParams) {
          return SellersFundingEventsSummary.get({ merchant_id: $stateParams.merchant_id }).$promise;
        }
        ]
      }
    })
    .state('sellers.funding_event', {
      title: 'Funding Activity',
      url: '/funding_events/:funding_event_id',
      controller: 'SellersFundingEventController as sellerFundEventCtrl',
      templateUrl: require('../../../templates/sellers/funding_events/show.html'),
      resolve: {
        fundingEventsSummary: ['SellersFundingEventsSummary', '$stateParams', function(SellersFundingEventsSummary, $stateParams) {
          return SellersFundingEventsSummary.get({ merchant_id: $stateParams.merchant_id }).$promise;
        }
        ],
        funding_event: ['SellersFundingEvent', '$stateParams', function(SellersFundingEvent, $stateParams) {
          return SellersFundingEvent.get({
            uuid: $stateParams.funding_event_id,
            merchant_id: $stateParams.merchant_id
          }).$promise;
        }
        ]
      }
    });

    // Corporate Accounts
    $stateProvider
    .state("sellers.corporate_accounts", {
      title: "Corporate Accounts",
      url: "/corporate_accounts?status?search_term",
      controller: "SellersCorporateAccountsController as sellerCorpAcctCtrl",
      templateUrl: require("../../../templates/sellers/corporate_accounts/index.html"),
      resolve: {
        object_counts: [
          "SellersCorporateAccount",
          "$stateParams",
          function (SellersCorporateAccount, $stateParams) {
            return SellersCorporateAccount.object_counts({
              merchant_id: $stateParams.merchant_id,
            }).$promise;
          },
        ],
      },
      params: {
        status: "approved",
        search_term: ""
      },
    }).state('sellers.new_account', {
      title: 'New Corporate Account',
      url: '/corporate_accounts/new',
      controller: 'NewAccountController as newAcctCtrl',
      templateUrl: require('../../../templates/sellers/corporate_accounts/new_account/new.html'),
      resolve: {
        merchant: ['$stateParams', 'Merchant', function($stateParams, Merchant) {
          return Merchant.Form.get({ merchant_id: $stateParams.merchant_id }).$promise;
        }
        ]
      }
    })
    .state('sellers.corporate_account', {
      title: 'Corporate Account Details',
      url: '/corporate_accounts/:corporate_account_id',
      controller: 'SellersCorporateAccountController',
      controllerAs: 'corpAcctCtrl',
      templateUrl: require('../../../templates/sellers/corporate_accounts/show.html'),
      resolve: {
        corporate_account: ['SellersCorporateAccount', '$stateParams', function(SellersCorporateAccount, $stateParams) {
          return SellersCorporateAccount.get({
            uuid: $stateParams.corporate_account_id,
            merchant_id: $stateParams.merchant_id
          }).$promise;
        }
        ],
        statistics: ['SellersCorporateAccountStatistics', '$stateParams', function(SellersCorporateAccountStatistics, $stateParams) {
          return SellersCorporateAccountStatistics.get({
            uuid: $stateParams.corporate_account_id,
            merchant_id: $stateParams.merchant_id
          }).$promise;
        }
        ],
        data: ['SellersCorporateAccountAgingReport', '$stateParams', function(SellersCorporateAccountAgingReport, $stateParams) {
          return SellersCorporateAccountAgingReport.get({uuid: $stateParams.corporate_account_id, merchant_id: $stateParams.merchant_id}).$promise;
        }
        ],
        merchant: ['$stateParams', 'Merchant', function($stateParams, Merchant) {
          return Merchant.Form.get({ merchant_id: $stateParams.merchant_id }).$promise;
        }
        ]
      }
    }).state('sellers.corporate_account.tabs', {
      title: 'Corporate Account Tabs',
      url: '',
      controller: 'SellersCorporateAccountController',
      controllerAs: 'corpAcctCtrl',
      parent: 'sellers.corporate_account',
      templateUrl: require('../../../templates/sellers/corporate_accounts/tabs.html')
    }).state('sellers.corporate_account.tabs.details', {
      title: 'Corporate Account Details Details',
      url: '/details',
      parent: 'sellers.corporate_account.tabs',
      controller: 'SellersCorporateAccountController',
      controllerAs: 'corpAcctCtrl',
      templateUrl: require('../../../templates/sellers/corporate_accounts/_corporate_account.html')
    }).state('sellers.corporate_account.tabs.activity_stream', {
      title: 'Corporate Account Details Details',
      url: '/activity_stream',
      parent: 'sellers.corporate_account.tabs',
      controller: 'SellersCorporateAccountActivityStreamController',
      controllerAs: 'activityStreamCtrl',
      templateUrl: require('../../../templates/sellers/corporate_accounts/_activity_stream.html')
    }).state('sellers.corporate_account.tabs.team_members', {
      title: 'Corporate Account Details Details',
      url: '/team_members',
      parent: 'sellers.corporate_account.tabs',
      controller: 'CorporateAccountTeamMembersController',
      controllerAs: 'teamMembersCtrl',
      templateUrl: require('../../../templates/sellers/corporate_accounts/_team_members.html')
    }).state('sellers.corporate_account.tabs.orders', {
      title: 'Corporate Account Details Details',
      url: '/orders?customer&page&sort_column&sort_order',
      parent: 'sellers.corporate_account.tabs',
      controller: 'PurchaseOrdersController',
      controllerAs: 'posCtrl',
      templateUrl: require('../../../templates/sellers/purchase_orders/index.html'),
      params: {
        scoped_account: true,
        customer: null
      }
    }).state('sellers.corporate_account.tabs.invoices', {
      title: 'Corporate Account Details Details',
      url: '/invoices?customer&page&sort_column&sort_order',
      parent: 'sellers.corporate_account.tabs',
      controller: 'InvoicesController',
      controllerAs: 'invoicesCtrl',
      templateUrl: require('../../../templates/sellers/invoices/index.html'),
      params: {
        scoped_account: true,
        customer: null
      }
    }).state('sellers.corporate_account.tabs.payments', {
      title: 'Corporate Account Details Details',
      url: '/payments?customer&page&sort_column&sort_order',
      parent: 'sellers.corporate_account.tabs',
      controller: 'PaymentsController',
      controllerAs: 'paymentsCtrl',
      templateUrl: require('../../../templates/sellers/payments/index.html'),
      params: {
        scoped_account: true,
        customer: null
      }
    }).state('sellers.corporate_account.edit', {
      title: 'Edit Credit Limit',
      url: '/edit',
      controller: 'SellersCreditLimitEditController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/sellers/corporate_accounts/credit_limit_edit.html'),
      params: {
        next_state: 'sellers.corporate_accounts'
      }
    }).state('sellers.corporate_account.new_credit_memo', {
      title: 'New Credit Memo',
      url: '/credit_memos/new',
      controller: 'NewCreditMemoController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/sellers/credit_memos/new.html'),
      params: {
        next_state: 'sellers.credit_memos'
      }
    }).state('sellers.request_limit_change', {
      title: 'Request Credit Limit Change',
      url: '/corporate_accounts/:corporate_account_id/credit_limit_change_requests/new',
      controller: 'SellersCreditLimitChangeRequestController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/sellers/corporate_accounts/credit_limit_change_request.html'),
      params: {
        next_state: 'sellers.corporate_accounts'
      }
    }).state('sellers.request_limit_change.success', {
      title: 'Credit Limit Change Requested',
      url: '/success',
      controller: 'SellersCreditLimitChangeRequestController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/sellers/corporate_accounts/credit_limit_change_requested.html'),
      params: {
        next_state: 'sellers.corporate_accounts'
      }
    }).state('sellers.corporate_account.prepayment_new', {
      title: 'New Prepayment',
      url: '/payments/new_prepayment',
      controller: 'SellersPrepaymentNewController',
      controllerAs: 'ctrl',
      templateUrl:  require('../../../templates/sellers/payments/prepayment_new.html'),
      params: {
        next_state: 'sellers.corporate_account'
      }
    }
    );
    return $stateProvider
    .state('sellers.credit_applications_new', {
      title: 'New Credit Application',
      url: '/credit_applications/new',
      controller: 'CreditApplicationsController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/sellers/credit_applications/new.html')
    }).state('sellers.financing_applications_new', {
      title: 'New Financed Application',
      url: '/financing_applications/new',
      controller: 'FinancingApplicationsController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/sellers/financing_applications/new.html')
    }
    );
  }
]);
