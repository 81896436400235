angular.module('apruve.sellers.billing_invoices').controller 'FeesController', [
  '$state', '$stateParams', 'billing_invoice_data',
  ($state, $stateParams, billing_invoice_data) ->
    @url_params = $stateParams
    @url_params.page = parseInt @url_params.page
    @new_params = angular.copy(@url_params)
    @billing_invoice = billing_invoice_data

    @url = ->
      "/sellers/#{$stateParams.merchant_id}/billing_invoices/#{@billing_invoice.uuid}/fees.json"

    return
]