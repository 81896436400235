import Highcharts from 'highcharts';

angular.module('apruve.common.corporate_account')
.directive 'financingLoanDonut', [
  'loanDonut', 'chartColors',
  (loanDonut, chartColors) ->
    paid_green = chartColors.secondaryLight
    balance_blue = chartColors.primaryLight
    colors = [paid_green, balance_blue]
    name = 'Financing Loan'
    data = (scope) -> [
      ['Amount Paid', scope.loanPaid]
      ['Amount Remaining', scope.loanBalance]
    ]

    loan_options = loanDonut.loanOptions()
    loan_options['scope'] =
      loanBalance: '='
      loanPaid: '='
      percentageSpend: '='
    loan_options['link'] = (scope, element, attr) ->
      chart = Highcharts.chart element[0].querySelector('#credit-limit-donut-chart'), loanDonut.chartOptions(colors, name, data(scope))
    loan_options['controller'] = ['$scope', ($scope) -> $scope.percentageLabel = 'remaining']
    loan_options
]