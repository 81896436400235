angular.module('apruve.sellers.payment').controller "RefundController", [
  '$scope', '$state', 'Refund', 'refund_data',
  ($scope, $state, Refund, refund_data) ->
    $scope.refund = refund_data

    $scope.create_refund = ->
      $scope.submitting = true

      if $scope.refundForm.$invalid
        $scope.display_errors = true
        $scope.result = 'error'
        return false

      $scope.display_errors = false
      $scope.refund.$save (response) ->
        if response.meta.errors == null
          $scope.result = 'success'
          $state.go('sellers.payment', {}, {reload: true})
        else
          $scope.display_errors = true
          $scope.result = 'error'
          $scope.refund.meta = response.meta
          console.error('Error while creating refund: ', JSON.stringify(response.meta))
      , (response) ->
        $scope.refund.meta = response.meta
        $scope.result = 'error'
        console.error('Error while creating refund: ', JSON.stringify(response.data))

    $scope.display_errors = false
    $scope.result = null
    $scope.submitting = null
    $scope.refund_options =
      buttonDefaultText: 'Issue Refund'
      buttonSubmittingText: 'Creating...'
      buttonSuccessText: 'Refund Issued'
      buttonDefaultClass: 'actionBar-btn-primary'
      buttonSubmittingClass: 'actionBar-btn-primary'
      buttonSuccessClass: 'actionBar-btn-primary'
      buttonErrorClass: 'actionBar-btn-danger'
]