angular.module('apruve.creditors.app.routes', []).config([
  '$stateProvider', '$urlRouterProvider',
  function($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise(window.location.pathname);

    $stateProvider
    .state('creditors', {
      controller: 'CreditorsBaseController',
      url:'/creditors',
      template: '<ui-view/>'
    })

    .state('creditors.home', {
      title: 'Home',
      url:'/:creditor_slug?date_range&tab',
      template: '<ui-view/>',
      controller: 'PerformanceController',
      controllerAs: 'performanceCtrl',
      params: {
        date_range: 'last_30',
        tab: 'credit'
      }
    })
    .state('creditors.home.credit', {
      title: 'Home',
      templateUrl: require('../../../templates/creditors/home/credit/index.html'),
      controller: 'PerformanceCreditController',
      controllerAs: 'performanceCreditCtrl',
      resolve: {
        data: ['CreditorPerformance', '$stateParams', (CreditorPerformance, $stateParams) => {
          return CreditorPerformance.Credit.get({date_range: $stateParams.date_range, creditor_slug: $stateParams.creditor_slug}).$promise;
        }]
      }
    });
 }
]);