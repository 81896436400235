angular.module('apruve.common.payment_modal').service('payableModelService', payableModelService);

// Make payable_model, i.e. invoice/statement available to the modal controllers decorated with a consistent interface.
function payableModelService() {
  var _payable_model = null;
  var _decorate_fn = null;

  this.set_invoice_model = function(invoice) {
    _payable_model = invoice;

    _decorate_fn = function(invoice) {
      invoice.invoice_ids = [invoice.uuid];
      invoice.statement_uuid = null;
      invoice.id_display = 'Invoice ' + invoice.display_id;
      invoice.type = 'Invoice';
      invoice.go_to_state = function(state) {
        state.go('invoice', {invoice_uuid: invoice.uuid}, {reload: true});
      };
      return invoice;
    };
  };

  this.set_statement_model = function(statement) {
    _payable_model = statement;

    _decorate_fn = function(statement) {
      statement.statement_id = statement.uuid;
      statement.id_display = 'Statement ' + statement.sid;
      statement.type = 'Statement';
      statement.go_to_state = function(state) {
        state.go('statement', {statement_uuid: statement.uuid}, {reload: true})
      };
      return statement;
    };
  };

  this.get_payable_model = function() {
    return _payable_model
  };

  // The promise is not resolved until later, and we cannot extend the resource until after it is resolved
  this.decorate = function(payable_model) {
    return _decorate_fn(payable_model);
  };

  return this;
}