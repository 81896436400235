angular.module('apruve.users.team_member').controller "TeamMemberController", [
  '$state', '$scope', '$stateParams', '$window', 'corporate_account', 'team_member_data', 'TeamMember'
  ($state,   $scope,   $stateParams,   $window,   corporate_account, team_member_data, TeamMember) ->
    $scope.url_params = $stateParams
    $scope.url_params.page = parseInt $scope.url_params.page
    $scope.new_params = angular.copy($scope.url_params)
    $scope.team_members_url = "/accounts/#{$scope.url_params.corporate_account_id}/team_members.json"

    $scope.corporate_account = corporate_account
    $scope.team_member = team_member_data

    $scope.isSubmitting = null
    $scope.result = null
    $scope.submit_options =
      buttonDefaultText: 'Save team member'
      buttonSubmittingText: 'Working...'
      buttonSuccessText: 'Success!'
      buttonErrorText: 'Please enter a valid email address'

    $scope.$watch 'team_member.admin', (newValue, oldValue) =>
      if !oldValue and !!newValue
        $scope.team_member.payer = true

    $scope.$watch 'team_member.payer', (newValue, oldValue) =>
      if !oldValue and !!newValue
        $scope.team_member.buyer = true
      else if !!oldValue and !newValue
        $scope.team_member.admin = false

    $scope.$watch 'team_member.buyer', (newValue, oldValue) =>
      if !!oldValue and !newValue
        $scope.team_member.payer = false

    $scope.load_new_page = (new_value, old_value) =>
      return true if !new_value? or !old_value?
      return true if new_value == old_value
      $state.go '.', new_value, notify: false
      return true if new_value.page != old_value.page
      @url_params = angular.copy(new_value)

    $scope.$watch 'new_params', $scope.load_new_page, true

    @show_team_member = (team_member) ->
      $state.go('team_member', {team_member_id: team_member.uuid}, {reload: true})

    @delete = () =>
      name = $scope.team_member.user.name || $scope.team_member.user.email || 'this user'
      if $window.confirm("Remove " + name + " from this account’s team?")
        $scope.team_member.$destroy ->
          $state.go('account', {corporate_account_id: $scope.team_member.customer_account.uuid}, reload: true)
        , (error) ->
          @result = 'Error removing team member'
          @display_errors = true

    $scope.submit_form = () =>
      $scope.isSubmitting = true
      if $scope.team_member_form.$invalid
        $scope.result = 'error'
        $scope.display_errors = true
        return false

      $scope.team_member.$create({customer_account_id: $scope.corporate_account.uuid}, (result) ->
          result.selected = true
          for tm in $scope.team_members
            delete tm.selected
          if $scope.team_members.length >= 10
            $scope.team_members = [result].concat($scope.team_members.slice(0, -1))
          else
            $scope.team_members = [result].concat $scope.team_members
          $scope.team_member = new TeamMember(buyer: true)
          $scope.isSubmitting = false
          $scope.result = 'success'
          $scope.corporate_account.authorized_buyers_count += 1
        , (response) ->
          if response.data.meta.errors.user?[0] == 'already has an account with this merchant'
            $scope.submit_options.buttonErrorText = 'User already has an account with this merchant.'
          else
            $scope.submit_options.buttonErrorText = 'Invalid email address.'
          $scope.isSubmitting = undefined
          $scope.result = 'error'
          @display_errors = true
      )

    return
]
