import Highcharts from 'highcharts/highmaps'
import highChartsTilemap from 'highcharts/modules/tilemap'
import highChartsHeatmap from 'highcharts/modules/heatmap'

angular.module('apruve.sellers.performance').controller 'PerformanceCustomerController', [
  'Performance', 'chart', '$state', '$scope', '$stateParams', 'currentUserService', 'chartColors'
  (Performance, chart, $state, $scope, $stateParams, currentUserService, chartColors) ->

    highChartsHeatmap(Highcharts)
    highChartsTilemap(Highcharts)
    Highcharts.setOptions
      lang:
        thousandsSep: ','
        noData: 'No new customer accounts within time range'

    $scope.$on 'performance.period.update', (event, period) =>
      Performance.Customers.get({date_range: period.value, merchant_id: $stateParams.merchant_id}).$promise.then (data) =>
        account_series =
          data: data.chart_data
          pointStart: Date.parse(data.start_date)
          pointIntervalUnit: data.point_interval_unit
        @accountChartControl.updateSeries account_series, 0
        @chart_data = data
        @not_enough_data = @chart_data.chart_data.reduce @all_values_zero, true

    @accountChartControl = {}
    @accountChartConfig =
      chart:
        type: 'area'
        backgroundColor: 'transparent'
        height: 400
      credits:
        enabled: false
      exporting:
        enabled: false
      navigator:
        enabled: false
      rangeSelector:
        enabled: false
      scrollbar:
        enabled: false
      title:
        text: 'Number of Accounts'
      navigation:
        buttonOptions:
          theme:
            states:
              hover:
                fill: '#fff'
              select:
                fill: '#f7f8fa'
      menuItemStyle:
        fontWeight: 'normal'
        background: 'none'
      menuItemHoverStyle:
        fontWeight: 'bold'
        background: '#1784B0'
        color: '#fff'
      xAxis:
        crosshair: true
        type: 'datetime'
        dateTimeLabelFormats:
          day: '%b %e'
          week: '%b %e'
          month: '%b, %Y'
          year: '%Y'
      yAxis:
        min: 0
        opposite:  false
        allowDecimals: false
      tooltip:
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>'
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>'
        footerFormat: '</table>'
        shared: true,
        useHTML: true
      plotOptions:
        series:
          fillOpacity: 0.1
          lineWidth: 3
          marker:
            enabled: false
          dataLabels:
            enabled: false
          label:
            enabled: false

      series: [{
        name: 'Total Accounts'
        color: chartColors.primary # fill color
        pointStart: Date.parse(chart.start_date)
        pointIntervalUnit: chart.point_interval_unit
        data: chart.chart_data
      }]


    @map_data = =>
      return @list_of_states if @list_of_states
      @list_of_states =
        [
          {
            "hc-a2":"AL",
            "name":"Alabama",
            "region":"South",
            "x":6,
            "y":7,
            "value":0
          },
          {
            "hc-a2":"AK",
            "name":"Alaska",
            "region":"West",
            "x":0,
            "y":0,
            "value":0
          },
          {
            "hc-a2":"AZ",
            "name":"Arizona",
            "region":"West",
            "x":5,
            "y":3,
            "value":0
          },
          {
            "hc-a2":"AR",
            "name":"Arkansas",
            "region":"South",
            "x":5,
            "y":6,
            "value":0
          },
          {
            "hc-a2":"CA",
            "name":"California",
            "region":"West",
            "x":5,
            "y":2,
            "value":0
          },
          {
            "hc-a2":"CO",
            "name":"Colorado",
            "region":"West",
            "x":4,
            "y":3,
            "value":0
          },
          {
            "hc-a2":"CT",
            "name":"Connecticut",
            "region":"Northeast",
            "x":3,
            "y":11,
            "value":0
          },
          {
            "hc-a2":"DE",
            "name":"Delaware",
            "region":"South",
            "x":4,
            "y":9,
            "value":0
          },
          {
            "hc-a2":"DC",
            "name":"District of Columbia",
            "region":"South",
            "x":4,
            "y":10,
            "value":0
          },
          {
            "hc-a2":"FL",
            "name":"Florida",
            "region":"South",
            "x":8,
            "y":8,
            "value":0
          },
          {
            "hc-a2":"GA",
            "name":"Georgia",
            "region":"South",
            "x":7,
            "y":8,
            "value":0
          },
          {
            "hc-a2":"HI",
            "name":"Hawaii",
            "region":"West",
            "x":8,
            "y":0,
            "value":0
          },
          {
            "hc-a2":"ID",
            "name":"Idaho",
            "region":"West",
            "x":3,
            "y":2,
            "value":0
          },
          {
            "hc-a2":"IL",
            "name":"Illinois",
            "region":"Midwest",
            "x":3,
            "y":6,
            "value":0
          },
          {
            "hc-a2":"IN",
            "name":"Indiana",
            "region":"Midwest",
            "x":3,
            "y":7,
            "value":0
          },
          {
            "hc-a2":"IA",
            "name":"Iowa",
            "region":"Midwest",
            "x":3,
            "y":5,
            "value":0
          },
          {
            "hc-a2":"KS",
            "name":"Kansas",
            "region":"Midwest",
            "x":5,
            "y":5,
            "value":0
          },
          {
            "hc-a2":"KY",
            "name":"Kentucky",
            "region":"South",
            "x":4,
            "y":6,
            "value":0
          },
          {
            "hc-a2":"LA",
            "name":"Louisiana",
            "region":"South",
            "x":6,
            "y":5,
            "value":0
          },
          {
            "hc-a2":"ME",
            "name":"Maine",
            "region":"Northeast",
            "x":0,
            "y":11,
            "value":0
          },
          {
            "hc-a2":"MD",
            "name":"Maryland",
            "region":"South",
            "x":4,
            "y":8,
            "value":0
          },
          {
            "hc-a2":"MA",
            "name":"Massachusetts",
            "region":"Northeast",
            "x":2,
            "y":10,
            "value":0
          },
          {
            "hc-a2":"MI",
            "name":"Michigan",
            "region":"Midwest",
            "x":2,
            "y":7,
            "value":0
          },
          {
            "hc-a2":"MN",
            "name":"Minnesota",
            "region":"Midwest",
            "x":2,
            "y":4,
            "value":0
          },
          {
            "hc-a2":"MS",
            "name":"Mississippi",
            "region":"South",
            "x":6,
            "y":6,
            "value":0
          },
          {
            "hc-a2":"MO",
            "name":"Missouri",
            "region":"Midwest",
            "x":4,
            "y":5,
            "value":0
          },
          {
            "hc-a2":"MT",
            "name":"Montana",
            "region":"West",
            "x":2,
            "y":2,
            "value":0
          },
          {
            "hc-a2":"NE",
            "name":"Nebraska",
            "region":"Midwest",
            "x":4,
            "y":4,
            "value":0
          },
          {
            "hc-a2":"NV",
            "name":"Nevada",
            "region":"West",
            "x":4,
            "y":2,
            "value":0
          },
          {
            "hc-a2":"NH",
            "name":"New Hampshire",
            "region":"Northeast",
            "x":1,
            "y":11,
            "value":0
          },
          {
            "hc-a2":"NJ",
            "name":"New Jersey",
            "region":"Northeast",
            "x":3,
            "y":10,
            "value":0
          },
          {
            "hc-a2":"NM",
            "name":"New Mexico",
            "region":"West",
            "x":6,
            "y":3,
            "value":0
          },
          {
            "hc-a2":"NY",
            "name":"New York",
            "region":"Northeast",
            "x":2,
            "y":9,
            "value":0
          },
          {
            "hc-a2":"NC",
            "name":"North Carolina",
            "region":"South",
            "x":5,
            "y":9,
            "value":0
          },
          {
            "hc-a2":"ND",
            "name":"North Dakota",
            "region":"Midwest",
            "x":2,
            "y":3,
            "value":0
          },
          {
            "hc-a2":"OH",
            "name":"Ohio",
            "region":"Midwest",
            "x":3,
            "y":8,
            "value":0
          },
          {
            "hc-a2":"OK",
            "name":"Oklahoma",
            "region":"South",
            "x":6,
            "y":4,
            "value":0
          },
          {
            "hc-a2":"OR",
            "name":"Oregon",
            "region":"West",
            "x":4,
            "y":1,
            "value":0
          },
          {
            "hc-a2":"PA",
            "name":"Pennsylvania",
            "region":"Northeast",
            "x":3,
            "y":9,
            "value":0
          },
          {
            "hc-a2":"RI",
            "name":"Rhode Island",
            "region":"Northeast",
            "x":2,
            "y":11,
            "value":0
          },
          {
            "hc-a2":"SC",
            "name":"South Carolina",
            "region":"South",
            "x":6,
            "y":8,
            "value":0
          },
          {
            "hc-a2":"SD",
            "name":"South Dakota",
            "region":"Midwest",
            "x":3,
            "y":4,
            "value":0
          },
          {
            "hc-a2":"TN",
            "name":"Tennessee",
            "region":"South",
            "x":5,
            "y":7,
            "value":0
          },
          {
            "hc-a2":"TX",
            "name":"Texas",
            "region":"South",
            "x":7,
            "y":4,
            "value":0
          },
          {
            "hc-a2":"UT",
            "name":"Utah",
            "region":"West",
            "x":5,
            "y":4,
            "value":0
          },
          {
            "hc-a2":"VT",
            "name":"Vermont",
            "region":"Northeast",
            "x":1,
            "y":10,
            "value":0
          },
          {
            "hc-a2":"VA",
            "name":"Virginia",
            "region":"South",
            "x":5,
            "y":8,
            "value":0
          },
          {
            "hc-a2":"WA",
            "name":"Washington",
            "region":"West",
            "x":2,
            "y":1,
            "value":0
          },
          {
            "hc-a2":"WV",
            "name":"West Virginia",
            "region":"South",
            "x":4,
            "y":7,
            "value":0
          },
          {
            "hc-a2":"WI",
            "name":"Wisconsin",
            "region":"Midwest",
            "x":2,
            "y":5,
            "value":0
          },
          {
            "hc-a2":"WY",
            "name":"Wyoming",
            "region":"West",
            "x":3,
            "y":3,
            "value":0
          }
        ]
      @list_of_states = @list_of_states.sort (a, b) ->
        a['x'] - b['x']
      @list_of_states

    @map_states = (state_data) =>
      location_data = @map_data()
      for state, value of state_data
        i = location_data.findIndex (el) -> el['name'] == state
        location_data[i]['value'] = state_data[state] unless i == -1
      location_data

    @mapChartControl = {}
    @mapChartConfig =
      chart:
        type: 'tilemap'
        inverted: true
        height: '80%'
      credits:
        enabled: false
      exporting:
        enabled: false
      navigator:
        enabled: false
      rangeSelector:
        enabled: false
      scrollbar:
        enabled: false
      legend:
        enabled: true
      title:
        text: ''
      subtitle:
        text: ''
      xAxis:
        visible: false
        crosshair: false
      yAxis:
        visible: false
        crosshair: false
        state:
          hover:
            enabled: false
      colorAxis:
        dataClasses: [{
          from: 0
          to: 1
          color: '#e1e1e1'
          name: '0'
        }, {
          from: 1
          to: 1000000
          color: chartColors.primaryLight # Light blue
          name: '1-50'
        }, {
          from: 1000000
          to: 5000000
          color: chartColors.secondaryLight # light green
          name: '51-100'
        }, {
          from: 5000000,
          to: 2000000
          color: chartColors.secondary # dark green
          name: '101-200'
        }, {
          from: 20000000
          color: chartColors.primary # Dark blue
          name: '> 200'
        }]
      navigation:
        buttonOptions:
          theme:
            states:
              hover:
                fill: '#fff'
              select:
                fill: '#f7f8fa'
        menuItemStyle:
          fontWeight: 'normal'
          background: 'none'
        menuItemHoverStyle:
          fontWeight: 'bold'
          background: '#1784B0'
          color: '#fff'
      tooltip:
        headerFormat: ''
        pointFormat: '<b>{point.name}</b>: {point.value}'
      plotOptions:
        series:
          states:
            hover:
              enabled: true
              halo:
                size: 0
          dataLabels:
            enabled: true
            format: '{point.hc-a2}'
            color: '#000000'
            style:
              textOutline: false
      series: [{
        name: ''
        data: @map_states chart.customers_by_location
      }]

    @all_values_zero = (accumulator, currentValue) -> accumulator && (currentValue == 0)
    @chart_data = chart
    @industries = Object.keys chart.total_orders_by_industry
    @customer_locations = Object.keys chart.customers_by_location

    @not_enough_data = chart.chart_data.reduce @all_values_zero, true
    @not_enough_map_data = @map_states(chart.customers_by_location).map (state) ->
      state['value']
    .reduce @all_values_zero, true

    return
]
