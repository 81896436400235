angular.module 'apruve.common.active_state_workaround', [
  'ui.router'
]
.run [
  '$rootScope', '$state', 
  ($rootScope, $state) ->
    $rootScope.states = {}
    updateStates = () ->
      angular.forEach $state.get(), (state) ->
        $rootScope.states[state.name] = $state.is state.name
    updateStates()
    $rootScope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      updateStates()
]
# Ok.  So.  This is a dirty dirty hack to work around issues with ui-sref-active and ui-bootstrap's active directive.
# This github issue should solve the issue once it's closed: https://github.com/angular-ui/ui-router/issues/1431
# Remove this workaround and change any ui-bootstrap active directives in this way:
#   active: 'states["purchase_order"]' ...becomes... 'ui-sref-active-eq' => 'active'