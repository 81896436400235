angular.module('apruve.users.app').controller 'MstsTosController',[
  '$scope', '$uibModalInstance', 'TeamMember', 'current_user'
  ($scope, $uibModalInstance, TeamMember, current_user) ->
    $scope.msts_tos_accepted = false
    $scope.result = null
    $scope.isSubmitting = null
    $scope.current_user = current_user
    $scope.team_member_to_migrate = new TeamMember({uuid: current_user.impacted_team_member_uuid})
    $scope.submit_tos_form = () ->
      if !$scope.msts_tos_accepted
        $scope.error_msg = 'You must accept the terms of Service to continue.'
        return
      else
        $scope.isSubmitting = true
        $scope.team_member_to_migrate.$update_terms()
        $scope.error_msg = null
        $scope.result = 'success'
        $uibModalInstance.close $scope.team_member_to_migrate
        return

    $scope.submit_options =
      buttonDefaultText: 'Continue'
      buttonSubmittingText: 'Accepting...'
      buttonErrorText: 'There was a problem accepting the terms of service. Please contact support.'
      buttonSuccessText: 'Accepted'
      buttonDefaultClass: 'btn-success'
      buttonSubmittingClass: 'btn-success'
      buttonSuccessClass: 'btn-success'

    return
]