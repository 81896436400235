angular.module('apruve.sellers.statement').controller 'StatementController', [
  '$scope', '$stateParams', '$state', '$uibModal', 'statusIconsService', 'statement_data',
  ($scope, $stateParams, $state, $uibModal, statusIconsService, statement_data) ->
    @url_params = $stateParams
    @url_params.page = parseInt @url_params.page
    @new_params = angular.copy(@url_params)
    @url = "/sellers/#{$stateParams.merchant_id}/invoices.json"

    @statusIconsService = statusIconsService
    @statement = statement_data

    @navigate_to_invoice = (invoice) ->
      $state.go('sellers.invoice', invoice_uuid: invoice.uuid)

    @status_explanation_for = (statement) ->
      switch statement.status
        when 'open' then "This billing period is currently open and collecting statements."
        when 'issued'     then "This statement will collect no more invoices, and is ready for payment."
        when 'closed'  then "This statement has been settled."
        else ''

    return
]
