angular.module('apruve.sellers.billing_invoices').controller 'BillingPaymentModalController', [
  '$uibModalInstance', 'billing_invoice_data', '$stateParams', 'BillingPayment'
  ($uibModalInstance, billing_invoice_data, $stateParams, BillingPayment) ->
    @billing_invoice = billing_invoice_data
    @payment_method = billing_invoice_data.payment_method
    @billing_payment = new BillingPayment(billing_invoice_uuid: billing_invoice_data.uuid, merchant_id: $stateParams.merchant_id)
    @is_submitting = false

    @pay_billing_invoice = =>
      @is_submitting = true
      @billing_payment.$save (p) =>
        @result = 'success'
        $uibModalInstance.close p
      , (p) =>
        @result = 'error'
        console.error 'Something went wrong.'

    @cancel = () ->
      $uibModalInstance.dismiss 'canceled'

    @payment_options =
      buttonDefaultText: 'Pay'
      buttonSubmittingText: 'Submitting Payment...'
      buttonErrorText: 'There was a problem paying this invoice. Please contact support.'
      buttonSuccessText: 'Success'
      buttonDefaultClass: 'btn-success'
      buttonSubmittingClass: 'btn-success'
      buttonSuccessClass: 'btn-success'

    return
]