import 'ng-file-upload'

angular.module('apruve.sellers.payment').controller('UploadPaymentsController', ['$scope', '$state', '$stateParams', '$http', UploadPaymentsController]);
function UploadPaymentsController($scope, $state, $stateParams, $http) {
  this.url_params = $stateParams;
  this.url_params.page = parseInt(this.url_params.page);
  this.new_params = angular.copy(this.url_params);
  this.merchant_id = $stateParams.merchant_id;
  this.upload_url = '/sellers/'+this.merchant_id +'/payments/upload_csv';
  this.display_errors = false;

  $scope.reset_form = function(){
    this.display_errors = false;
  };
  this.uploadCsv = function() {
    var fd = new FormData();
    fd.append('attachment', this.form.csvFile['$modelValue']);

    $http.post(this.upload_url, fd, {
      withCredentials: false,
      headers: {
        'Content-Type': undefined
      },
      transformRequest: angular.identity,
      responseType: "arraybuffer"
    })
        .then(function(response) {
          $state.go('sellers.payments', {}, {reload: true});
        })
        .catch(function(error) {
          console.log(error.status);
        });
  };
}
