angular.module('apruve.sellers.subscription').controller "SubscriptionController", ['$scope', '$routeParams', '$location', 'Subscription', ($scope, $routeParams, $location, Subscription) ->
  $scope.load_subscription = ->
    Subscription.get merchant_id: $routeParams.merchant_id, purchase_order_id: $routeParams.purchase_order_id, id: $routeParams.subscription_id, (subscription) ->
      $scope.subscriptionDetail = subscription
      $scope.paymentsVisible = false
      $scope.subscriptionsVisible = false

  $scope.show_request = ->
    $location.path('/sellers/'+$routeParams.merchant_id+'/purchase_orders/'+$routeParams.purchase_order_id)

  $scope.load_subscription()

]