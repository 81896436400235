angular.module('apruve.users.getting_started').controller "GettingStartedTeamController", [
  '$state', '$scope', '$location', '$stateParams', 'TeamMember'
  ($state, $scope, $location, $stateParams, TeamMember) ->
    @submitting = null
    @team_members = []
    @submit_options =
      buttonDefaultText: 'Add Member'
      buttonSubmittingText: 'Working...'
      buttonSuccessText: 'Success!'
      buttonErrorText: 'Please enter a valid email address'

    $scope.new_team_member = new TeamMember(buyer: true)

    $scope.$watch 'new_team_member.admin', (newValue, oldValue) =>
      if !oldValue and !!newValue
        $scope.new_team_member.payer = true

    $scope.$watch 'new_team_member.payer', (newValue, oldValue) =>
      if !oldValue and !!newValue
        $scope.new_team_member.buyer = true
      else if !!oldValue and !newValue
        $scope.new_team_member.admin = false

    $scope.$watch 'new_team_member.buyer', (newValue, oldValue) =>
      if !!oldValue and !newValue
        $scope.new_team_member.payer = false

    @add_member = () =>
      @submitting = true
      if @team_member_form.$invalid
        @result = 'error'
        return false

      $scope.new_team_member.$create({customer_account_id: $stateParams.corporate_account_id}, (result) =>
        @submitting = false
        @result = 'success'
        $scope.new_team_member = new TeamMember(buyer: true)
        @team_members = [result].concat @team_members
      , (response) =>
        @submitting = false
        @result = 'error'
      )

    return
]