angular.module('apruve.sellers.credit_memo').controller 'CreditMemoInvoicesController', [
  '$state', '$stateParams', 'credit_memo_data',
  ($state, $stateParams, credit_memo_data) ->
    @url_params = $stateParams
    @url_params.page = parseInt @url_params.page
    @new_params = angular.copy(@url_params)
    @url = "/sellers/#{$stateParams.merchant_id}/invoices.json"
    @merchant_id = $stateParams.merchant_id
    @credit_memo = credit_memo_data

    @navigate_to_invoice = (invoice) ->
      $state.go('sellers.invoice', invoice_uuid: invoice.uuid, merchant_id: @credit_memo.merchant_id)

    @applied_amount_for_invoice = (invoice) ->
      index = @credit_memo.invoice_payments.findIndex (invoice_payment) -> invoice_payment.invoice_uuid == invoice.uuid
      @credit_memo.invoice_payments[index].applied_amount_display

    return
]