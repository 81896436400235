angular.module('apruve.sellers.credit_memo').controller "CreditMemosController", [
  '$scope', '$state', '$stateParams', 'merchant', 'statusIconsService',
  ($scope, $state, $stateParams, merchant, statusIconsService) ->
    @statusIconsService = statusIconsService
    @url_params = $stateParams
    @url_params.page = parseInt @url_params.page
    @new_params = angular.copy(@url_params)
    @search_field = @new_params.search
    @url = "/sellers/#{$stateParams.merchant_id}/credit_memos.json"
    @merchant = merchant

    @navigate_to_credit_memo = (credit_memo) ->
      $state.go('sellers.credit_memo', credit_memo_uuid: credit_memo.uuid, merchant_id: $stateParams.merchant_id)

    @search = =>
      @new_params.page = 0
      @new_params.search = @search_field

    @reset_search = =>
      @search_field = ''
      @search()

    @resort = (clicked_on_col) ->
      if @new_params.sort_column != clicked_on_col
        @new_params.sort_column = clicked_on_col
        @new_params.sort_order = 'desc'
      else
        if @new_params.sort_order == 'desc'
          @new_params.sort_order = 'asc'
        else
          @new_params.sort_order = 'desc'

    @sort_classes_for = (column) ->
      if column == @new_params.sort_column
        ['apruve-icon-angle', @new_params.sort_order]
      else
        []

    @row_classes_for = (credit_memo) ->
      classes = []
      classes.push 'list-item-done'
      classes

    @load_new_page = (new_value, old_value) =>
      return true if !new_value? or !old_value?
      return true if new_value == old_value
      $state.go '.', new_value, notify: false
      return true if new_value.page != old_value.page
      @url_params = angular.copy(new_value)

    $scope.$watch "creditMemosCtrl.new_params", @load_new_page, true

    return

]