angular.module('apruve.sellers.performance').factory('Performance', ['$resource', function($resource) {
  return {
    Invoices: $resource('/sellers/:merchant_id/performance/invoices.json',
      {merchant_id: '@merchant_id'}),
    Credit: $resource('/sellers/:merchant_id/performance/credit.json',
      {merchant_id: '@merchant_id'}),
    Customers: $resource('/sellers/:merchant_id/performance/customers.json',
      {merchant_id: '@merchant_id'}),
    AgingReport: $resource('/sellers/:merchant_id/performance/aging_report.json',
      {merchant_id: '@merchant_id'})
  };
}
]);
