import {uniq, map, filter } from 'lodash';

angular.module('apruve.sellers.corporate_account.new_account').controller('NewAccountController', [
  '$scope', '$state', 'SellersCorporateAccount', 'merchant',
  function ($scope, $state, SellersCorporateAccount, merchant) {
    $scope.submitting = false;
    $scope.result = null;
    $scope.corporate_account = new SellersCorporateAccount({merchant: {id: $state.params.merchant_id}});
    $scope.available_terms = merchant.merchant_term.payment_term_strategy_names;

    const choices = formChoices(merchant.merchant_terms); // Every triple that could exist

    $scope.validChoices = () => {
      const isValid = choice => {
        const country_code_ok = ($scope.corporate_account.country_code ?? choice.country) === choice.country;
        const currency_ok = ($scope.corporate_account.currency ?? choice.currency) === choice.currency;
        const term_ok = ($scope.corporate_account.credit_terms ?? choice.payment_term) === choice.payment_term;
        return country_code_ok && currency_ok && term_ok;
      };
      return filter(choices, isValid);
    };

    const choices_for_attr_factory = (choice_attr, acct_attr) => {
      return () => {
        if (!$scope.corporate_account[acct_attr]) { // If set, we want to be able to make other choices
          return uniq(map($scope.validChoices(), choice_attr))
        } else {
          return uniq(map(choices, choice_attr))
        }
      };
    };

    $scope.validCurrencies = choices_for_attr_factory('currency', 'currency');
    $scope.validCountries = choices_for_attr_factory('country', 'country_code');
    $scope.validTerms = choices_for_attr_factory('payment_term', 'credit_terms');

    $scope.invalidateAccount = (field) => {
      // For each option that can constrain the list and isn't the latest change,
      // unset that option if there's no valid choice, then continue if that doesn't fix the problem.
      if ($scope.validChoices().length > 0) {
        return;
      }
      if (field !== 'currency') {
        $scope.corporate_account.currency = undefined;
        if ($scope.validChoices().length > 0) { return; }
      }
      if (field !== 'country') {
        $scope.corporate_account.country_code = undefined;
        if ($scope.validChoices().length > 0) { return; }
      }
      if (field !== 'payment_term') {
        $scope.corporate_account.credit_terms = undefined;
      }
    };

    $scope.create_options = {
      buttonDefaultText: 'Create Account',
      buttonSubmittingText: 'Creating...',
      buttonSuccessText: 'Created',
      buttonDefaultClass: 'actionBar-btn-primary',
      buttonSubmittingClass: 'actionBar-btn-primary',
      buttonSuccessClass: 'actionBar-btn-primary',
      buttonErrorClass: 'actionBar-btn-danger'
    };

    $scope.filterValue = function ($event) {
      if (isNaN(String.fromCharCode($event.keyCode))) {
        $event.preventDefault();
      }
    };

    $scope.create_account = function () {
      $scope.submitting = true;
      $scope.corporate_account.$create(() => {
            $scope.result = 'success';
            $state.go('sellers.corporate_accounts', {}, {reload: true});
          }
          , error => {
            $scope.create_options.buttonErrorText = 'Invalid admin email.';
            $scope.result = 'error';
            console.error("Error while creating account");
          });
    };
  }
]);

function formChoices(creditor_terms) {
  const choices = [];
  for (const term of creditor_terms) {
    for(const supported_country of term.supported_countries) {
      for(const allowed_currency of supported_country.allowed_country_currencies) {
        // For each of these <supported_country, currency> pairs, we also support all payment terms of the account
        for(const payment_term of term.payment_term_strategy_names) {
          choices.push({country: supported_country.country_code, currency: allowed_currency.currency, payment_term })
        }
      }
    }
  }
  return choices;
}

