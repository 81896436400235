import { en } from 'translations/users/en.yml';
import { zh } from 'translations/users/zh.yml';
import { ru } from 'translations/users/ru.yml';

angular.module('apruve.users.app')
    .config(['$translateProvider',
      function ($translateProvider) {
        // Stop XSS attacks
        $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
        // make messageformat interpolation available but not the default
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        $translateProvider.translations('en', en);
        $translateProvider.translations('zh', zh);
        $translateProvider.translations('ru', ru);

        $translateProvider.preferredLanguage('en');
      }
    ])
    .run(['$rootScope', '$translate', 'currentUserService', async ($rootScope, $translate, currentUserService) => {
      function setLanguageKey(langKey) {
          $translate.use(langKey);
      }

      $rootScope.$on('language.change', (event, args) => setLanguageKey(args.locale));

      const user = await currentUserService.user.$promise;
      // We will put that back when we finish the translation work in application
      // setLanguageKey(user.locale);
      if (user.locale == null) {
        setLanguageKey('en');
      }
      else {
        setLanguageKey(user.locale);
      }
    }]);
