angular.module('apruve.sellers.performance').controller('PerformanceCreditController', [
  'data', '$state', '$scope', '$stateParams', 'currentUserService', 'chartColors',
  function(data, $state, $scope, $stateParams, currentUserService, chartColors) {
    this.donutChartData = data.donut_chart_data;

    // Approve / Decline percentages by type
    const approveDeclinePercentages = data.approve_decline_percentages_by_type;
    this.approvePercentagesByType = Object.keys(approveDeclinePercentages)
      .map(type => ({
      type,
      approve: approveDeclinePercentages[type].approve
    }))
      .filter(tAndA => tAndA.approve > 0)
      .sort((a, b) => b.approve - a.approve);

    this.declinePercentagesByType = Object.keys(approveDeclinePercentages)
      .map(type => ({
      type,
      decline: approveDeclinePercentages[type].decline
    }))
      .filter(tAndD => tAndD.decline > 0)
      .sort((a, b) => b.decline - a.decline);

    // Years in business statistics
    this.yearsInBusinessStatistics = data.years_in_business_statistics;

    this.numberOfEmployeesStatistics = data.number_employees_statistics;

    const industryStatistics = data.industry_statistics;
    this.approvePercentagesByIndustry = Object.keys(industryStatistics)
      .map(industry => ({
      industry,
      approve: industryStatistics[industry].approve
    }))
      .sort((a, b) => b.approve - a.approve);

    this.declinePercentagesByIndustry = Object.keys(industryStatistics)
      .map(industry => ({
      industry,
      decline: industryStatistics[industry].decline
    }))
      .sort((a, b) => b.decline - a.decline);

    // Not enough data
    this.allValuesNull = (accumulator, currentValue) => accumulator && (currentValue === null);

    this.donutChartNoData = (this.donutChartData.num_apps_approved === 0) && (this.donutChartData.num_apps_declined === 0);
    this.scatterPlotNoData = (data.scatter_plot_data.declines.length === 0) && (data.scatter_plot_data.approvals.length === 0);

    this.yearsInBusinessNoData = Object.keys(this.yearsInBusinessStatistics)
      .map(stat_key => this.yearsInBusinessStatistics[stat_key])
      .reduce(this.allValuesNull, true);
    this.numberOfEmployeesNoData = Object.keys(this.numberOfEmployeesStatistics)
      .map(stat_key => this.numberOfEmployeesStatistics[stat_key])
      .reduce(this.allValuesNull, true);

    this.businessTypesNoData = Object.keys(data.approve_decline_percentages_by_type).length === 0;
    this.industryStatsNoData = Object.keys(data.industry_statistics).length === 0;

    this.donutChartControl = {}; // Will be filled by donut chart directive

    this.donutChartOptions = {
      chart: {
        type: 'pie',
        height: '160',
        width: '160'
      },
      colors: [chartColors.primary, chartColors.secondary],
      title: {
        text: ''
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      plotOptions: {
        pie: {
          shadow: false
        }
      },
      tooltip: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Test',
        data: [
          ['Approvals', this.donutChartData.num_apps_approved], // TODO: decide what to do when approved == declined == 0. The chart gets weird
          ['Declines', this.donutChartData.num_apps_declined]
        ],
        size: '150px',
        innerSize: '70%',
        showInLegend: false,
        dataLabels: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        }
      }]
    };

    this.scatterPlotControl = {};

    this.scatterPlotOptions = {
      chart: {
        type: 'scatter',
        zoomType: 'xy',
        marginRight: 120,
        height: '40%'
      },
      title: { text: ''
    },
      subtitle: { text: ''
    },
      xAxis: {
        title: {
          enabled: true,
          text: 'Years in Business',
          align: 'middle',
          margin: 15
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        min: 0
      },
      yAxis: {
        title: {
          enabled: true,
          text: '# of Employees',
          align: 'middle'
        },
        min: 0
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: 7,
        floating: true,
        itemStyle: { fontWeight: 'normal'
      }
      },
      exporting: { enabled: false
    },
      navigation: {
        buttonOptions: { theme: { states: {
          hover: { fill: '#fff'
        },
          select: { fill: '#f7f8fa'
        }
        }
      }
      },
        menuItemStyle: {
          fontWeight: 'normal',
          background: 'none'
        },
        menuItemHoverStyle: {
          fontWeight: 'bold',
          background: '#1784B0',
          color: '#fff'
        }
      },
      plotOptions: { scatter: {
        marker: {
          radius: 5,
          states: { hover: {enabled: true}
        }
        },
        states: { hover: {marker: {enabled: false}}
      },
        tooltip: {
          pointFormat: '{point.x} Years in Business, {point.y} Employees'
        }
      }
    },
      series: [
        {
          name: 'Approvals',
          color: chartColors.applyTransparency(chartColors.primary, 0.7),
          data: data.scatter_plot_data.approvals
        },
        {
          name: 'Declines',
          color: chartColors.applyTransparency(chartColors.secondary, 0.7),
          data: data.scatter_plot_data.declines
        }
      ]
    };

  }
]);