angular.module('apruve.users.purchase_order').controller "PurchaseOrderController", [
  '$scope', '$state', 'purchase_order_data',
  ($scope, $state, purchase_order_data) ->
    $scope.purchase_order = purchase_order_data
    $scope.quote = $scope.purchase_order.quote
    $scope.merchant = $scope.purchase_order.merchant
    $scope.shopper = $scope.purchase_order.shopper

    $scope.sent_message = ->
      $state.go('purchase_order')
      
    $scope.can_update_po_number = ->
      $scope.purchase_order.can_update_po

    $scope.current_user = window.current_user
]