angular.module('apruve.sellers.team_admin')
    .directive('emailExampleModalLink',
        ['$uibModal', 'images', ($uibModal, images) =>
  ({
    restrict: 'A',
    scope: {
      modalTemplate: '='
    }, // function to return template for the modal

    link(scope, elem, attrs) {
      scope.images = images;
      
      elem.on('click', () => scope.open_modal());

      scope.close_modal = () => scope.emailExampleModal.dismiss();

      scope.open_modal = function() {
        const templateUrl = scope.modalTemplate();
        scope.emailExampleModal = $uibModal.open({
          ariaLabelledBy: 'emailExampleModal',
          templateUrl,
          scope,
          windowClass: 'fade v2'
        });
      };

    }
  })

]);