angular.module('apruve.users.corporate_account').controller "CreditLimitChangeRequestController", [
  'CorporateAccount', 'CreditLimitChangeRequest', '$state', 'corporate_account_data'
  (CorporateAccount, CreditLimitChangeRequest, $state, corporate_account_data) ->

    @corporate_account_uuid = corporate_account_data.uuid
    @corporate_account = corporate_account_data
    @credit_limit_change_request = new CreditLimitChangeRequest(corporate_account_id: @corporate_account_uuid)
    @submitting = null
    @result = null
    @display_errors = false
    @submit_options =
      buttonDefaultText: 'Request Change'
      buttonSubmittingText: 'Saving...'
      buttonSuccessText: 'Saved'
    @redirect_to_overdue_invoices = =>
      $state.go('invoices')

    @has_overdue_invoices = =>
      @corporate_account.overdue_invoices_count > 0

    @submit_form = () =>
      @submitting = true
      if @change_request_form.$invalid
        @result = 'error'
        @display_errors = true
        return false
      @display_errors = false
      @credit_limit_change_request.$save (result) ->
        @result = 'success'
        $state.go('.success')
      , (response) =>
        @result = 'error'
        @display_errors = true
        @credit_limit_change_request = response.data
        console.error(response.data)

      return

    return
]