export function passwordStrengthValidator() {
    document.addEventListener("turbolinks:load", function() {
        var strength = {
            0: "",
            1: "Too Weak",
            2: "Good"
        }
        var meter = document.getElementById('password-strength-meter');
        if (meter) {
            var text = document.getElementById('password-strength-text');
            var submit = document.getElementById('submit')
            var password = document.getElementById('user_password');
            var confirm_password = document.getElementById('user_password_confirmation');
            password.addEventListener('input', function () {
                var val = password.value;
                $.ajax({
                    type: "POST",
                    data: {password: val},
                    url: "/users/password_strength",
                    datatype: 'json',
                    success: function (data) {
                        meter.value = data.strength ? 2 : 1
                        if (val !== "") {
                            text.innerHTML = "Strength: " + strength[meter.value];
                            if (meter.value === 2 && calculateEqual()) {
                                submit.disabled = false;
                            }
                            else {
                                submit.disabled = true;
                            }
                        } else {
                            meter.value = 0
                            text.innerHTML = "";
                            submit.disabled = true;
                        }
                    },
                    error: function (xhr, textStatus, error) {
                        console.log(xhr.statusText);
                        console.log(textStatus);
                        console.log(error);
                    }
                });
            });

            confirm_password.addEventListener('input', function () {
                if (meter.value === 2 && calculateEqual()) {
                    submit.disabled = false;
                }
                else {
                    submit.disabled = true;
                }
            });

            function calculateEqual(){
                return password.value === confirm_password.value;
            }
        }
    });
}

