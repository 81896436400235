angular.module('apruve.sellers.bank_account').factory 'Merchant', ['$resource', ($resource) ->
  Form: $resource '/sellers/:merchant_id/payout_account.json', # GET for this goes to MerchantsController.show. routes.rb is whack
    {merchant_id: '@merchant_id'}
    {
      update:  {method: 'PUT'}
    }

  PayoutAccount: $resource '/sellers/:merchant_id/payouts/bank_account.json',
    {merchant_id: '@merchant_id'}
    {
      update:  {method: 'PATCH'}
    }
]