angular.module('apruve.sellers.team_admin').controller('SellersEditTeamAdminController', [
  '$state', '$stateParams', 'team_admin', 'currentUserService', 'roles',
  function($state, $stateParams, team_admin, currentUserService, roles) {
    this.team_admin = team_admin;
    this.roles = roles; // {id: role internal name, display_name: external name}
    this.owner_email = currentUserService.currentContext.owner_email;

    this.is_owner = function(account_admin) {
      return account_admin.email === this.owner_email;
    };

    this.is_current_user_super_admin = () => {
        return !!_(currentUserService.user.merchant_roles).find((role) => role.id == 'merchant_super_admin');
    };

    this.get_role_name = (account_admin) => {
      if (account_admin.role) {
        return account_admin.role.display_name
      } else {
        return 'Admin'
      }
    };


    this.delete_modal_template = () => require('../../../../templates/sellers/account_administrators/delete_admin_modal.html');
    this.order_approval_example_template = () => require('../../../../templates/sellers/account_administrators/notification_email_example_modals/order_approval_email_modal.html');
    this.funding_report_example_template = () => require('../../../../templates/sellers/account_administrators/notification_email_example_modals/funding_report_email_modal.html');
    this.credit_application_approval_example_template = () => require('../../../../templates/sellers/account_administrators/notification_email_example_modals/credit_application_approval_email_modal.html');
    this.webhook_failure_example_template = () => require('../../../../templates/sellers/account_administrators/notification_email_example_modals/webhook_failure_email_modal.html');
    this.billing_events_example_template = () => require('../../../../templates/sellers/account_administrators/notification_email_example_modals/billing_event_email_modal.html');
    this.invoice_update_example_template = () => require('../../../../templates/sellers/account_administrators/notification_email_example_modals/invoice_update_email_modal.html');

    this.delete = () => {
      return this.team_admin.$destroy(
          {merchant_id: $stateParams.merchant_id, account_admin_id: $stateParams.account_admin_id},
              result => $state.go('sellers.account_administrators'),
              response => console.error(response.data)
      );
    };

    this.update = () => {
      return this.team_admin.$update({merchant_id: $stateParams.merchant_id, account_admin_id: $stateParams.account_admin_id});
    };

  }
]);