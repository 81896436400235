import moment from 'moment';

angular.module('apruve.common.moment').filter('calendar', calendarFilter);

function calendarFilter() {
  return function(input) {
    if(isUndefinedOrNull(input)) {
      return '';
    }
    const date = moment(input);
    return date.isValid() ? date.format('MM/DD/YYYY') : '';
  }
}

function isUndefinedOrNull(value) {
  return value === null || typeof value === 'undefined';
}
