angular.module('apruve.sellers.corporate_account').controller "SellersCreditLimitChangeRequestController", [
  'SellersCorporateAccount', 'SellersCreditLimitChangeRequest', '$location', '$state', '$stateParams'
  (CorporateAccount, CreditLimitChangeRequest, $location, $state, $stateParams) ->

    @merchant_id = $location.path().split('/')[2]
    @corporate_account_uuid = $location.path().split('/')[4]
    @corporate_account = CorporateAccount.get(uuid: @corporate_account_uuid, merchant_id: @merchant_id)
    @credit_limit_change_request = new CreditLimitChangeRequest(merchant_id: @merchant_id, corporate_account_id: @corporate_account_uuid)
    @submitting = null
    @result = null
    @display_errors = false

    @back_button_text = ->
      if $stateParams.next_state == 'sellers.corporate_accounts'
        'Back to Corporate Accounts'
      else
        'Back to Corporate Account'

    @go_back = =>
      $state.go($stateParams.next_state, {corporate_account_id: @corporate_account_uuid})

    @submit_options =
      buttonDefaultText: 'Request Change'
      buttonSubmittingText: 'Saving...'
      buttonSuccessText: 'Saved'

    @redirect_to_overdue_invoices = =>
      $state.go('sellers.invoices')

    @has_overdue_invoices = =>
      @corporate_account.overdue_invoices_count > 0


    @submit_form = () =>
      @submitting = true
      if @change_request_form.$invalid
        @result = 'error'
        @display_errors = true
        return false

      @display_errors = false
      @credit_limit_change_request.$save (result) ->
        @result = 'success'
        $state.go('sellers.request_limit_change.success', {next_state: $stateParams.next_state})
      , (response) =>
        @result = 'error'
        @display_errors = true
        @credit_limit_change_request = response.data
        console.error(response.data)

      return

    return
]