angular.module('apruve.sellers.invoice').controller "InvoiceReturnController", [
  '$state', '$stateParams', 'return_data', 'invoice_data'
  ($state, $stateParams, return_data, invoice_data) ->
    @invoice_return = return_data
    @amount_due = invoice_data.amount_due
    @currency_symbol = invoice_data.currency_symbol
    @submitting = null
    @result = null
    @display_errors = false
    @save_options =
      buttonDefaultText: 'Save'
      buttonSubmittingText: 'Saving...'
      buttonSuccessText: 'Return Saved!'

    @submit_return = =>
      @submitting = true
      if @returnForm.$invalid
        @display_errors = true
        @result = 'error'
        return false

      @display_errors = false
      @invoice_return.$save (p) =>
        if p.meta.errors == null
          @result = 'success'
          $state.go('sellers.invoice', null, {reload: true})
        else
          @display_errors = true
          @result = 'error'
          console.error('Error while updating invoice: ', JSON.stringify(p.meta))
      , (response) =>
        @invoice_return.meta = response.data.meta
        @result = 'error'
        console.error('Error while recording payment: ', JSON.stringify(response.data))

    return
]