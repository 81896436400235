angular.module('apruve.creditors.app', [
  'ui.router',
  'ui.select',
  'ngAnimate',
  'ngSanitize',
  'ngResource',
  'apruve.common',
  'apruve.creditors.performance',
  'apruve.creditors.app.routes',
  'apruve.sellers.performance',
  'apruve.ng-bs-animated-button',
  'ui.bootstrap',
  'bgf.paginateAnything'
]);
