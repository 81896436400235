angular.module('apruve.users.corporate_account').controller("CorporateAccountController", [
  '$scope', '$stateParams', '$state', '$location', '$window', '$uibModal' ,'CorporateAccount', 'corporate_account_data', 'payment_methods', 'corporate_account_payment_method', 'account_performance', 'PaymentMethod', 'TeamMember', 'AccountPerformance', 'CorporateAccountPaymentMethod',
  function($scope, $stateParams, $state, $location, $window, $uibModal, CorporateAccount, corporate_account_data, payment_methods, corporate_account_payment_method, account_performance, PaymentMethod, TeamMember, AccountPerformance, CorporateAccountPaymentMethod) {
    var _this = this;
    this.url_params = $stateParams;
    this.url_params.page = parseInt(this.url_params.page);
    this.new_params = angular.copy(this.url_params);
    this.team_members_url = "/accounts/".concat(
      this.url_params.corporate_account_id,
      "/team_members.json?include_inactive=true"
    );
    this.account_performance = account_performance;
    this.corporate_account = corporate_account_data;
    this.payment_methods = payment_methods;

    this.corporate_account_payment_method = corporate_account_payment_method;
    this.corporate_account_payment_method.strategy = this.corporate_account.payment_strategy;
    if (this.corporate_account.self_financed) {
      this.corporate_account_payment_method.strategy = 'StripeServiceStrategy';
    }

    this.corporate_account_payment_method.account_uuid = $stateParams.corporate_account_id;
    this.bankAccountCardTemplate = function() {
      return require('../../../../templates/accounts/bank_account_card.html');
    };
    this.bankAccountModalTemplate = function() {
      return require('../../../../templates/common/bank_accounts/bank_account_modal.html');
    };
    this.bankAccountCountryCode = this.corporate_account.country_code;
    this.contextParams = {
      allow_payer: this.corporate_account.allow_payer,
      payment_methods_length: this.payment_methods.length,
      creditor_name: this.corporate_account.creditor_name
    };

    this.submit_options = {
      buttonDefaultText: 'Add',
      buttonSubmittingText: 'Working...',
      buttonSuccessText: 'Success!'
    };

    this.ddSelectOptions = [
      {
        text: 'Last 30 Days'
      },
      {
        text: 'Year-to-Date'
      },
      {
        text: 'All Time'
      }
    ];
    this.ddSelectSelected = {
      text: 'Last 30 Days'
    };

    this.load_new_page = function(new_value, old_value) {
      if ((new_value == null) || (old_value == null)) { return true; }
      if (new_value === old_value) { return true; }
      $state.go('.', new_value, {notify: false});
      if (new_value.page !== old_value.page) { return true; }
      return _this.url_params = angular.copy(new_value);
    };

    this.fetch_stats = function(new_value, old_value) {
      switch (new_value.text) {
        case 'All Time':
          return _this.account_performance = AccountPerformance.get({account_id: $stateParams.corporate_account_id, date_range: 'all_time'});
        case 'Year-to-Date':
          return _this.account_performance = AccountPerformance.get({account_id: $stateParams.corporate_account_id, date_range: 'ytd'});
        default:
          return _this.account_performance = AccountPerformance.get({account_id: $stateParams.corporate_account_id});
      }
    };


    $scope.$watch('corpAcctCtrl.new_params', this.load_new_page, true);
    $scope.$watch('corpAcctCtrl.ddSelectSelected', this.fetch_stats, true);

    this.remove_payment_method = function() {
      return this.corporate_account.$delete_payment_method(function(result) {
        $('#removeAccountModal').modal('hide');
        return $state.reload();
      }
      , function(response) {
        return console.error(response.data);
      });
    };

    this.update_quotes_enabled = function() {
      return this.corporate_account.$update_quotes_enabled()["catch"](function(err) {
        return console.error(err);
      });
    };

    this.show_team_member = function(team_member) {
      if (!team_member.deleted_at) {
        return $state.go('team_member', {team_member_id: team_member.uuid}, {reload: true});
      }
    };

    this.row_classes_for = function(team_member) {
      if (team_member.deleted_at) {
        return ['list-item-done', 'data-table__row--unclickable'];
      } else {
        if (this.corporate_account.allow_admin) {
          return ['data-table__row--clickable'];
        } else {
          return ['data-table__row--unclickable'];
        }
      }
    };

    this.display_spend = function(team_member) {
      if (_this.ddSelectSelected.text === 'All Time') {
        return team_member.total_spend_display;
      } else if (_this.ddSelectSelected.text === 'Year-to-Date') {
        return team_member.ytd_spend_display;
      } else  if (_this.ddSelectSelected.text === 'Last 30 Days') {
        return team_member.last_30_spend_display;
      }
    };

    this.set_payment_method = function() {
      var set_method_modal_instance = $uibModal.open({
        animation: true,
        backdrop: true,
        keyboard: true,
        templateUrl: require('../../../../templates/accounts/set_account_modal.html'),
        controller: 'AccountAddPaymentMethodController',
        scope: $scope,
        resolve: {
          corporate_account_data: [function() {
            return corporate_account_data;
          }
          ],
          payment_methods: [function() {
            return payment_methods;
          }
          ]
        }
      });
      set_method_modal_instance.result.then((function(new_account) {
        corporate_account_data.payment_method = new_account;
        $state.reload();
      }), function() {
        console.log('Account Modal dismissed at: ' + new Date);
      });
    };

    this.contextParams['set_payment_method'] = this.set_payment_method;

    this.submit_form = function() {
      _this.submitting = true;
      if (_this.authorized_shopper_form.$invalid) {
        _this.result = 'error';
        _this.display_errors = true;
        return false;
      }

      _this.display_errors = false;
      var uuid = _this.corporate_account.uuid;
      CorporateAccount.add_shopper({uuid: uuid, email: _this.shopper_email, name: _this.shopper_name}, function(result) {
        this.result = 'success';
        return $state.go('account', {corporate_account_id: uuid}, {reload: true});
      }
      , function(response) {
        _this.result = 'error';
        _this.display_errors = true;
        _this.corporate_account = response.data;
        return console.error(response.data);
      });
    };
  }
]);