import currency from 'currency.js';

class CurrencyFormatter {
  constructor(currencyData) {
    this.parse = this.parse.bind(this);
    this.format = this.format.bind(this);
    this.currencyWrapped = this.currencyWrapped.bind(this);
    this.symbol = currencyData.symbol;
    this.thousands_separator = currencyData.thousands_separator;
    this.decimal_mark = currencyData.decimal_mark;
    this.exponent = currencyData.exponent;
  }

  currencyWrapped(raw) {
    const raw_or_zero = raw ?? 0.0;
    return currency(raw_or_zero, {
      symbol: this.symbol,
      separator: this.thousands_separator,
      decimal: this.decimal_mark,
      precision: this.exponent
    });
  }

  parse(raw) {
    const money_with_iso_code = /^([^a-zA-Z]+?)\s+[a-zA-Z]{3}$/;
    if (typeof raw === 'string' && raw.match(money_with_iso_code)) {
      const match = raw.match(money_with_iso_code);
      const without_iso = match[1];
      return this.currencyWrapped(without_iso).value;
    } else if (typeof raw === 'string' && raw.match( /[a-zA-Z]/)) {
      return null;
    } else {
      return this.currencyWrapped(raw).value;
    }
  }

  format(num) {
    return this.currencyWrapped(num).format();
  }
}

angular.module('apruve.common.util')
.factory(
  'currencyFormatterMaker',
  function() {
    const buildFormatter = currencyData => new CurrencyFormatter(currencyData);
    return { buildFormatter };
});