angular.module('apruve.common.bank_account').directive('bankAccountForm', function() {
  return {
    require: 'ngModel',
    templateUrl: require('../../../../templates/common/bank_accounts/bank_account_form.html'),
    controller: ['$stateParams', '$scope', '$window', 'environment', 'BankAccountFormatService', function($stateParams, $scope, $window, environment, BankAccountFormatService) {
      if ($scope.mode !== 'create') {
        $scope.modal_title = 'Verify Bank Account';
      } else {
        $scope.modal_title = 'Add a Bank Account';
      }

      BankAccountFormatService.get_format_information_for_country($stateParams.corporate_account_id, $stateParams.invoice_uuid).then(function() {
        $scope.currency = BankAccountFormatService.get_currency();
        if ($scope.mode === 'create') {
          if ($scope.currency === 'CAD') {
            $scope.modal_title = 'Add a Canadian Bank Account';
          } else if ($scope.currency === 'EUR') {
            $scope.modal_title = 'Add an EU Debit Bank Account';
          } else {
            $scope.modal_title = 'Add a US Debit Bank Account';
          }
        }
        $scope.branch_number_regex = new RegExp(BankAccountFormatService.get_branch_number_regex());
        $scope.account_number_regex = new RegExp(BankAccountFormatService.get_account_number_regex());
        $scope.bank_number_regex = new RegExp(BankAccountFormatService.get_bank_number_regex());
        $scope.routing_number_regex = new RegExp(BankAccountFormatService.get_routing_number_regex());
        $scope.iban_regex = new RegExp(BankAccountFormatService.get_iban_regex());
        return $scope.should_show_input = function(input_name) {
          return BankAccountFormatService.get_required_fields().includes(input_name);
        };
      });

      this.in_canada = function() {
        return $scope.countryCode === 'CA';
      };

      $scope.should_show_account_number_tooltip = !this.in_canada();
      $scope.environment = environment;
      /* Bank Account Form */
      $scope.success_account = function() {
        $scope.bankAccount = angular.copy($scope.bankAccountResource);
        $scope.bankAccount.account_holder_name = 'Joe';
        $scope.bankAccount.account_holder_type = 'company';
        $scope.bankAccount.routing_number = '110000000';
        $scope.bankAccount.account_number = '000123456789';
        $scope.bankAccount.strategy = 'StripeServiceStrategy';
        $scope.bankAccount.iban = 'DE89370400440532013000';
        $scope.bankAccount.financial_institution_number = "003";
        $scope.bankAccount.transit_number = "54678";
        $scope.bankAccount.sepa_mandate_accepted_signature = 'Joe';
        return $scope.bankAccount.sepa_mandate_checkbox = true;
      };

      $scope.failure_account = function() {
        $scope.bankAccount = angular.copy($scope.bankAccountResource);
        $scope.bankAccount.account_holder_name = 'Joe';
        $scope.bankAccount.account_holder_type = 'company';
        $scope.bankAccount.routing_number = '110000000';
        $scope.bankAccount.account_number = '000111111116';
        $scope.bankAccount.sepa_mandate_accepted_signature = 'Joe';
        return $scope.bankAccount.sepa_mandate_checkbox = true;
      };

      $scope.$on('bank_account.reset', $scope.reset_form);
    
      $scope.reset_form = function() {
        $scope.display_errors = false;
        if ($scope.bankAccountForm) {
          $scope.bankAccountForm.$setPristine(true);
          return $scope.bankAccountForm.$setUntouched(true);
        }
      };

      $scope.show_error_class = function(elem, form) {
        var submitted = form ? form.$submitted : false;
        return !elem.$valid && (elem.$touched || elem.$dirty || submitted);
      };

      $scope.submit_form = function() {
        $scope.submitting = true;
        if ($scope.bankAccountForm.$invalid) {
          $scope.display_errors = true;
          $scope.result = 'error';
          return;
        }
        if ($window.account_uuid != null) {
          $scope.bankAccount.account_uuid = $window.account_uuid;
        }
        return $scope.bankAccount.$create(null, function(value, responseHeaders) {
          $scope.result = 'success';
          if (($scope.bankAccount.meta != null ? $scope.bankAccount.meta.flash : undefined) != null) { $scope.bankAccount.meta.flash = null; }
          $scope.reset_form();
          return $scope.$emit('bank_account.done');
        }
        , function(httpResponse) {
          $scope.display_errors = true;
          $scope.bankAccount.meta = httpResponse.data.meta;
          return $scope.result = 'error';
        });
      };
        
      $scope.cancel_bank = function() {
        return $scope.$emit('bank_account.done');
      };

      // Used by account/routing number validators
      $scope.country = 'US';
      $scope.currency = 'USD';

      $scope.submit_options = {
        buttonDefaultText: 'Save Bank Account',
        buttonSubmittingText: 'Saving...',
        buttonSuccessText: 'Saved',
        buttonDefaultClass: 'btn-success',
        buttonSubmittingClass: 'btn-success',
        buttonSuccessClass: 'btn-success'
      };

      $scope.reset_form();
    }
    ]
  };
});
