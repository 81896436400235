angular.module('apruve.users.corporate_account').directive('creditLimitCard', [() => {
  return {
    restrict: 'E',
    scope: {
      isAutoPay: '=',
      corporateAccount: '='
    },
    templateUrl: require('../../../../templates/accounts/loan_card.html'),
    link: scope => {
      scope.show_loan_card = scope.corporateAccount.credit_limit !== null || scope.corporateAccount.financing_loan !== null;
      if(!scope.show_loan_card) return;

      scope.show_increase_request_link = scope.corporateAccount.user_can_update &&
          scope.corporateAccount.has_credit_limit &&
          scope.corporateAccount.has_creditor_term &&
          !scope.corporateAccount.credit_limit_change_pending &&
          !scope.corporateAccount.self_financed;

      if(scope.corporateAccount.credit_limit && scope.corporateAccount.credit_limit.is_prepaid) {
        scope.prepaidSpentDisplay = scope.corporateAccount.credit_limit.spent_prepaid_amount_display;
        scope.creditInvoiced = scope.corporateAccount.credit_limit.spent_prepaid_amount + scope.corporateAccount.credit_limit.uncleared_hold_amount;
      }
    }
  }
}]);