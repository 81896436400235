angular.module('apruve.sellers.credit_memo').controller "NewCreditMemoController", [
  'CreditMemo', '$state', '$stateParams', 'corporate_account',
  (CreditMemo, $state, $stateParams, corporate_account) ->

    @merchant_id = $stateParams.merchant_id
    @corporate_account = corporate_account
    @corporate_account_uuid = corporate_account.uuid
    @credit_memo = new CreditMemo(merchant_id: @merchant_id, corporate_account_id: @corporate_account_uuid)
    @submitting = null
    @result = null
    @display_errors = false

    @back_button_text = ->
      if $stateParams.next_state == 'sellers.credit_memos'
        'Back to Credit Memos'
      else
        'Back to Credit Memo'

    @go_back = =>
      $state.go($stateParams.next_state, {corporate_account_id: @corporate_account_uuid})

    @submit_options =
      buttonDefaultText: 'Create Credit Memo'
      buttonSubmittingText: 'Saving...'
      buttonSuccessText: 'Saved'

    @submit_form = () =>
      @submitting = true
      if @credit_memo_form.$invalid
        @result = 'error'
        @display_errors = true
        return false

      @display_errors = false
      @credit_memo.$save (result) =>
        if result.meta.errors == null
          @result = 'success'
          $state.go('sellers.credit_memo', credit_memo_uuid: result.uuid)
        else
          @result = 'error'
          @display_errors = true
          @submitting = false
      , (response) =>
        @result = 'error'
        @display_errors = true

      return

    return
]