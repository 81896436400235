import { TrackJS } from 'trackjs';

angular.module('apruve.common.payment_modal').factory('bankAccountWorkflowFactory', ['paymentActionDecorator', bankAccountWorkflowFactory]);

function bankAccountWorkflowFactory(paymentActionDecorator) {
  return {
    card_template: require('../../../../../templates/common/payment_modal/payment_method_cards/bank_account.html'),
    next_state: '^.bank_account.confirmation',
    checkbox: true,
    checkbox_text: 'I authorize Apruve to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits.',
    resolve_next_state: function(payment_action, state) {
      payment_action.payment.$save(function(p) {
        const payment_action = paymentActionDecorator.decorate(p);
        state.go('^.bank_account.confirmation', {
          payment_action: payment_action,
        })
      }, function(error) {
        TrackJS.console.error('Pay by ECheckAccount error: ' + JSON.stringify(error));
        state.go('^.error')
      });
    }
  };
}
