angular.module('apruve.sellers.checklist').controller 'ChecklistController', [
  'Checklist', '$scope', '$stateParams', 'currentUserService',
  (Checklist, $scope, $stateParams, currentUserService) ->

    $scope.checklist_data = Checklist.get({merchant_id: $stateParams.merchant_id })

    @currentUserService = currentUserService
    @currentContext = () ->
      @currentUserService.currentContext
    return
]