angular.module('apruve.users.corporate_account').factory("CorporateAccount", ['$resource', function($resource) {
  return $resource("/accounts/:uuid.json",
    {uuid: "@uuid"},
    {
      query: {isArray: true},
      add_buyer: {method: 'PUT', url: '/accounts/:uuid/add_buyer'},
      remove_buyer: {method: 'PUT', url: '/accounts/:uuid/remove_buyer'},
      add_payment_method: {method: 'PUT', url: '/accounts/:uuid/payment_methods'},
      delete_payment_method: {method: 'DELETE', url: '/accounts/:uuid/payment_methods'},
      update_quotes_enabled: {method: 'PUT', url: '/accounts/:uuid/quotes_enabled' },
      accept_auto_pay_tos: {method: 'PUT', url: '/accounts/:uuid/accept_auto_pay_tos'},
      decline_auto_pay_tos: {method: 'PUT', url: '/accounts/:uuid/decline_auto_pay_tos'}
    }
  );
}
]);
