angular.module('apruve.common.environment').provider('environment', environment);

function environment() {

  const environment_string = process.env.NODE_ENV;

  this.$get = function() {
    return {
      is_test: function() {
        return environment_string === 'test'
      },
      is_production: function() {
        return environment_string === 'production'
      },
      is_development: function() {
        return environment_string === 'development'
      }
    };
  };

  return this;
}
