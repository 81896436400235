angular.module('apruve.common.payment_modal')
       .factory(
         'paymentMethodWorkflowFactory',
           [
             'newBankAccountWorkflowFactory',
             'bankAccountWorkflowFactory',
             'creditMemoWorkflowFactory',
             paymentMethodWorkflowFactory
           ]
       );

function paymentMethodWorkflowFactory(newBankAccountWorkflowFactory,
                                      bankAccountWorkflowFactory,
                                      creditMemoWorkflowFactory) {
  return {
    workflow_for_payment_method_category: function(category) {
      switch (category) {
        case 'BankAccount':
          return bankAccountWorkflowFactory;
        case 'NewBankAccount':
          return newBankAccountWorkflowFactory;
        case 'CreditorCreditMemo':
          return creditMemoWorkflowFactory;
        case 'MerchantCreditMemo':
          return creditMemoWorkflowFactory;
      }
    },

    workflow_for_payment: function(payment_action) {
      const uses_non_credit_memo = payment_action.payment.payment_amount > 0;
      const uses_credit_memos = payment_action.credit_memos.length > 0;
      let payment_method_factory = this.workflow_for_payment_method_category(payment_action.payment.payment_method?.category);

      if(uses_non_credit_memo && uses_credit_memos) {
        payment_method_factory.has_credit_memos = true;
        return payment_method_factory;
      } else if(uses_non_credit_memo && !uses_credit_memos) {
        return payment_method_factory;
      } else if(!uses_non_credit_memo && uses_credit_memos) {
        return creditMemoWorkflowFactory;
      } else {
        console.error('we have a problem');
      }
    }
  };
}