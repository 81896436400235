angular.module 'apruve.common', [
  'apruve.common.http'
  'apruve.common.alerts'
  'apruve.common.helpers'
  'apruve.common.loading'
  'apruve.common.logging'
  'apruve.common.message_log'
  'apruve.common.click_to_show'
  'apruve.common.sidenav'
  'apruve.common.current_user'
  'apruve.common.active_state_workaround'
  'apruve.common.po_details'
  'apruve.common.profile_band'
  'apruve.common.title'
  'apruve.common.tooltip'
  'apruve.common.charts'
  'apruve.common.environment'
  'apruve.common.context_menu'
  'apruve.common.confirmation_modal'
  'apruve.common.combodate'
  'apruve.common.bank_account'
  'apruve.common.corporate_account'
  'apruve.common.search'
  'apruve.common.invoice_import'
  'apruve.common.payment_modal'
  'apruve.common.assets'
  'apruve.common.util'
  'apruve.common.validators'
  'apruve.common.bank_account_format'
  'apruve.common.moment'
  'apruve.common.money'
  'apruve.common.to_precision'
]
