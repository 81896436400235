class @ApiKey
  constructor: ->
    _.bindAll @
    @initAddApiKeyToggle()
    @initApiKeyFormAjax()

  initAddApiKeyToggle: ->
    $('#add_api_key').on 'click', (e) ->
      e.preventDefault()
      $('#add_api_key').hide 'fast', ()->
        $('#api_key_form').slideDown('fast')

    $('#cancel').on 'click', (e) ->
      e.preventDefault()
      $('#api_key_form').slideUp 'fast', ()->
        $('#add_api_key').show('fast')
        $('#api_key_form form').parent().find('.alert').remove()

  initApiKeyFormAjax: ->
    @apiKeyForm = $('#api_key_form form')
    @apiKeyForm.on 'submit', @submitHandler

  submitHandler: (evt) ->
    evt.preventDefault()
    $('#api_key_form .btn-primary').attr('disabled', true)
    $.post @apiKeyForm.attr('action') + '.json', @apiKeyForm.serialize(), @responseHandler
    
  responseHandler: (data, textStatus, jqXHR) ->
    @apiKeyForm.parent().find('.alert').remove()
    $('#api_key_form .btn-primary').attr('disabled', false)
    if data.errors?
      for field, errors of data.errors
        for error in errors
          @apiKeyForm.before @errorMarkup(field, error)
    else
      @apiKeyForm.before @successMarkup()
      @clearForm()
      $('#no_api_keys').hide()
      $('#api_keys_container').show()
      $('#api_keys tbody').append data.rendered

  errorMarkup: (field, error) ->
    "<div class='alert alert-danger alert-compact'>#{field} #{error}</div>"

  successMarkup: ->
    "<div class='alert alert-success alert-compact'>Created Api Key</div>"

  clearForm: ->
    @apiKeyForm.find('input[type=text], select').val('')
