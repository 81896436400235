angular.module('apruve.users.invoice').controller("InvoiceController", [
  '$scope', '$state', '$uibModal', 'invoice_data', 'currentUserService', 'Payment', 'payment_instructions', '$sce',
  ($scope, $state, $uibModal, invoice_data, currentUserService, Payment, payment_instructions, $sce) => {
      $scope.invoice = invoice_data;
      $scope.colspan = Object.keys($scope.invoice.item_optional_fields).filter((field) => {
          return $scope.invoice.item_optional_fields[field];
      }).length + 5;
      $scope.payment_instructions = clean_up_html(payment_instructions.data);

      function clean_up_html(payment_instructions_html) {
          payment_instructions_html = payment_instructions_html.substr(payment_instructions_html.indexOf("<div class='app'"), payment_instructions_html.length - 1);
          const html = $('<div/>', {html: payment_instructions_html});
          html.find('.alertContainer').remove();
          html.find('.main__navtop-wrap').remove();
          html.find('.navmobile').remove();
          html.find('.main__left').remove();
          html.find('div').removeClass('main__body-wrap');
          html.find('div').removeClass('page-container');
          html.find('div').removeClass('main__right');
          html.find('div').removeClass('main__wrapper');
          return $sce.trustAsHtml(html.html());
      }
  }
]);