class window.VendorSuggestion

  constructor: ->
    @initModalClose()
    @initInvalidator()
    @initRemoveWarning()

  initModalClose: ->
    $('body').on 'click', '#modal_close', (e) =>
      e.preventDefault()
      @closeModal()
    $(document).on 'keyup', (e) =>
      if e.keyCode == 27
        @closeModal()
    $('#modalContainer').on 'click', (e) =>
      e.preventDefault()
      @closeModal()
    $('#modalContainer').on 'click', '#modal_close', (e) =>
      e.preventDefault()
      @closeModal()
    $('#modalContainer').on 'click', '#modal', (e) =>
      e.stopPropagation()


  initInvalidator: ->
    $('form#new_vendor_suggestion').on 'submit', (e) ->
      store = $('#vendor_suggestion_store_name')
      url   = $('#vendor_suggestion_web_url')
      if store.val() == '' && url.val() == ''
        store.css('border-color', 'red')
        url.css('border-color', 'red')
        e.preventDefault()

  initRemoveWarning: ->
    $('#vendor_suggestion_store_name, #vendor_suggestion_web_url').on 'keypress', () ->
      if $(@).val() == ''
        $('#vendor_suggestion_web_url').css('border-color', '')
        $('#vendor_suggestion_store_name').css('border-color', '')

  closeModal: ->
    $('#modalContainer').fadeOut 200, ()->
      $('#modal').fadeOut(200)
