angular.module('apruve.common.context_menu')
.directive 'contextMenu', [() ->
  restrict: 'E'
  templateUrl: require('../../../../templates/common/context_menu/context_menu.html')
  replace: true
  transclude: true
  controller: [
    '$scope', '$state', '$window', 'currentUserService',
    ($scope, $state, $window, currentUserService) ->
      $scope.currentUserService = currentUserService
      $scope.currentState = $state.current.name
      $scope.collapsed = true

      $scope.user = () ->
        currentUserService.user

      $scope.showDropdown = () ->
        $scope.hasMultipleMerchants()

      $scope.urlFor = (merchant_id) ->
        if $window.location.pathname.match(/sellers/g)
          $state.href('sellers', merchant_id: merchant_id)

      $scope.hasMultipleMerchants = () ->
        $scope.allContexts().length > 1

      $scope.allContexts = () ->
        [$scope.currentContext()].concat $scope.alternateContexts()

      $scope.currentContext = () ->
        $scope.currentUserService.currentContext

      $scope.alternateContexts = () ->
        $scope.currentUserService.alternateContexts
  ]
]