angular.module('apruve.users.getting_started').controller("GettingStartedAccountController", [
  '$scope', '$state', '$location', '$window', '$uibModal' ,'CorporateAccount', 'corporate_account_data', 'TeamMember', 'CorporateAccountPaymentMethod',
  function($scope, $state, $location, $window, $uibModal, CorporateAccount, corporate_account_data, TeamMember, CorporateAccountPaymentMethod) {
    var _this = this;
    this.corporate_account = corporate_account_data;

    if (this.corporate_account.auto_pay && !this.corporate_account.auto_pay_tos_accepted) {
      $scope.corporate_account = this.corporate_account;
      this.auto_pay_tos_form_modal = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'autoPayTosFormModal',
        templateUrl: require('../../../../templates/getting_started/auto_pay_tos_modal.html'),
        scope: $scope,
        windowClass: 'modal fade v2',
        controller: 'AutoPayTosFormController',
        keyboard: false, // disable closing with ESC key
        backdrop: 'static' // disable closing modal by clicking outside
      });
    }

    this.payment_categories = this.corporate_account.allowed_payment_categories
      .filter(function(payment_category) {
      return payment_category.name !== 'BankAccount';
    })
      .map(function(payment_category) {
      return payment_category.display_name;
    })
      .sort();
    this.display_form = '';
    this.payer_email = '';
    this.submitting = null;
    this.new_team_member = new TeamMember({buyer: true, payer: true, admin: true});

    this.corporate_account_payment_method = new CorporateAccountPaymentMethod();
    this.corporate_account_payment_method.strategy = this.corporate_account.payment_strategy;
    this.corporate_account_payment_method.account_uuid = this.corporate_account.uuid;
    this.bankAccountCardTemplate = function() {
      return require('../../../../templates/getting_started/add_account_card.html');
    };
    this.bankAccountModalTemplate = function() {
      return require('../../../../templates/common/bank_accounts/bank_account_modal.html');
    };

    this.submit_options = {
      buttonDefaultText: 'Save and Send Invitation',
      buttonSubmittingText: 'Sending...',
      buttonSuccessText: 'Sent!',
      buttonErrorText: 'Please enter a valid email address'
    };

    this.show_add_later = function() {
      return ["account", "alternative"].indexOf(_this.display_form) !== -1;
    };

    this.add_team_admin = function() {
      _this.submitting = true;
      if (_this.team_admin_form.$invalid) {
        _this.result = 'error';
        return false;
      }
      _this.new_team_member.$create({customer_account_id: _this.corporate_account.uuid}, function(result) {
          _this.submitting = false;
          return _this.result = 'success';
        },
        $state.go('setup_team', {corporate_account_id: _this.corporate_account.uuid}, {reload: true})
        , function(response) {
          this.submitting = false;
          return this.result = 'error';
      });
      return _this.new_team_member = new TeamMember({buyer: true});
    };

    this.redirect_to_setup_team = function (){
      window.location = "/accounts/"+ _this.corporate_account.uuid + "/setup_team";
    }

    $scope.$on('bank_account.done', function() {
      return $state.go('getting_started.setup_team', null, {reload: true});
    });
  }
]);