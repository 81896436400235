angular.module('apruve.sellers.performance')
.directive 'currency', [
  ->
    restrict: 'E'
    scope: { amount: '=', filter: '=?', shrink: '@' }
    template: '<div class="perf__data" ng-class="{perf__smaller: shrink && amount >= 10000}">{{ amount | selectFilter: filter }}</div>'

    compile: (element, attrs) ->
      if !attrs.filter then attrs.filter = '"currency"'
      if typeof(attrs.shrink) == "undefined" then attrs.shrink = true
      return
]