angular.module('apruve.sellers.invoice').factory "Invoice", ['$resource', ($resource) ->
  $resource("/sellers/:merchant_id/invoices/:uuid.json",
    {uuid: "@uuid", merchant_id: "@merchant_id"},
    {
      query: {isArray: true},
      close: {method: 'POST', url: '/sellers/:merchant_id/invoices/:uuid/close.json', isArray: false}
      cancel: {method: 'POST', url: '/sellers/:merchant_id/invoices/:uuid/cancel.json', isArray: false}
      update: {method: 'PUT', isArray: false}
      import_external: {method: 'POST', url: '/sellers/:merchant_id/invoices/import_external'}
    })
]