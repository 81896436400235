angular.module('apruve.sellers.invoice').controller "InvoiceController", [
  '$scope', '$state', 'invoice_data', 'Payment', 'Invoice',
  ($scope, $state, invoice_data, Payment, Invoice) ->
    $scope.invoice = invoice_data
    $scope.invoice_orig = angular.copy(invoice_data)
    $scope.current_user = window.current_user
    $scope.update_data = {}
    $scope.colspan = Object.keys($scope.invoice.item_optional_fields).filter((field) ->
      $scope.invoice.item_optional_fields[field]
    ).length + 5


    $scope.restore_invoice = ->
      angular.copy($scope.invoice_orig, $scope.invoice)

    $scope.close_invoice = ->
      $scope.submitting = true
      $scope.invoice.$close (p) ->
        if p.meta.errors == null
          $scope.result = 'success'
          $state.go('sellers.invoice')
        else
          $scope.result = 'error'
          console.error('Error while closing invoice: ', JSON.stringify(p.meta))

    $scope.cancel_invoice = ->
      $scope.submitting = true
      $scope.invoice.$cancel (p) ->
        if p.meta.errors == null
          $scope.result = 'success'
          $state.go('sellers.invoice')
        else
          $scope.result = 'error'
          console.error('Error while canceling invoice: ', JSON.stringify(p.meta))

    $scope.sent_message = ->
      $state.go('sellers.invoice')

    $scope.display_errors = false
    $scope.result = null
    $scope.submitting = null
    $scope.close_invoice_options =
      buttonDefaultText: 'Yes'
      buttonSubmittingText: 'Closing...'
      buttonSuccessText: 'Invoice Closed'
      buttonDefaultClass: 'actionBar-btn-primary'
      buttonSubmittingClass: 'actionBar-btn-primary'
      buttonSuccessClass: 'actionBar-btn-primary'
      buttonErrorClass: 'actionBar-btn-danger'
    $scope.cancel_invoice_options =
      buttonDefaultText: 'Yes'
      buttonSubmittingText: 'Canceling...'
      buttonSuccessText: 'Invoice Canceled'
      buttonDefaultClass: 'actionBar-btn-primary'
      buttonSubmittingClass: 'actionBar-btn-primary'
      buttonSuccessClass: 'actionBar-btn-primary'
      buttonErrorClass: 'actionBar-btn-danger'

]