angular.module('apruve.users.stats').factory 'stats', [
  '$rootScope', '$state', 'UserCounts',
  ($rootScope, $state, UserCounts) ->
    user_object_count = {}

    do_update_object_counts = ->
      UserCounts.get (object_count) ->
        user_object_count = object_count

    $rootScope.$on '$stateChangeSuccess', ->
      do_update_object_counts()
    
    $rootScope.$on 'update_stats', ->
      do_update_object_counts()
    
    do_update_object_counts()

    return {
      get_user_object_count: ->
        return user_object_count
    }
]
