angular.module('apruve.users.invoice').controller 'InvoicePaymentsController', [
  '$scope', '$state', '$stateParams', 'invoice_data',
  ($scope, $state, $stateParams, invoice_data) ->
    $scope.url_params = $stateParams
    $scope.url_params.page = parseInt $scope.url_params.page
    $scope.new_params = angular.copy($scope.url_params)
    $scope.invoice = invoice_data
    $scope.url = ->
      "/invoices/#{$scope.invoice.uuid}/payments.json"

    $scope.navigate_to_payment = (invoice_payment) ->
      if !invoice_payment.is_credit_memo
        $state.go('payment', payment_uuid: invoice_payment.payment_uuid)
      else
        $state.go('credit_memo', credit_memo_uuid: invoice_payment.payment_uuid)
]