angular.module('apruve.sellers.billing_invoices').controller 'BillingInvoiceController', [
  '$scope', '$state', 'billing_invoice_data', '$uibModal', 'BillingInvoice',
  ($scope, $state, billing_invoice_data, $uibModal, BillingInvoice) ->
    @billing_invoice = billing_invoice_data

    @open_payment_modal = ->
      @payment_modal = $uibModal.open({
        animation: true
        backdrop: true
        keyboard: true
        templateUrl: require('../../../../templates/sellers/billing_invoices/billing_payment_modal.html')
        controller: 'BillingPaymentModalController'
        controllerAs: 'ctrl'
        resolve:
          billing_invoice_data: [ =>
            billing_invoice_data
          ]
      }).result.then (payment) =>
        BillingInvoice.get(uuid: @billing_invoice.uuid, merchant_id: @billing_invoice.merchant_id).$promise.then (bi) =>
          @billing_invoice = bi

    return
]