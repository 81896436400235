angular.module('apruve.sellers.performance').controller('PerformanceAgingReportController', [
  'Performance', '$state', '$scope', '$stateParams', 'currentUserService',
  function(Performance, $state, $scope, $stateParams, currentUserService) {
    var vm = $scope;
    let data = angular.fromJson(vm.data);
    if(!data){
      data = vm.$resolve.data
    }

      vm.ar_table_labels = Object.keys(data.merchant_age_groups);
      vm.ar_breakdown_by_group = data.merchant_age_groups;
      vm.ar_total = data.total_balance;
      vm.ar_total_overdue = data.total_overdue;
      vm.ar_total_current = vm.ar_breakdown_by_group["current"];
  }
]);
angular.module('apruve.sellers.performance').directive('corporateAccountAging', function () {

  return {
    templateUrl: require('../../../../templates/sellers/performance/aging_report.html'),
    controller: 'PerformanceAgingReportController',
    controllerAs: 'performanceAgingReportCtrl',
    scope: {
      ar_table_labels: "=?",
      ar_breakdown_by_group: "=?",
      ar_total: "=?",
      ar_total_overdue: "=?",
      ar_total_current: "=?",
      data: "@"
    },
  }
});