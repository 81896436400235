import { TrackJS } from 'trackjs';

angular.module('apruve.common.payment_modal')
  .controller('PaymentModalCreditMemoController', ['payable_model', '$state', 'images', 'paymentActionDecorator', PaymentModalCreditMemoController]);

function PaymentModalCreditMemoController(payable_model, $state, images, paymentActionDecorator) {
  this.state = $state;
  this.images = images;
  this.payable_model = payable_model;
  this.payment_action = paymentActionDecorator.decorate($state.params.payment_action);
  this.payment = this.payment_action.payment ?? this.payment_action.credit_memos[0];
  this.payment_instructions = this.payment.payment_method.payment_method_instruction;
  this.includes_credit_memos = (this.payment_action.credit_memo_amount.intValue ?? 0 > 0);

  this.submit_options = {
    buttonDefaultText: 'Submit Payment',
    buttonSubmittingText: 'Submitting...',
    buttonSuccessText: 'Payment Submitted',
    buttonDefaultClass: 'button',
    buttonSubmittingClass: 'button',
    buttonSuccessClass: 'button',
    buttonErrorClass: 'button'
  };
  this.result = null;
  this.submitting = false;

  this.next_state = () => {
    if (this.credit_memo_review_form.$invalid) {
      // TODO other error messaging
      return false;
    }
    this.submitting = true;

    this.payment.$save(p => {
      const payment_action = paymentActionDecorator.decorate(p);
      this.payment = payment_action.payment;
      this.payment_action = payment_action;
      this.result = 'success';
      $state.go('^.confirmation', {
        payment_action: this.payment_action
      });
    }, error => {
      this.submitting = false;
      this.result = 'error';
      TrackJS.track(error);
      $state.go('^.^.error');
    });

    return true;
  };
}
