angular.module('apruve.sellers.credit_applications').controller 'CreditApplicationsController', [
  '$state', 'SellersCreditApplication',
  ($state, SellersCreditApplication) ->
    @submitting = false
    @result = null
    @current_merchant_id = $state.params.merchant_id
    @credit_application = new SellersCreditApplication(merchant_id: $state.params.merchant_id)
    @create_options =
      buttonDefaultText: 'Begin Application'
      buttonSubmittingText: 'Creating...'
      buttonSuccessText: 'Redirecting...'
      buttonDefaultClass: 'actionBar-btn-primary'
      buttonSubmittingClass: 'actionBar-btn-primary'
      buttonSuccessClass: 'actionBar-btn-primary'
      buttonErrorClass: 'actionBar-btn-danger'

    @phone_regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

    @create_application = =>
      return unless @credit_application_form.$valid
      @submitting = true
      @credit_application.$save (data) =>
        @result = 'success'
        # leaving angular
        href = '/apply/' + $state.params.merchant_id + '/' + data.id + '/business_information/new'
        window.location.href = href
      , (error) =>
        @result = 'error'
        @submitting = false
        console.error("Error while creating account")

    @show_error_class = (elem, form) ->
      submitted = if form then form.$submitted else false
      !elem.$valid && (elem.$touched || elem.$dirty || submitted)

    return
]