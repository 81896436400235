angular.module('apruve.users.corporate_account').directive('creditLimitProgressBar', [() => {
  return {
    restrict: 'E',
    templateUrl: require('../../../../templates/accounts/loan_progress_bar.html'),
    scope: {
      corporateAccount: '='
    },
    link: (scope, element) => {
      scope.loan_type = 'credit';
      scope.has_loan_of_type = scope.corporateAccount.credit_limit != null; // Some people have financing
      if(!scope.has_loan_of_type) return;
      scope.is_prepaid = scope.corporateAccount.credit_limit.is_prepaid;
      scope.loan_percentage = scope.corporateAccount.credit_limit.percentage_spend;
      scope.progress_display = scope.corporateAccount.credit_limit.balance_display;
      scope.progress_label = 'spent';
      if(scope.is_prepaid) {
        scope.remainder_display = scope.corporateAccount.credit_limit.available_display;
        scope.remainder_label = 'remainder';
      } else {
        scope.remainder_display = scope.corporateAccount.credit_limit.amount_display;
        scope.remainder_label = 'limit';
      }
      scope.show_increase_request_link =  scope.corporateAccount.user_can_update &&
          scope.corporateAccount.has_credit_limit &&
          scope.corporateAccount.has_creditor_term &&
          !scope.corporateAccount.credit_limit_change_pending &&
          !scope.corporateAccount.self_financed;
    }
  }
}]);
