angular.module('apruve.sellers.purchase_order').controller "PurchaseOrderInvoicesController", [
  'purchase_order_data', 'invoice_data', '$state', '$stateParams', 'currencyFormatterMaker'
  (purchase_order_data, invoice_data, $state, $stateParams, currencyFormatterMaker) ->
    @url_params = $stateParams
    @url_params.page = parseInt @url_params.page
    @new_params = angular.copy(@url_params)
    @url = "/sellers/#{$stateParams.merchant_id}/invoices.json"
    @currencyData = purchase_order_data.currency_data
    @currencyFormatter = currencyFormatterMaker.buildFormatter(@currencyData)
    @invoice = invoice_data
    @purchase_order = purchase_order_data
    @pattern_errors = []
    @required_errors = []
    @all_errors = []
    @min_length_error = false
    @display_errors = false
    @submitting = null
    @result = null
    @invoice_orig = angular.copy(invoice_data)
    @create_button_options =
      buttonDefaultText: 'Save Invoice'
      buttonSubmittingText: 'Saving...'

    @should_show_create_invoice = () =>
      @purchase_order.can_charge

    @format_number_to_money = (num) =>
      @currencyFormatter.format(num)

    @error_class = (field) =>
      (@display_errors || field.$dirty) && field.$invalid

    @restore_invoice = ->
      angular.copy(@invoice_orig, @invoice)

    @show_create_invoice = () ->
      $state.go('sellers.purchase_order.invoices.new')

    @cancel_create_invoice = () ->
      $state.go('sellers.purchase_order.invoices')
      @restore_invoice()
      @clear_errors()

    @navigate_to_invoice = (invoice) ->
      $state.go('sellers.invoice', invoice_uuid: invoice.uuid, merchant_id: @purchase_order.merchant.id)

    @get_error_name_index = (errorName) ->
      return errorName.substring(errorName.lastIndexOf('_') + 1)

    @error_is_present = (array, errorName) ->
      if errorName.includes('tax') || errorName.includes('shipping')
        return false
      else
        name_last_index = @get_error_name_index(errorName)
        return array.some (x) -> x.includes(errorName.slice(0,-name_last_index.length)) == true

    @construct_error_name = (name) ->
      if name.includes('tax') || name.includes('shipping')
        return name.replace(/_/g, ' ')
      else
        index = @get_error_name_index(name)
        return name.slice(0,-index.length).replace(/_/g, ' ')

    @disable_save_on_error = () ->
      @disable_save = @all_errors.length != 0

    @add_error_message = (error) =>
      if error.$dirty || @form.$submitted
        message = document.getElementsByName(error.$name)[0].dataset?.errorMessage || 'has an error'
        @add_raw_error_message(@construct_error_name(error.$name) + ' ' + message)

    @add_raw_error_message = (message) =>
        @all_errors.push(message)

    @handle_form_errors = () ->
      # Safe operator because these will be null instead of empty arrays.
      @form.$error['currency']?.forEach(@add_error_message)
      @form.$error['minlength']?.forEach(@add_error_message)
      @form.$error['required']?.forEach(@add_error_message)
      @disable_save_on_error()
      # Can't do this in angular
      @all_errors = _.uniq(@all_errors)


    @reset_invoice_amounts = () ->
      if @invoice.invoice_items && @invoice.invoice_items.length == 0
        @invoice.shipping = @format_number_to_money(0)
        @invoice.amount = @format_number_to_money(0)
        @invoice.tax = @format_number_to_money(0)
        @invoice.subtotal = @format_number_to_money(0)

    @parse_amount = (amount) ->
      # It's ok to return 0 instead of something like NaN here because undefined gets passed if the field we're parsing
      # has an error (which will lead to other error handling for it
      return 0 unless amount
      @currencyFormatter.parse(amount)

    @set_amount_error = ()   ->
      if purchase_order_data.capturable_amount_cents != null && @parse_amount(@invoice.amount) * 100 > purchase_order_data.capturable_amount_cents
        @add_raw_error_message('Invoice total exceeds the capturable amount.')
        @amount_error = true
      else if purchase_order_data.capturable_amount_cents == null && @parse_amount(@invoice.amount) > @parse_amount(purchase_order_data.amount)
        @add_raw_error_message('Invoice total cannot exceed order total.')
        @amount_error = true
      else
        @amount_error = false

    @calculate_form_changes = () ->
      @clear_errors()
      subtotal = @invoice.invoice_items.map (ii) =>
        if ii? then @parse_amount(ii.price_total) else 0
      .reduce(((amt1, amt2) -> amt1 + amt2), 0)
      invoice_amount = subtotal + @parse_amount(@invoice.shipping) + @parse_amount(@invoice.tax)
      @invoice.amount = @format_number_to_money(invoice_amount)
      @invoice.subtotal = @format_number_to_money(subtotal)
      @set_amount_error()
      @disable_save_on_error()
      @reset_invoice_amounts()
      @handle_form_errors()

    @construct_invoice_data = (response) ->
      @invoice = response
      @invoice.shipping = response.shipping_display
      @invoice.subtotal = response.subtotal_display
      @invoice.tax = response.tax_display

    @create_invoice = (new_state) ->
      @submitting = true
      if @form.$invalid
        @display_errors = true
        @submitting = false
        @handle_form_errors()
        return false

      @display_errors = false

      @invoice.$save (response) =>
        if response.meta.errors == null
          @construct_invoice_data(response)
          $state.go new_state, {}, {reload: true}
        else
          @display_errors = true
          @result = 'error'
          @set_amount_error()
          @disable_save_on_error()
          @construct_invoice_data(response)
          console.error(response.meta.errors)

    @clear_errors = () ->
      @all_errors = []
      @pattern_errors = []
      @required_errors = []
      @min_length_error = false
      @display_errors = false
      @amount_error = false
      @amount_error_message = ''
      if @invoice.meta
        @invoice.meta.errors = {}
      @disable_save_on_error()

    @remove_item = (item) ->
      @invoice.invoice_items.splice(@invoice.invoice_items.indexOf(item), 1)
      @calculate_form_changes()
      @clear_errors()


    @add_row = () ->
      @invoice.invoice_items.push({
        description: '',
        variant: '',
        vendor: '',
        sku: '',
        quantity: '',
        price_ea: '',
        price_total: '',
        currency: @purchase_order.currency,
        apruve_rate: @purchase_order.apruve_rate,
        merchant_rate: @purchase_order.merchant_rate
      })

    return
]