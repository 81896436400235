class @ButtonAnimation
  constructor:(selector, loadingMessage)->
    @interval = null
    @counter = 0
    @selector = selector
    @normalMessage = $(@selector).val()
    @loadingMessage = loadingMessage

  animation:=>
    dots = new Array(@counter%6).join('.')
    @counter++
    @loadingWidth = $(@selector).val(@loadingMessage + '....').css('width','').css('width')
    $(@selector).css('width', @loadingWidth).addClass('main__btn-animated')
    $(@selector).val(@loadingMessage + dots)
    $(@selector).attr('disabled', true)

  start:=>
    @interval = setInterval(@animation, 175)
    return @interval

  stop:=>
    clearInterval(@interval)
    $(@selector).val(@normalMessage).removeAttr('style').removeClass('main__btn-animated').removeAttr('disabled')