angular.module('apruve.users.purchase_order').controller "PurchaseOrdersController", [
  '$state', '$stateParams', '$scope', 'statusIconsService',
  ($state, $stateParams, $scope, statusIconsService) ->
    @statusIconsService = statusIconsService
    @url_params = $stateParams
    @url_params.page = parseInt @url_params.page
    @new_params = angular.copy(@url_params)
    @search_field = @new_params.search
    @url = "/purchase_orders.json"

    @navigate_to = (purchase_order) ->
      $state.go('purchase_order', purchase_order_id: purchase_order.token)

    @search = =>
      @new_params.page = 0
      @new_params.search = @search_field

    @reset_search = =>
      @search_field = ''
      @search()

    @resort = (clicked_on_col) ->
      if @new_params['sort_column'] != clicked_on_col
        @new_params['sort_column'] = clicked_on_col
        @new_params['sort_order'] = 'desc'
      else
        if @new_params['sort_order'] == 'desc'
          @new_params['sort_order'] = 'asc'
        else
          @new_params['sort_order'] = 'desc'

    @sort_classes_for = (column) ->
      if column == @new_params['sort_column']
        ['apruve-icon-angle', @new_params['sort_order']]
      else
        []

    @row_classes_for = (purchase_order) ->
      classes = []
      if purchase_order.completed
        classes.push 'list-item-done'
      classes

    @load_new_page = (new_value, old_value) =>
      return true if !new_value? or !old_value?
      return true if new_value == old_value
      $state.go '.', new_value, notify: false
      return true if new_value.page != old_value.page
      @url_params = angular.copy(new_value)

    $scope.$watch "posCtrl.new_params", @load_new_page, true

    return
]