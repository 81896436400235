angular.module('apruve.common.current_user')
.factory 'currentUserService', [
  '$stateParams', '$location', 'CurrentUser'
  ($stateParams, $location, CurrentUser) ->
    new class CurrentUserService
      user: {}
      currentContext: {}
      alternateContexts: []

      reload_user: =>
        @user = CurrentUser.get()
        @user.$promise.then (user) =>
          if $stateParams.merchant_id
            current_merchant_id = $stateParams.merchant_id
          else if $location.path().split('/')[1] == 'sellers'
            # This provides a (slightly hack-ish) way of handling context in the 'navonly' app
            # It can be removed once the entire app is angular-ized.s
            current_merchant_id = $location.path().split('/')[2]

          results = []
          for merchant in user.merchants
            current_merchant = merchant if current_merchant_id == merchant.id
          if current_merchant?
            @currentContext = current_merchant
          else
            @currentContext = user

          if current_merchant_id
            # remove the current merchant
            for merchant in user.merchants
              results.push merchant unless merchant.id == current_merchant_id

          @alternateContexts = results

      constructor: ->
        @reload_user()
]
