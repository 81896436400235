angular.module 'apruve.common.search'
.directive 'searchBar', ->
  restrict: 'E'
  scope: {
    onSubmitCallback: '=onSubmit'
    onTextChangeCallback: '=onTextChange'
    onClearCallback: '=onClear',
    text: '=?searchText',
    placeholder: '@placeholder'
  }
  templateUrl: require('../../../../templates/common/search/search.html')
  link: (scope) ->
    scope.textEmpty = ->
      scope.text? && scope.text.replace(/\s/g, '') == ''

    scope.showClearButton = !scope.textEmpty()

    scope.submitSearch = ->
      return scope.clearSearch() if scope.textEmpty()
      scope.showClearButton = true
      scope.onSubmitCallback && scope.onSubmitCallback(scope.text)

    scope.clearSearch = ->
      scope.showClearButton = false
      scope.text = ''
      scope.onClearCallback && scope.onClearCallback()

    scope.onSearchTextChange = ->
      scope.showClearButton = false if scope.textEmpty()
      scope.onTextChangeCallback && scope.onTextChangeCallback(scope.text)