/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
angular.module('apruve.admin.app.routes', ['apruve.users.payment']).config([
  '$stateProvider', '$urlRouterProvider',
  function($stateProvider, $urlRouterProvider) {
    $urlRouterProvider
    .otherwise(window.location.pathname);

    var initialParams = {
      search: {
        value: '',
        squash: true
      },
      page: {
        value: '0',
        squash: true
      },
      sort_column: {
        value: '',
        squash: true
      },
      sort_order: {
        value: '',
        squash: true
      }
    };

    return $stateProvider
    .state('admin', {
      controller: 'AdminBaseController',
      url: '/admin',
      template: '<ui-view/>'
    }).state('admin.performance', {
      title: 'Performance',
      url: '/performance',
      templateUrl: require('../../../templates/admin/performance/index.html'),
      controller: 'AdminPerformanceController',
      controllerAs: 'adminPerformanceCtrl',
      resolve: {
        merchants: ['Performance', function(Performance) {
          return Performance.Merchants.query().$promise;
        }
        ],
        dashboard_chart_names: ['Performance', function(Performance) {
          return Performance.DashboardCharts.query().$promise;
        }
        ],
        dashboard_chart: ['Performance', function(Performance) {
          return Performance.DashboardCharts.get({type: 'month', count: 6, chart_index: 0, compare_period: 'previous'}).$promise;
        }
        ]
      }
    })
    .state('admin.merchants', {
      title: 'Merchants',
      url: '/merchants/:merchant_id?merchant&tab',
      templateUrl: require('../../../templates/admin/merchants/performance/index.html'),
      controller: 'AdminMerchantsController',
      controllerAs: 'adminMerchantsCtrl',
      resolve: {
        tab: ['$stateParams', function($stateParams) {
          if ($stateParams.tab !== 'performance') {
            throw new Error("ResolverException", "not performance tab");
          }
        }
        ]
      }
    })
    .state('admin.payment', {
      title: 'Payment',
      url: '/payments/:payment_uuid',
      controller: 'PaymentController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/admin/payments/show.html'),
      resolve: {
        payment_data: ['Payment', '$stateParams', function(Payment, $stateParams) {
          return Payment.get({uuid: $stateParams.payment_uuid}).$promise;
        }
        ]
      }
    })
    .state('admin.payment.invoices', {
      title: 'Payment Invoices',
      url: '/invoices?page',
      controller: 'PaymentInvoicesController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/admin/payments/invoices.html'),
      params: { page: { value: '0', squash: true } }
    })
    .state('admin.payment.apply', {
      title: 'Payment Apply',
      url: '/apply?search&page&sort_column&sort_order',
      controller: 'PaymentApplyController',
      controllerAs: 'ctrl',
      templateUrl: require('../../../templates/payments/apply.html'),
      params: angular.copy(initialParams),
      resolve: {
        invoices: ['payment_data', 'Payment', '$stateParams', function(payment_data, Payment, $stateParams) {
          return Payment.available_invoices({uuid: $stateParams.payment_uuid}).$promise;
        }
        ],
        auto_apply_invoices: ['payment_data', 'Payment', '$stateParams', function (payment_data, Payment, $stateParams) {
          return Payment.auto_apply_invoices({uuid: $stateParams.payment_uuid}).$promise
        }
        ],
        url_prefix: [ function() {
          return 'admin';
        }
        ]
      }
    });
  }
]);


