angular.module('apruve.common.payment_modal').factory('newBankAccountWorkflowFactory', [newBankAccountWorkflowFactory]);

function newBankAccountWorkflowFactory() {
  return {
    card_template: require('../../../../../templates/common/payment_modal/payment_method_cards/new_bank_account.html'),
    resolve_next_state: function(payment_action, state) {
      state.go('^.bank_account.new', {
        payment_action: payment_action,
      })
    }
  };
}
