angular.module('apruve.common.validators')
.directive('currency', ['currencyFormatterMaker',
  (currencyFormatterMaker) ->
    linkFn = (scope, element, attributes, ctrl) ->
      ctrl.$validators.currency = (modelValue, viewValue) ->
        value = modelValue || viewValue || '0'
        formatter = currencyFormatterMaker.buildFormatter(scope.currencyData)
        { symbol, thousands_separator, decimal_mark } = scope.currencyData
        return formatter.parse(modelValue) != null


    return {
        require: 'ngModel',
        restrict: 'A',
        template: null,
        link: linkFn,
        scope: {
          currencyData: '='
        }
    }
  ])