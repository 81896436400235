angular.module('apruve.common.helpers')
.directive 'apruveBaseError', ->
  restrict: 'E'
  scope:
    object: '='
  template: """
            <div class='alertContainer'>
              <div class='alert alert-danger alertContainer__alert' ng-repeat='alert in object.meta.errors.base'>
                <div class='alert-error__message'>
                  {{alert}}
                </div>
              </div>
            </div>
            """ 
.directive 'apruveError', ->
  restrict: 'E'
  scope:
    errors: '='
  template: """
            <div class='alertContainer'>
              <div class='alert alert-danger alertContainer__alert' ng-repeat='alert in errors'>
                <div class='alert-error__message'>
                  {{alert}}
                </div>
              </div>
            </div>
            """ 
  