/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import approved_template from "../../../../templates/sellers/corporate_accounts/index/approved.html";
import pending_template from "../../../../templates/sellers/corporate_accounts/index/pending_approval.html";
import in_progress_template from "../../../../templates/sellers/corporate_accounts/index/in_progress.html";
import self_financed_template from "../../../../templates/sellers/corporate_accounts/index/self_financed.html";
import needs_more_info_template from "../../../../templates/sellers/corporate_accounts/index/needs_more_info.html";
import declined_template from "../../../../templates/sellers/corporate_accounts/index/declined.html";
import suspended_template from "../../../../templates/sellers/corporate_accounts/index/suspended.html";
import expired_template from "../../../../templates/sellers/corporate_accounts/index/expired.html";
import search_template from "../../../../templates/sellers/corporate_accounts/index/search.html";
import in_collections_template from "../../../../templates/sellers/corporate_accounts/index/in_collections.html";
import partial_template from "../../../../templates/sellers/corporate_accounts/index/_partial.html";

angular
  .module("apruve.sellers.corporate_account")
  .controller("SellersCorporateAccountsController", [
    "$scope",
    "$state",
    "$stateParams",
    "$window",
    "currentUserService",
    "object_counts",
    "statusIconsService",
    "SellersCreditApplication",
    "$q",
    "$http",
    function (
      $scope,
      $state,
      $stateParams,
      $window,
      currentUserService,
      object_counts,
      statusIconsService,
      SellersCreditApplication,
      $q,
      $http
    ) {
      this.statusIconsService = statusIconsService;
      this.url_params = $stateParams;
      this.url_params.page = parseInt(this.url_params.page);
      if (!this.url_params.status) {
        this.url_params.status = "approved";
      }
      this.new_params = angular.copy(this.url_params);
      this.search_term = this.new_params.search_term || "";
      this.url = `/sellers/${$state.params.merchant_id}/corporate_accounts.json`;
      this.currentUserService = currentUserService;
      this.object_counts = object_counts;
      this.search_item_count = 0;
      this.page_is_loading = true;
      this.start_date = this.new_params.start_date ? new Date(this.new_params.start_date) : null;
      this.end_date = this.new_params.end_date ? new Date(this.new_params.end_date) : null;

      this.approved_template = approved_template;
      this.pending_template = pending_template;
      this.in_progress_template = in_progress_template;
      this.self_financed_template = self_financed_template;
      this.needs_more_info_template = needs_more_info_template;
      this.declined_template = declined_template;
      this.suspended_template = suspended_template;
      this.expired_template = expired_template;
      this.search_template = search_template;
      this.in_collections_template = in_collections_template;
      this.partial_template = partial_template;

      this.currentContext = function () {
        return this.currentUserService.currentContext;
      };

      this.creditor_msts = (corporate) => {
        return corporate.creditor_name === "MSTS";
      };

      this.edit_credit_limit_for_account = (corporate_account) => {
        return (
          corporate_account.self_financed &&
          !corporate_account.credit_limit_change_pending &&
          !this.creditor_msts(corporate_account)
        );
      };

      this.request_increase_for_account = function (corporate_account) {
        if (!this.creditor_msts(corporate_account)) {
          return false;
        } else if (corporate_account.self_financed) {
          return (
            corporate_account.has_credit_limit &&
            !corporate_account.credit_limit_change_pending
          );
        } else {
          return false;
        }
      };

      this.change_tab = (status) => {
        this.start_date =  null;
        this.end_date =  null;
        this.new_params.start_date = null;
        this.new_params.end_date = null;
        return (this.new_params.status = status);
      };

      this.is_active_tab = (status) => {
        if (status === "search") {
          return (
            this.new_params.search_term != null &&
            this.new_params.search_term.replace(/\s/g, "") !== ""
          );
        }
        return this.new_params.status === status;
      };

      this.clear_search = () => {
        this.new_params.status = "approved";
        this.new_params.search_term = "";
        return $state.go(".", this.new_params, { notify: true });
      };

      this.submit_search = () => {
        this.new_params.status = "search";
        this.new_params.search_term = this.search_term;
        return $state.go(".", this.new_params, { notify: true });
      };

      this.load_new_page = (new_value, old_value) => {
        if (new_value == null || old_value == null) {
          return true;
        }
        if (new_value === old_value) {
          return true;
        }
        if (this.page_is_loading) {
          this.canceller.resolve("user cancelled");
        }
        this.page_is_loading = true;
        $state.go(".", new_value, { notify: false });
        if (new_value.page !== old_value.page) {
          return true;
        }
        this.url_params = angular.copy(new_value);
        
        return (this.csv_url = `/sellers/${$state.params.merchant_id}/corporate_accounts.csv?status=${this.url_params.status}`);
      };

      this.corporate_accounts_updated = (event, status, config) => {
        this.page_is_loading = false;
      };

      $scope.$watch("sellerCorpAcctCtrl.new_params", this.load_new_page, true);
      $scope.$on("pagination:loadPage", this.corporate_accounts_updated, true);

      this.sort_classes_for = function (column) {
        if (column === "account_name" && !this.new_params.sort_column) {
          return ["apruve-icon-angle", "asc"];
        } else if (column === this.new_params.sort_column) {
          return ["apruve-icon-angle", this.new_params.sort_order];
        } else {
          return [];
        }
      };

      this.row_clickable = (corporate_account) =>
        corporate_account.status !== "in_progress" ||
        corporate_account.started_by_merchant;

      this.resort = function (clicked_on_col) {
        if (this.new_params.sort_column !== clicked_on_col) {
          this.new_params.sort_column = clicked_on_col;
          return (this.new_params.sort_order = "desc");
        } else {
          if (this.new_params.sort_order === "desc") {
            return (this.new_params.sort_order = "asc");
          } else {
            return (this.new_params.sort_order = "desc");
          }
        }
      };

      this.in_progress_row_class = function (corporate_account) {
        const row_classes = [];
        if (corporate_account.signature_required) {
          row_classes.push("alert-negative", "alert--missing-signature");
        }
        if (corporate_account.started_by_merchant) {
          row_classes.push("data-table__row--clickable");
        } else {
          row_classes.push("data-table__row");
        }
        return row_classes;
      };

      this.navigate_to_credit_application = function (corporate_account) {
        if (!corporate_account.started_by_merchant) {
          return;
        }
        // leaving angular
        return (window.location.href =
          this.credit_application_link(corporate_account));
      };

      this.credit_application_link = (corporate_account) =>
        `/apply/${$state.params.merchant_id}/${corporate_account.id}/business_information/new`;

      this.reject_credit_application = (credit_application) =>
        SellersCreditApplication.destroy(
          {
            merchant_id: $state.params.merchant_id,
            uuid: credit_application.id,
          },
          (result) => $state.go(".", {}, { reload: true }),
          function (response) {
            console.error(response.data);
            return $state.go(".", {}, { reload: true });
          }
        );

      this.loadData = (config) => {
        this.canceller = $q.defer();
        config.timeout = this.canceller.promise;
        return $http(config);
      };

      this.searchByDate = () => {
        this.new_params = {
          ...this.new_params,
          start_date: this.start_date,
          end_date: this.end_date,
        };

        return $state.go(".", this.new_params, { reload: true });
      };

      this.downloadCsv = () => {
        const startDateParam = this.new_params.start_date
            ? `&start_date=${this.new_params.start_date}`
            : "";
        const endDateParam = this.new_params.end_date
            ? `&end_date=${this.new_params.end_date}`
            : "";
        $window.open(
            `/sellers/${$state.params.merchant_id}/corporate_accounts.csv?status=${this.url_params.status}${startDateParam}${endDateParam}`
        );
      };
    },
  ]);
