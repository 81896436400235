angular.module('apruve.sellers.corporate_account').factory "SellersCorporateAccount", ['$resource', ($resource) ->
  $resource("/sellers/:merchant_id/corporate_accounts/:uuid.json",
    {uuid: '@uuid', merchant_id: '@merchant.id'},
    {
      query: {isArray: true}
      update: {method: 'PUT'}
      create_prepayment: {method: 'POST', url: '/sellers/:merchant_id/corporate_accounts/:uuid/payments/create_prepayment.json'}
      create: {method: 'POST', url: '/sellers/:merchant_id/corporate_accounts.json'}
      object_counts: {method: 'GET', url: '/sellers/:merchant_id/corporate_accounts/object_counts.json'}
      submit_decision: {method: 'PUT', url: '/sellers/:merchant_id/corporate_accounts/:uuid/submit_decision'}
    }
  )
]