import { TrackJS } from 'trackjs';
import {wrap} from './response_config_wrapper';

angular.module('apruve.common.http').factory('requestLogging', ['$q', 'environment', function($q, environment) {

  return {

    // TODO: Combine this with apruve.checkout.http, they do similar things, but checkout has more of them.

    request: (config) => {
      const message = {
        url: config.url,
        method: config.method,
        params: config.params,
        headers: config.headers
      };

      TrackJS.console.log('Outgoing request', message);

      return config;
    },

    response: (response) => {
      TrackJS.console.log('Response', {
        url: response.config.url,
        method: response.config.method,
        params: response.config.params,
        response: response.data,
        status: response.status,
        statusText: response.statusText,
        headers: response.headers()
      });
      return response;
    },

    responseError: (response) => {
      const wrapped_response = wrap(response);
      const message = {
        url: wrapped_response.config.url,
        method: wrapped_response.config.method,
        params: wrapped_response.config.params,
        response: wrapped_response.data,
        status: wrapped_response.status,
        statusText: wrapped_response.statusText,
        headers: wrapped_response.headers()
      };

      if(!environment.is_production) {
        console.error('Response error', message);
      } else {
        TrackJS.console.error('Response error', message);
      }
      TrackJS.track(message);

      return $q.reject(response);
    }
  };
}]);