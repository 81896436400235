angular.module('apruve.sellers.payout').controller 'PayoutsController', [
  '$scope', '$state', '$stateParams', 'currentUserService', 'Payout', 'payoutsSummary', 'SellerBankAccount', 'payout_account', 'merchant',
  ($scope, $state, $stateParams, currentUserService, Payout, payoutsSummary, SellerBankAccount, payout_account, merchant) ->
    @url_params = $stateParams
    @url_params.page = parseInt @url_params.page
    @new_params = angular.copy(@url_params)
    @url = "/sellers/#{$stateParams.merchant_id}/payouts.json"
    @bank_accounts_url = "/sellers/#{$stateParams.merchant_id}/bank_accounts"
    @currentUserService = currentUserService
    @currentMerchant = $stateParams.merchant_id

    @merchant = merchant
    @summary = payoutsSummary
    @payout_account = payout_account
    @payout_account_resource = new SellerBankAccount(merchant_id: @merchant.id)

    @payoutCardTemplate = () ->
      require('../../../../templates/sellers/bank_account_card/payout_bank_account_card.html')

    @payoutModalTemplate = () =>
      return unless @merchant
      if @merchant.payment_service_identities_exist
        require('../../../../templates/sellers/bank_account_card/payout_bank_account_modal.html')
      else
        require('../../../../templates/sellers/bank_account_card/add_payout_account_form_modal.html')


    @currentContext = () ->
      @currentUserService.currentContext

    @navigate_to_payout = (payout) ->
      $state.go('sellers.payout', payout_id: payout.uuid, merchant_id: $stateParams.merchant_id)

    @load_new_page = (new_value, old_value) =>
      return true if !new_value? or !old_value?
      return true if new_value == old_value
      $state.go '.', new_value, notify: false
      return true if new_value.page != old_value.page
      @url_params = angular.copy(new_value)

    $scope.$watch "ctrl.new_params", @load_new_page, true

    return
]