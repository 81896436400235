angular.module('apruve.common.payment_modal').service('paymentActionDecorator', ['money', paymentActionDecorator]);

function paymentActionDecorator(money) {
  this.decorate = function(payment_action) {
    const currency = money.currency(payment_action.currency_data);

    payment_action.amount = currency(payment_action.amount);
    payment_action.credit_memo_amount = currency(payment_action.credit_memo_amount);
    payment_action.payment_amount = currency(payment_action.payment_amount);

    (payment_action.credit_memos ?? []).forEach((credit_memo) => {
      credit_memo.applied_amount = currency(credit_memo.applied_amount);
    });

    return payment_action;
  };
}