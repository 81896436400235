angular.module('apruve.users.team_member').factory 'TeamMember', ['$resource', ($resource) ->
  $resource('/team_members/:uuid.json',
    { uuid: '@uuid' },
    {
      initialize: { method: 'GET', url: '/accounts/:customer_account_id/team_members/new.json' }
      create: { method: 'POST', url: '/accounts/:customer_account_id/team_members.json', params: { customer_account_id: '@customer_account.uuid' } }
      update: { method: 'PATCH', params: { uuid: '@uuid' } }
      destroy: { method: 'DELETE', params: { uuid: '@uuid' } }
      update_terms: { method: 'PUT', params: { uuid: '@uuid' }, url: '/team_members/:uuid/update_terms.json' }
      query:  { isArray: true }
    }
  )
]
