angular.module('apruve.sellers.statement').controller 'StatementPaymentsController', [
  '$scope', '$state', '$stateParams', 'statement_data',
  ($scope, $state, $stateParams, statement_data) ->
    @url_params = $stateParams
    @url_params.page = parseInt @url_params.page
    @new_params = angular.copy(@url_params)
    @url = "/sellers/#{$stateParams.merchant_id}/payments.json"

    @statement = statement_data

    @navigate_to_invoice = (invoice) ->
      $state.go('sellers.invoice', invoice_uuid: invoice.uuid, merchant_id: $stateParams.merchant_id)

    return
]