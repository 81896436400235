import moment from 'moment';
angular.module('apruve.sellers.funding_events').controller("SellersFundingEventsController", [
  '$scope', '$state', '$stateParams', 'currentUserService', 'fundingEventsSummary', 'chartColors',
  function($scope, $state, $stateParams, currentUserService, fundingEventsSummary, chartColors) {
    this.url_params = $stateParams;
    this.url_params.page = parseInt(this.url_params.page);
    this.new_params = angular.copy(this.url_params);
    this.url = `/sellers/${$stateParams.merchant_id}/funding_events.json`;
    this.csv_url = `/sellers/${$stateParams.merchant_id}/funding_events.csv`;
    this.currentUserService = currentUserService;
    this.currentMerchant = $stateParams.merchant_id;
    this.summary = fundingEventsSummary;
    this.is_ti = fundingEventsSummary.is_ti;

    this.chartControl = {};

    const chartData = this.summary.per_month_last_year_chart_data.map(function(...args) { const [iso8601, amount_cents] = Array.from(args[0]); return [moment(iso8601).valueOf(), amount_cents / 100]; });
      this.chartConfig = {
      chart: {
        backgroundColor: 'transparent',
        type: 'area',
        height: 240,
        marginRight: 20
      }, // The last month label clips without this
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      plotOptions: {
        series: {
          fillOpacity: 0.1,
          lineWidth: 3,
          marker: {
            enabled: false
          },
          dataLabels: {
            enabled: false
          },
          label: {
            enabled: false
          }
        }
      },
      menuItemStyle: {
        fontWeight: 'normal',
        background: 'none'
      },
      menuItemHoverStyle: {
        fontWeight: 'bold',
        background: '#1784B0',
        color: '#fff'
      },
      tooltip: {
        backgroundColor: '#fff',
        borderRadius: 4,
        borderColor: '#ccc',
        borderWidth: 1,
        headerFormat: '<small>{point.key}</small><br>',
        pointFormat: '<b>${point.y}</b>',
        shadow: false,
        shared: true,
        useHTML: false,
        split: false,
        valueDecimals: 2,
        xDateFormat: '%B %Y'
      },
      series: [{
        data: chartData,
        color: chartColors.primary, // fill color
        showInLegend: false,
        states: {
          hover: {
            enabled: false
          }
        }
      }],
      loading: false,
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%b, %Y',
          year: '%Y'
        }
      },
      yAxis: {
        opposite:  false,
        labels: {
          enabled: true
        },
        title: {
          text: 'Funded Amount per Month'
        }
      }
    };

    this.currentContext = function() {
      return this.currentUserService.currentContext;
    };

    this.load_new_page = (new_value, old_value) => {
      if ((new_value == null) || (old_value == null)) { return true; }
      if (new_value === old_value) { return true; }
      $state.go('.', new_value, {notify: false});
      if (new_value.page !== old_value.page) { return true; }
      return this.url_params = angular.copy(new_value);
    };

    this.sort_classes_for = function(column) {
      if (column === this.new_params.sort_column) {
        return ['apruve-icon-angle', this.new_params.sort_order];
      } else {
        return [];
      }
    };

    this.resort = function(clicked_on_col) {
      if (this.new_params.sort_column !== clicked_on_col) {
        this.new_params.sort_column = clicked_on_col;
        return this.new_params.sort_order = 'asc';
      } else {
        if (this.new_params.sort_order === 'desc') {
          return this.new_params.sort_order = 'asc';
        } else {
          return this.new_params.sort_order = 'desc';
        }
      }
    };

    this.navigate_to_event = event_id => $state.go('sellers.funding_event', {funding_event_id: event_id, merchant_id: $stateParams.merchant_id});

    $scope.$watch("sellerFundEventsCtrl.new_params", this.load_new_page, true);

  }
]);