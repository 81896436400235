angular.module('apruve.common.confirmation_modal').directive 'confirmationModalLink', ['$uibModal', ($uibModal) ->
  restrict: 'A'
  scope:
    modalTemplate: '=' # function to return template for the modal
    confirmCallback: '='

  link: (scope, elem, attrs) ->
    elem.on 'click', ->
      scope.open_modal()

    scope.close_modal = ->
      scope.confirmationModal.dismiss()

    scope.confirm = ->
      scope.confirmCallback()
      scope.close_modal()

    scope.open_modal = ->
      templateUrl = scope.modalTemplate()
      scope.confirmationModal = $uibModal.open {
        animation: true
        ariaLabelledBy: 'confirmationModal'
        templateUrl: templateUrl
        scope: scope
        windowClass: 'fade v2'
      }
]